<template>
  <div class="p-8 flex flex-col">
    <redaction-button-exit
      class="absolute right-0 top-0 m-2"
      @emit-close="closeModal()"
    />
    <h1 class="text-xl text-left pb-3">
      Filen du er ved at {{ actionType === "downloadFullDocument" ? "download" : "send/print" }} er større end 35 mb
    </h1>
    <p>
      Denne fil indeholder afgørelsesbrevet, indholdsfortegnelse og dokumenterne for sagen. 
      Afgørelsesbrevet og indholdsfortegnelsen findes i det første dokument. 
      Filen er opdelt i flere dele grundet dens størrelse. 
      Du kan vælge at {{ actionType === "downloadFullDocument" ? "download" : "send/print" }} enkelte dele efter behov.
    </p>
    <!-- Liste start -->
    <div class="list-container">
      <ul class="file-list">
        <li
          v-for="(file, index) in sortedFiles"
          :key="index"
          class="file-item"
        >
          <div class="file-info">
            <span class="file-name">{{ file.name }} ({{ (file.size / 1024).toFixed(2) }} MB)</span>
          </div>
          <div class="file-actions">
            <standard-button
              v-if="actionType === 'downloadFullDocument'"
              :class="{ 'processed': processedFiles.includes(file.id) }"
              :title="'Download ' + file.name"
              button-type="standard"
              button-icon="download"
              button-text="Download"
              @click="downloadFile(file)"
            />
            <standard-button
              v-else-if="actionType === 'printFullDocument'"
              :class="{ 'processed': processedFiles.includes(file.id) }"
              :title="'Send/Print ' + file.name"
              button-type="standard"
              button-icon="print"
              button-text="Send/Print"
              @click="sendPrintFile(file)"
            />
          </div>
        </li>
      </ul>    
    </div>
    <!-- Liste slut -->

    <div class="mt-5 items-center">
      <standard-button
        class="h-9 w-28 ml-4"
        button-title="Luk"
        button-type="grey"
        button-text="Luk"
        @click="closeModal()"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import buttonExit from "@/components/controls/buttonExit.vue";
import StandardButton from "@/components/controls/StandardButton.vue";

export default {
  name: "SplittetFileChooseForm",
  components: {
    "redaction-button-exit": buttonExit,
    StandardButton,
  },
  props: {
    processId: String,
    decisionLetterId: Number,
    redactedProcess: Array,
    actionType: String,
  },
  data() {
    return {
      processedFiles: []
    };
  },
  computed: {
    ...mapState([
      "processStatus",
      "openProcess",
      "apiUrl",
      "user"
    ]),
    sortedFiles() {
      if (!this.redactedProcess[0] || !this.redactedProcess[0].files) {
        return [];
      }
      // Copy array to avoid mutation of the original
      const files = [...this.redactedProcess[0].files];

      // Regular expression to find "part X of Y" where X is the part number
      const partRegex = / del (\d+) af \d+/;

      // Sort the files based on the part number found in the file name
      return files.sort((a, b) => {
        const matchA = a.name.match(partRegex);
        const matchB = b.name.match(partRegex);
        
        const partA = matchA ? parseInt(matchA[1], 10) : 0;
        const partB = matchB ? parseInt(matchB[1], 10) : 0;

        return partA - partB; // Ascending sort
      });
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    sendPrintFile(fileObject) {
      this.processedFiles.push(fileObject.id);
      const fileObj = this.getDocumentData(fileObject);
      this.fetchFileData(fileObj).then((res) => {
        const blob = new Blob([res.data], { type: 'application/pdf' });
        const blobURL = URL.createObjectURL(blob);

        const iframe = document.createElement('iframe');
        document.body.appendChild(iframe);
        iframe.style.display = 'none';
        iframe.src = blobURL;
        iframe.onload = function() {
          setTimeout(() => {
            iframe.focus();
            iframe.contentWindow.print();
          }, 1);
        };
      });
    }, 
    downloadFile(fileObject) {
      this.processedFiles.push(fileObject.id);
      const file_obj = this.getDocumentData(fileObject);
      this.fetchFileData(file_obj).then((res) => {
        const fileURL = window.URL.createObjectURL(new Blob([res.data]));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', file_obj.fileObj.output_filename);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    getDocumentData(fileObject) {
      const { process_id }  = this.openProcess

      if (fileObject && process_id) {
        const fileObj = {
          id: fileObject.id,
          name: fileObject.name,
          url: fileObject.url,
          mime: fileObject.mime,
          output_filename: 'Dokumenter for aktindsigt sagsid [' + process_id.toString() + '].' + fileObject.name.split('.').pop()
        }
        return {'fileObj': fileObj, 'process_id': process_id, 'apiURL': this.apiUrl + "/file/get_redacted_documents"}
      }
    },
    fetchFileData(fileObject) {
      const jwt_token = localStorage.getItem('aktio-ai-user-token');
      const axios_headers = { 'Authorization': 'Bearer ' + jwt_token };
      return axios({
        url: fileObject.apiURL,
        method: 'POST',
        data: {
          file: { 
            id: fileObject.fileObj.id,
            name: fileObject.fileObj.name,
            url: fileObject.fileObj.url,
            mime: fileObject.fileObj.mime
          },
          process_id: fileObject.process_id
        },
        responseType: 'blob',
        headers: axios_headers,
      });
    },
  }
};
</script>

<style>
.list-container {
  background: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}

.file-list {
  list-style-type: none;
  padding: 0;
}

.file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin: 8px;
  background: white;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.1s ease;
}

.file-item:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.file-info {
  flex-grow: 1;
}

.file-name {
  font-weight: 500;
}

.processed {
  background-color: #db58a4;
  color: white;
}
</style>
