<template>
  <button
    class="focus:outline-none rounded text-white font-semibold shadow inline-flex items-center justify-center"
    :class="[
      { 'px-3 py-2': buttonText},
      { 'p-2': !buttonText},
      {
        'bg-aktio-darkblue-900 hover:bg-aktio-darkblue-700 focus:ring-aktio-darkblue-900':
          buttonType === 'standard',
      },
      {
        'bg-red-500 hover:bg-red-600 focus:ring-red-500':
          buttonType === 'delete',
      },
      {
        'bg-gray-500 hover:bg-gray-600 focus:ring-gray-500':
          buttonType === 'cancel',
      },
      {
        'bg-blue-500 hover:bg-aktio-darkblue-700 focus:ring-blue-500':
          buttonType === 'blue',
      },
      {
        'bg-gray-500 hover:bg-gray-600 focus:ring-gray-500':
          buttonType === 'grey',
      },
      {
        'bg-green-500 hover:bg-green-600 focus:ring-green-500':
          buttonType === 'green',
      },
      {
        'bg-aktio-pink-500 hover:bg-aktio-darkblue-700 focus:ring-aktio-pink-500':
          buttonType === 'success',
      },
      {
        'cursor-not-allowed bg-gray-400 focus:ring-gray-400': buttonDisabled,
      },
      {
        'bg-aktio-darkblue-900 hover:bg-aktio-darkblue-800 focus:ring-aktio-darkblue-900':
          buttonType === 'standard-disableable' && !buttonDisabled,
      },
      {
        'text-sm' : buttonTextSmall,
        'text-base': !buttonTextSmall
      }
    ]"
    :title="buttonTitle"
  >
    <component
      :is="optionComponent"
      v-if="buttonIcon && showIcon"
      class="w-4 h-4"
      :class="[{
        'mr-2': buttonText
      }]"
    />
    <font-awesome-icon  
      v-if="buttonIconFa"
      :class="[{
        'mr-2': buttonText
      }]"
      :icon="buttonIconFa"
    />
    <span v-if="buttonText">{{ buttonText }}</span>
  </button>
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
  name: "StandardButton",
  props: {
    buttonText: String,
    buttonIcon: String,
    buttonIconFa: String,
    buttonTitle: String,
    showIcon: { type: Boolean, default: true },
    buttonType: {
      type: String,
      default: "standard",
    },
    buttonDisabled: {
      type: Boolean,
      default: false,
    },
    buttonTextSmall: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    optionComponent() {
      return defineAsyncComponent(() => 
        import(`@/components/icons/${this.buttonIcon}.vue`)
        .catch(error => {
          console.error("Failed to load the icon component", error);
          return null; // Return a fallback or null if the import fails
        })
      );
    },
  },
};
</script>

<style>
</style>