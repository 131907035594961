<template>
  <span
    class="text-annotation"
    :style="{ 'border': '2px solid ' + localAnnotationBgColor, 'color': localAnnotationTextColor }"
  >
    <span
      class="text-annotation__word"
      :class="{ 'data-masking': dataMasking }"
      @click="editAnnotation"
    >
      <span
        v-if="dataMasking"
        id="annotation"
        ref="annotation"
      >
        <span class="text-annotation__word-top">
          {{ dataMaskingCharacter.repeat(textAnnotation.content.length) }}
        </span>
        <span class="text-annotation__word-bottom">
          {{ textAnnotation.content }}
        </span>
      </span>
      <span v-else>
        {{ textAnnotation.content }}
      </span>
    </span>
    <span
      class="text-annotation__annotation non-selectable"
      :style="{ backgroundColor: localAnnotationBgColor }"
      @click="editAnnotation"
    >
      {{ textAnnotation.annotation }}
    </span>
    <button
      class="text-annotation__delete"
      @click="deleteAnnotation"
    >x</button>
  </span>
</template>

<script>
export default {
  name: 'TextAnnotation',
  props: {
    textAnnotation: Object,
    annotationBgColor: String,
    annotationTextColor: String,
    dataMaskingCharacter: {
      type: String,
      default: '●',
    },
    dataMasking: Boolean,
  },
  data() {
    return {
      localAnnotationBgColor: "",
      localAnnotationTextColor: "",
    }
  },
  mounted() {
    this.localAnnotationBgColor = this.annotationBgColor;
    this.localAnnotationTextColor = this.annotationTextColor; 
  },
  methods: {
    deleteAnnotation(){
      this.$emit('delete-annotation', this.textAnnotation)

      this.$store.dispatch("posthogCapture", {event_name:"DeleteAnnotation", data:{}});
    },
    editAnnotation(event){
      const left = event.clientX;
      const top = event.clientY;
      this.$emit('edit-annotation', {
        editPosX:left,
        editPosY:top,
        annotation: this.textAnnotation
      })
    }
  }
}
</script>
<style>
.text-annotation {
  margin-right: 3px;
  line-height: 1.3;
  display: inline-block;
  white-space:normal;
  position: relative;
  cursor: pointer;
}
.text-annotation:hover .text-annotation__delete {
display: inline-block;
}
.text-annotation__word {
  float: left;
  height: 1.3em;
  line-height: 1.3;
  overflow: hidden;
  padding-left: 2px;
  padding-right: 2px;
  display: inline-block;
}
.text-annotation__word-top, .text-annotation__word-bottom {
  height: 1.3em;
  text-align: center;
  display: inline-block;
  transition: margin-top 0.3s;
}
.text-annotation__word.data-masking:hover .text-annotation__word-top {
  margin-top: -1.3em;
}
.text-annotation__annotation {
  display: inline-block;
  font-weight: 700;
  height: 1.3em;
  padding-left: 2px;
}
.text-annotation__delete {
  font-size: 16px;
  color: #fff;
  top: -9px;
  left: -9px;
  position: absolute;
  text-align: center;
  display: none;
  border-radius: 50%;
  line-height: 1;
  padding: 0px;
  width: 16px;
  height: 16px;
  background-color: rgba(0, 0, 0, 0.54);
  border: none;
}
.text-annotation__delete:hover {
  cursor: pointer;
}
</style>