<template>
  <div class="p-8 flex flex-col">
    <redaction-button-exit
      class="absolute right-0 top-0 m-2"
      @emit-close="closeModal()"
    />
    <h1 class="text-xl text-left pb-3 title">
      Der er registreret ændringer i sagen.
    </h1>
    <div class="content">
      <p>Vælg en af de to muligheder for at opdatere.</p>
      
      <p class="option-1 flex">
        <RestoreIcon
          :stroke-width="'2'"
          :icon-size="'21'"
          class="mr-1"
        />
        (Opdater sag) - 
        Afgørelsesbrev og aktliste opdateres. NB har du allerede redigeret i afgørelsesbrevet bliver det gendannet til standard.
      </p>
      <p class="option-2 flex mt-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="icon icon-tabler icons-tabler-outline icon-tabler-list mr-1"
        >
          <path
            stroke="none"
            d="M0 0h24v24H0z"
            fill="none"
          />
          <path d="M9 6l11 0" />
          <path d="M9 12l11 0" />
          <path d="M9 18l11 0" />
          <path d="M5 6l0 .01" />
          <path d="M5 12l0 .01" />
          <path d="M5 18l0 .01" />
        </svg>
        (Opdater aktliste) - 
        Det er alene aktlisten som opdateres, der sker ingen ændringer i afgørelsesbrevet.
      </p>
    </div>
    
    <div class="mt-5 flex justify-end">
      <standard-button
        class="h-9 ml-4"
        button-title="Gendan afgørelsesbrev og aktlist"
        button-type="standard-disableable"
        button-text="Opdater sag"
        button-icon="restore"
        @click="reset('RestoreBoth')"
      />
      <standard-button
        class="h-9 ml-4"
        button-title="Gendan kun aktlist"
        button-type="standard-disableable"
        button-text="Opdater aktliste"
        button-icon="list"
        @click="reset('RestoreTOC')"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import buttonExit from "@/components/controls/buttonExit.vue";
import StandardButton from "@/components/controls/StandardButton.vue";
import RestoreIcon from "@/components/icons/restore.vue";

export default {
  name: "ConfirmRestoreOptions",
  components: {
    "redaction-button-exit": buttonExit,
    StandardButton,
    RestoreIcon,
  },
  props: {
    processId: String,
    decisionLetterId: Number,
  },
  computed: {
    ...mapState([
      "processStatus",
      "openProcess",
      "user"
    ]),
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async reset(resetType) {
      await this.$store.dispatch("updateRestorationStatus", {
          processId: this.$route.params.processId,
          status: resetType
      });
      // Reset decision letter content to template
      this.$emit("reset")
      const obj = {
        process: {
          id: this.openProcess.id,
          process_id: this.$route.params.processId,
        },
        request_user: this.user.userData,
        decision_letter_id: this.decisionLetterId
      }
      
      this.$store.dispatch("resetDecisionLetterContent", obj)
      // Close modal
      this.closeModal()

      // navigate to decisionletter page
      this.$router.push({ name: "Decision", params: { processId: this.$route.params.processId, decision: "beslutning" } });
    },
  }
};
</script>

<style>
  .title {
    margin-bottom: 5px;
  }

  .content p {
    word-break: keep-all;
  }

  .content p:first-of-type {
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .option-2 {
    margin-top: 15px;
  }
</style>
