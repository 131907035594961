<template>
  <div
    class="input-dialog__box"
    :class="{ 'align-right': align == 'right', 'align-left': align == 'left' }"
    :style="{ left: posX, top: posY, width: `${inputDialogWidth}px` }"
  >
    <div class="flex flex-col modal">
      <div class="flex">
        <!--<div class="input-dialog__box_left">
         <input
          id="input-dialog"
          v-model="annotation"
          type="text"
          placeholder="Indtast kategori her"
          autocomplete="off"
          class="input-dialog mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        /> 
      </div>-->
        <div v-if="editObject === null">
          <standard-button
            class="h-10 ml-2"
            button-title="GEM"
            button-type="blue"
            button-text="GEM"
            button-text-small
            @click="addAnnotation"
          />
        </div>
        <div v-else>
          <standard-button
            class="h-10 ml-2"
            button-title="GEM"
            button-type="green"
            button-text="GEM"
            button-text-small
            @click="editAnnotation"
          />
        </div>
      </div>
      <multiselect
        id="ner-annotation-category"
        ref="category"
        v-model="nerAnnotationCategory"
        class="w-300 cursor-pointer select-none"
        name="ner-annotation-category"
        :options="nerAnnotationCategories"
        :loading="!nerAnnotationCategory || nerAnnotationCategories.length==0"
        :multiple="false"
        :searchable="true"
        :allow-empty="false"
        :close-on-select="true"
        :custom-label="customNERAnnotationCategoryLabel"
        placeholder="Skriv Annotering"
        lable="ner-annotation-category"
        track-by="guid"
        :option-height="150"
        select-label
        deselect-label
        selected-label
      />
      <div
        contenteditable="false"
        class="flex items-center mt-1"
      >
        <toggle-switch v-model="unsureNerAnnotationCategory" />
        <span class="ml-2">Usikker</span>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import StandardButton from "@/components/controls/StandardButton.vue";
import nerAnnotationCategoriesFromJSON from '../../assets/json/ner_annotation_categories.json';
import ToggleSwitch from "@/components/controls/ToggleSwitch.vue";

export default {
  name: "InputDialog",
  components: {
    Multiselect,
    StandardButton,
    ToggleSwitch
  },
  props: {
    posX: String,
    posY: String,
    editObject: Object,
    annotationTextColor: String,
    align: String,
    inputDialogWidth: Number
  },
  data(){
    return {
      annotation: '',
      nerAnnotationCategories: [],
      nerAnnotationCategory: undefined,
      unsureNerAnnotationCategory: false,
    }
  },
  watch: {
    nerAnnotationCategory(category){
      if(category.sort_order !== 0){
        this.annotation = category.tag_name
      }
    },
    editObject: {
      handler() {
        if(this.editObject) {
          this.annotation = this.editObject.annotation;
          this.nerAnnotationCategory = nerAnnotationCategoriesFromJSON.find(({tag_name}) => tag_name === this.editObject.annotation);
        } else {
          this.annotation = '';
          this.nerAnnotationCategory = nerAnnotationCategoriesFromJSON.find(({sort_order}) => sort_order === 0);
        }
      },
      deep: true
    }
  },
  mounted(){
    this.nerAnnotationCategories = nerAnnotationCategoriesFromJSON.sort((a,b) => a.sort_order > b.sort_order);
    if(this.editObject) {
        this.nerAnnotationCategory = nerAnnotationCategoriesFromJSON.find(({tag_name}) => tag_name === this.editObject.annotation);
        this.annotation = this.editObject.annotation;
      } else {
        this.nerAnnotationCategory = nerAnnotationCategoriesFromJSON.find(({sort_order}) => sort_order === 0);
        this.annotation = '';
      }
      const categoryElement = document.getElementById("ner-annotation-category")
      categoryElement.focus();
  },
  methods: {
    customNERAnnotationCategoryLabel({description}){
      return description;
    },
    addAnnotation(){
      this.$emit('add-annotation', {annotation: this.annotation, category: {guid: this.nerAnnotationCategory.guid, unsure: this.unsureNerAnnotationCategory}});
      this.$store.dispatch("posthogCapture", {event_name:"AddAnnotation", data:{}});

    },
    editAnnotation(){
      this.$emit('edit-annotation',{
        annotation: this.annotation,
        content: this.editObject.content,
        start: this.editObject.start,
        id: this.editObject.id,
        category: {
          guid: this.nerAnnotationCategory.guid,
          unsure: this.unsureNerAnnotationCategory
        }
      });

      this.$store.dispatch("posthogCapture", {event_name:"EditAnnotation", data:{}});
    },
  }
}
</script>

<style>
.input-dialog__box {
  z-index: 7;
  position: absolute;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  display: flex;
  padding: 10px;
  line-height: 1;
  justify-content: center;
  box-sizing: border-box;
}
.input-dialog__box_left {
  flex-grow: 1;
}
.input-dialog__box.align-right::before {
  right: 10px;
}
.input-dialog__box.align-left::before {
  left: 10px;
}
.input-dialog__box::before {
  display: block;
  content: "";
  width: 10px;
  height: 10px;
  top: -7px;
  position: absolute;
  background-color: #fff;
  transform: rotate(45deg);
  border-left: 1px solid #e6e6e6;
  border-top: 1px solid #e6e6e6;
}
.input-dialog__box_left {
  width: 100%;
  border: none;
  outline: none;
  box-sizing: border-box;
  background: none;
}
.input-dialog__box_left .input-dialog:-webkit-autofill {
  box-shadow: 0 0 0px 1000px white inset !important;
}
.input-dialog__box .add-annotation-button {
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  padding: 0px;
  height: 22px;
  width: 40px;
  margin-left: 5px;
  cursor: pointer;
}
.w-300 {
  width: 300px;
}
.modal .multiselect__element {
  border-bottom: 1px solid #e3e3e3;
  white-space: normal;
}
.modal .multiselect__input {
  border: none;
  outline: none;
  height: 40px;
  padding: 12px 5px;
  border-radius: 4px;
}
.modal .multiselect__input:focus {
  outline: none;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
</style>