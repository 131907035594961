<template>
  <div id="app">
    <div id="main-body" class="leading-normal tracking-normal">
      <div class="flex flex-wrap">
        <div id="main-content" class="w-full bg-gray-100 pl-0 min-h-screen">
          <Navbar v-if="showNavbar" />

          <div class="pl-6 pr-6 pb-2 bg-gray-100">
            <router-view />
          </div>

          <AppFooter v-if="false" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Navbar from "@/components/navigation/Navbar.vue";
import AppFooter from "@/components/Footer.vue";
import posthog from "posthog-js";
import '@r2rka/vue3-treeselect/dist/style.css'

export default {
  name: "App",
  components: {
    // Sidebar,
    Navbar,
    AppFooter,
  },
  computed: {
    ...mapState(["user"]),
    showNavbar() {
      // Hide the Navbar on the /login and /services routes
      const hiddenRoutes = ['/aktio'];
      return !hiddenRoutes.includes(this.$route.path);
    },
  },
  mounted() {
    try {
      if (process.env.VUE_APP_POSTHOG_API_KEY && process.env.VUE_APP_POSTHOG_API_HOST) {
        posthog.init(process.env.VUE_APP_POSTHOG_API_KEY, {
          api_host: process.env.VUE_APP_POSTHOG_API_HOST,
          autocapture: false,
          maskAllInputs: true,
          capture_pageview: false,
          disable_session_recording: true,
          feature_flag_request_timeout_ms: 10000,
        });
      }
    } catch (exception) {
      console.log(exception);
    }
  }
};
</script>


<style>
/* Setting multiselect default colors */
.multiselect__option--highlight {
  background: rgb(59, 130, 246) !important;
}

body.modal-open {
  overflow: hidden;
}

#app {
  text-align: initial;
  color: unset;
}
</style>
<style>@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;600;700&display=swap');</style>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<!-- <style src="vue-multiselect/dist/vue-multiselect.min.css"></style> -->
<!-- <style src="@riophae/vue-treeselect/dist/vue-treeselect.css"></style> -->