<template>
  <div>
    <div class="flex">
      <div
        class="rounded text-xs text-white px-2 py-1 whitespace-no-wrap"
        :class="process.process_status.background_color_class || 'bg-aktio-pink-500'"
      >
        {{ process.process_status.display_name }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ProcessStatus",
  components: {

  },
  props: {
    process: Object,
  },
  data() {
    return {
      showEdit: false,
    };
  },
  computed: {
      ...mapState(["processStatus"]),
    localProcess: {
      get() {
        return this.process;
      },
      set(val) {
        this.localProcess = val;
      },
    },
  },
  methods: {
    async updateProcess() {
      await this.$store.dispatch("updateProcessStatus", this.localProcess);
      this.showEdit = false;
    },
  },
};
</script>

<style>
</style>