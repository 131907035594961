<template>
  <div id="home">
    <div class="lg:flex justify-between items-center mb-6">
      <!-- <p class="text-2xl font-semibold mb-2 lg:mb-0"> -->
      <!-- Velkommen, {{ userFirstName }}! -->
      <!-- </p> -->
      <!-- <button
        class="bg-aktio-darkblue-500 hover:bg-aktio-darkblue-600 focus:outline-none rounded-lg px-6 py-2 text-white font-semibold shadow"
      >
        Se logfiler
      </button> -->
    </div>

    <div class="flex flex-wrap -mx-3">
      <div class="w-1/2 xl:w-1/4 px-3">
        <div class="w-full bg-white border text-aktio-darkblue-400 rounded-lg flex items-center p-6">
          <svg
            class="w-16 h-16 fill-current mr-4 hidden lg:block"
            viewBox="0 0 20 20"
          >
            <path
              d="M17.431,2.156h-3.715c-0.228,0-0.413,0.186-0.413,0.413v6.973h-2.89V6.687c0-0.229-0.186-0.413-0.413-0.413H6.285c-0.228,0-0.413,0.184-0.413,0.413v6.388H2.569c-0.227,0-0.413,0.187-0.413,0.413v3.942c0,0.228,0.186,0.413,0.413,0.413h14.862c0.228,0,0.413-0.186,0.413-0.413V2.569C17.844,2.342,17.658,2.156,17.431,2.156 M5.872,17.019h-2.89v-3.117h2.89V17.019zM9.587,17.019h-2.89V7.1h2.89V17.019z M13.303,17.019h-2.89v-6.651h2.89V17.019z M17.019,17.019h-2.891V2.982h2.891V17.019z"
            />
          </svg>

          <div class="text-gray-700">
            <p class="font-semibold text-3xl">
              {{ processes.length }}
            </p>
            <p>Sager</p>
          </div>
        </div>
      </div>
      <div class="w-1/2 xl:w-1/4 px-3">
        <div class="w-full bg-white border text-aktio-darkblue-400 rounded-lg flex items-center p-6">
          <svg
            class="w-12 h-12 fill-current mr-4 hidden lg:block"
            style="width: 3rem !important"
            viewBox="0 0 232.058 232.058"
          >
            <g>
              <path
                d="M16.45,103.92c5.972-33.874,29.885-61.607,61.536-73.034L76.5,33.461c-2.072,3.587-0.844,8.174,2.743,10.246
		c1.182,0.682,2.471,1.007,3.744,1.007c2.591,0,5.112-1.345,6.501-3.75l10.34-17.901c0.995-1.723,1.266-3.77,0.751-5.691
		c-0.515-1.922-1.771-3.56-3.494-4.555L79.179,2.476c-3.59-2.072-8.175-0.842-10.246,2.744c-2.071,3.587-0.843,8.174,2.744,10.245
		l1.868,1.079C36.59,29.628,8.631,61.879,1.679,101.315c-3.364,19.06-1.651,38.703,4.953,56.807c1.11,3.043,3.984,4.931,7.047,4.931
		c0.853,0,1.722-0.147,2.569-0.456c3.892-1.42,5.896-5.725,4.476-9.616C15.022,137.352,13.544,120.388,16.45,103.92z"
              />
              <path
                d="M193.646,180.699c-3.172-2.666-7.902-2.256-10.567,0.915c-21.818,25.955-56.084,38.429-89.437,32.542
		c-16.189-2.855-31.295-9.876-43.866-20.192h2.413c4.143,0,7.5-3.358,7.5-7.5c0-4.142-3.357-7.5-7.5-7.5H32.222
		c-1.927-0.182-3.923,0.376-5.523,1.718c-1.56,1.308-2.449,3.116-2.639,4.993c-0.001,0.007-0.002,0.013-0.003,0.02
		c0,0.002,0,0.004,0,0.006c-0.021,0.215-0.031,0.431-0.033,0.647c-0.001,0.038-0.006,0.074-0.006,0.112l-0.008,20.67
		c-0.002,4.142,3.355,7.501,7.497,7.503h0.003c4.141,0,7.498-3.356,7.5-7.497l0.001-2.612c14.795,12.509,32.75,21.004,52.026,24.403
		c6.326,1.116,12.679,1.662,18.994,1.662c32.221,0,63.415-14.205,84.53-39.323C197.227,188.096,196.817,183.364,193.646,180.699z"
              />
              <path
                d="M231.052,142.479c-2.07-3.587-6.659-4.816-10.245-2.745l-2.467,1.424c0.102-0.53,0.222-1.057,0.315-1.588
		c5.109-28.982-1.372-58.216-18.25-82.32c-16.878-24.104-42.132-40.191-71.109-45.296c-4.08-0.718-7.97,2.006-8.687,6.085
		c-0.719,4.079,2.005,7.969,6.084,8.688c25.031,4.41,46.847,18.306,61.426,39.128c14.55,20.779,20.153,45.973,15.791,70.958
		l-1.099-1.903c-2.071-3.587-6.658-4.818-10.245-2.747c-3.588,2.07-4.817,6.657-2.747,10.245l10.336,17.909
		c0.995,1.723,2.633,2.98,4.555,3.495c0.639,0.171,1.291,0.256,1.941,0.256c1.305,0,2.6-0.341,3.75-1.005l17.906-10.338
		C231.894,150.653,233.123,146.066,231.052,142.479z"
              />
            </g>
          </svg>

          <div class="text-gray-700">
            <p class="font-semibold text-3xl">
              {{ numberOfOnGoingProcesses }}
            </p>
            <p>Igangværende sager</p>
          </div>
        </div>
      </div>
      <div class="w-1/2 xl:w-1/4 px-3">
        <div class="w-full bg-white border text-aktio-darkblue-400 rounded-lg flex items-center p-6 mb-6 xl:mb-0">
          <svg
            class="w-16 h-16 fill-current mr-4 hidden lg:block"
            viewBox="0 0 20 20"
          >
            <path
              d="M17.35,2.219h-5.934c-0.115,0-0.225,0.045-0.307,0.128l-8.762,8.762c-0.171,0.168-0.171,0.443,0,0.611l5.933,5.934c0.167,0.171,0.443,0.169,0.612,0l8.762-8.763c0.083-0.083,0.128-0.192,0.128-0.307V2.651C17.781,2.414,17.587,2.219,17.35,2.219M16.916,8.405l-8.332,8.332l-5.321-5.321l8.333-8.332h5.32V8.405z M13.891,4.367c-0.957,0-1.729,0.772-1.729,1.729c0,0.957,0.771,1.729,1.729,1.729s1.729-0.772,1.729-1.729C15.619,5.14,14.848,4.367,13.891,4.367 M14.502,6.708c-0.326,0.326-0.896,0.326-1.223,0c-0.338-0.342-0.338-0.882,0-1.224c0.342-0.337,0.881-0.337,1.223,0C14.84,5.826,14.84,6.366,14.502,6.708"
            />
          </svg>

          <div class="text-gray-700">
            <p class="font-semibold text-3xl">
              {{ numberOfOnFinishedProcesses }}
            </p>
            <p>Færdigbehandlede sager</p>
          </div>
        </div>
      </div>

      <div class="w-1/2 xl:w-1/4 px-3">
        <div class="w-full bg-white border text-aktio-darkblue-400 rounded-lg flex items-center p-6 mb-6 xl:mb-0">
          <svg
            class="w-16 h-16 fill-current mr-4 hidden lg:block"
            viewBox="0 0 20 20"
          >
            <path
              d="M17.684,7.925l-5.131-0.67L10.329,2.57c-0.131-0.275-0.527-0.275-0.658,0L7.447,7.255l-5.131,0.67C2.014,7.964,1.892,8.333,2.113,8.54l3.76,3.568L4.924,17.21c-0.056,0.297,0.261,0.525,0.533,0.379L10,15.109l4.543,2.479c0.273,0.153,0.587-0.089,0.533-0.379l-0.949-5.103l3.76-3.568C18.108,8.333,17.986,7.964,17.684,7.925 M13.481,11.723c-0.089,0.083-0.129,0.205-0.105,0.324l0.848,4.547l-4.047-2.208c-0.055-0.03-0.116-0.045-0.176-0.045s-0.122,0.015-0.176,0.045l-4.047,2.208l0.847-4.547c0.023-0.119-0.016-0.241-0.105-0.324L3.162,8.54L7.74,7.941c0.124-0.016,0.229-0.093,0.282-0.203L10,3.568l1.978,4.17c0.053,0.11,0.158,0.187,0.282,0.203l4.578,0.598L13.481,11.723z"
            />
          </svg>

          <div class="text-gray-700">
            <p class="font-semibold text-3xl">
              {{ noProcessedFiles }}
            </p>
            <p>Dokumenter behandlet</p>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-wrap pt-5 -mx-3">
      <div class="w-full md:w-1/3 px-3">
        <p class="text-xl font-semibold mb-4">
          Antal sager oprettet
        </p>

        <div class="w-full bg-white border rounded-lg p-4 mb-8 xl:mb-0">
          <canvas
            v-if="processes.length > 0"
            id="processes-chart"
            class="w-full h-full"
          />
          <div
            v-else
            class="block w-full h-full text-sm"
          >
            Mangler data
          </div>
        </div>
      </div>

      <div class="w-full md:w-1/3 px-3">
        <p class="text-xl font-semibold mb-4">
          Undtagelser
        </p>

        <div class="w-full bg-white border rounded-lg p-4 mb-8 xl:mb-0">
          <canvas
            v-if="processes.length > 0"
            id="redactions-chart"
            class="w-full h-full"
          />
          <div
            v-else
            class="block w-full h-full text-sm"
          >
            Mangler data
          </div>
        </div>
      </div>

      <div class="w-full md:w-1/3 px-3">
        <p class="text-xl font-semibold mb-4">
          Seneste sager
        </p>
        <div class="w-full bg-white border rounded-lg p-4">
          <div
            v-if="processes.length === 0"
            class="text-sm"
          >
            Ingen sager
          </div>
          <div
            v-for="process in processes.slice(0, 5)"
            :key="process.process_id"
            class="w-full cursor-pointer bg-gray-100 border rounded-lg flex justify-between items-center px-4 py-2 mb-4"
            @click="goToProcess(process.process_id)"
          >
            <div>
              <p class="font-semibold text-lg">
                {{ process.display_name }}
              </p>
              <div v-if="process.process_status.name === 'Færdigbehandlet'">
                <p>Afsluttet: {{ returnDate(process.process_finished) }}</p>
                <p
                  v-show="returnDateDiff(
                    process.Request_received_date,
                    process.process_finished
                  ) >= 0
                  "
                >
                  Sagsbehandlingstid:
                  {{
                    returnDateDiff(
                      process.request_received_date,
                      process.process_finished
                    )
                  }}
                  dage
                </p>
              </div>
              <div v-else>
                <p class="text-xs">
                  Frist
                </p>
                <p>{{ returnDate(process.request_deadline_date) }}</p>
              </div>
            </div>
            <div v-if="process.process_status.name === 'Færdigbehandlet'">
              <redaction-process-status :process="process" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Chart from "chart.js/auto";
import dayjs from "dayjs";
import 'dayjs/locale/da'

import ProcessStatus from "@/components/controls/ProcessStatus.vue";

dayjs.locale('da')

export default {
  name: "DashboardHome",
  components: {
    "redaction-process-status": ProcessStatus
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState(["user", "processes", "noProcessedFiles", "redactionGraphStats", "processGraphStats"]),
    numberOfOnGoingProcesses() {
      const onGoing = this.processes.filter(p => p.process_status.name !== "Færdigbehandlet") || [];
      return onGoing.length;
    },
    numberOfOnFinishedProcesses() {
      const onGoing = this.processes.filter(p => p.process_status.name === "Færdigbehandlet") || [];
      return onGoing.length;
    },
    userFirstName() {
      const userData = (this.user || {}).userData;
      const firstName = userData.first_name || "Bruger";
      return firstName;
    },
  },
  async mounted() {
    // load process data form API
    await this.$store.dispatch("loadProcessesFromApi");
    await this.$store.dispatch("getNumberOfProcessedFiles", this.user.userData.id);
    await this.$store.dispatch("getRedactionGraphStats");
    // load creating case data from API
    await this.$store.dispatch("loadDocumentTypes");
    await this.$store.dispatch("loadDocumentStatus");
    
    // Charts
    const processesChartCanvas = document.getElementById("processes-chart");
    const redactionsChartCanvas = document.getElementById("redactions-chart");
    if (processesChartCanvas) {
      new Chart(processesChartCanvas, this.processGraphStats);
    }
    
    if (redactionsChartCanvas) {
      new Chart(redactionsChartCanvas, this.redactionGraphStats);
    }
    // new Chart(document.getElementById("processes-chart"), this.processGraphStats);
    // new Chart(document.getElementById("processes-chart"), {
    //  data: this.redactionGraphStats,
    //  options: {
    //    scales: {
    //      yAxes: [{
    //        ticks: {
    //          stepSize: 10,
    //        }
    //      }]
    //    }
    //  }
    // });
    // new Chart(document.getElementById("redactions-chart"), this.redactionGraphStats);
    // new Chart(document.getElementById("redactions-chart"), {
    //   data: this.processGraphStats,
    //   options: {
    //     scales: {
    //       yAxes: [{
    //         ticks: {
    //           stepSize: 20,
    //         }
    //       }]
    //     }
    //   }
    // });
  },
  methods: {
    async goToProcess(processId) {
      // TODO: consider where to load things - before router change or after?
      await this.$store.dispatch("loadSpecificProcess", processId);
      await this.$store.dispatch("loadFilesFromApi", processId);
      this.$router.push({
        name: "Processes",
        params: { processId: processId },
      });
    },
    returnDateDiff(fromDate, toDate) {
      return dayjs(toDate).diff(fromDate, "day");
    },
    returnDate(dateString) {
      return dayjs(dateString).format("DD-MM-YYYY");
    },
  },
};
</script>