<template>
  <div class="h-full">
    <redaction-button-exit
      class="absolute right-0 top-0 m-2"
      @emit-close="closeModal()"
    />
    <!-- Tabbar for selecting law -->
    <div 
      class="tab-container"
    >
      <button
        v-for="law in lawTabs"
        :key="law.key"
        :class="['tab-button', { 'active': selectedLawTab === law.key }]"
        @click="selectTab(law.key)"
      >
        {{ law.label }}
      </button>
    </div>
    <!-- End Tabbar for selecting law -->
    <div
      class="py-4 pt-6 flex justify-between items-center"
      :class="[
        {
          'px-2': !shouldEmit || elementType === 'search',
        },
      ]"
    >
      <div class="flex space-x-2">
        <input
          v-if="!showSelectedParagraph || forceShowFilteredParagraph"
          v-model="paragraphSearch"
          class="bg-white h-10 w-96 px-5 rounded-lg border text-sm focus:outline-none"
          type="text"
          placeholder="Søg i lovparagraffer"
        >
        <standard-button
          v-if="!shouldEmit && !lawNotChosen && !forceShowFilteredParagraph"
          button-title="Slet undtagelse"
          button-text="Slet undtagelse"
          button-type="delete"
          button-icon="remove"
          @click="removeRedaction()"
        />
        <standard-button
          v-else-if="!lawNotChosen && !forceShowFilteredParagraph"
          button-title="Fjern lovparagraf"
          button-text="Fjern"
          button-type="cancel"
          button-icon="remove"
          @click="clearLaw()"
        />
      </div>
      <!-- <div v-if="(lawNotChosen || forceShowFilteredParagraph) && !shouldEmit" class="text-sm">-->
      <!-- Vælg lovparagraf nedenfor-->
      <!-- </div> -->

      <redaction-law-paragraph
        v-if="!shouldEmit && !forceShowFilteredParagraph"
        :law-containing-element="element"
      />
    </div>
    <div class="flex flex-col text-sm">
      <div
        class="overflow-y-auto"
        :style="{ height: paragraphHeight + 'px' }"
      >
        <div v-if="showSelectedParagraph && !forceShowFilteredParagraph">
          <div
            v-if="element.law.paragraph_label"
            class="font-bold pb-1"
            :class="[
              {
                'mx-2': !shouldEmit || elementType === 'search',
              },
            ]"
          >
            {{ element.law.paragraph_label }}
          </div>
          <div
            v-if="element.law.paragraph_label"
            class="bg-aktio-darkblue-100 cursor-pointer block p-2 rounded justify-between"
            :class="[
              {
                'mx-1': !shouldEmit || elementType === 'search',
              },
            ]"
          >
            <div>
              <span 
                v-if="element.law.stk !== '1'"
                class="font-bold mr-2"
              >Stk. {{ element.law.stk }}
                <br>
              </span>
              <p
                class="text-normal"
                v-html="element.law.stk_label"
              />
            </div>
            <div class="h-full w-full my-2 self-center">
              <standard-button
                button-title="Fjern og vælg ny"
                button-text="Fjern og vælg ny"
                button-type="blue"
                button-icon="law"
                @click="setShowFilteredParagraphs(true)"
              />
            </div>
          </div>
        </div>
        <div
          v-if="showFilteredParagraphs || forceShowFilteredParagraph"
          class="law-paragraph-text"
          :class="[
            {
              'mx-2': !shouldEmit || elementType === 'search',
            },
          ]"
        >
          <div
            v-for="laws in filteredList"
            :key="laws.id"
          >
            <div
              v-if="laws.paragraph_label"
              class="font-semibold bg-gray-600 text-white p-2 rounded-t mt-2 sticky-header"
            >
              {{ laws.paragraph_label }}
            </div>
            <div
              v-for="(stk,index) in laws.stks"
              :key="stk.id"
            >
              <a 
                v-if="stk.is_disabled || isLawSelected(stk.id)"
                class="border bg-gray-100 cursor-not-allowed block p-2 list-none text-gray-500 my-px"
              >
                <span
                  v-if="stk.stk !== '1'"
                  class="font-bold mr-2"
                >Stk. {{ stk.stk }}<br></span>
                <span 
                  class="text-normal list-none"
                  v-html="stk.stk_label"
                ></span>
              </a>
              <a
                v-else
                title="Vælg paragraf"
                class="border-2 hover:border-zinc-700  cursor-pointer block p-2"
                :class="[
                  {
                    'border-zinc-100 bg-zinc-100': index % 2 == 0,
                    'border-zinc-300 bg-zinc-300': index % 2 != 0
                  }]"
                @click="
                  selectLaw = stk;
                  updateSelectedParagraph(stk);
                  $emit('close');
                "
              >
                <div class="flex items-center gap-2">
                  <span
                    v-if="stk.stk !== '1'" 
                    class="font-bold flex-shrink-0"
                  >Stk. {{ stk.stk }}</span>
                </div>
                <span 
                  class="text-normal list-none"
                  v-html="stk.stk_label"
                ></span>
              </a>
            </div>
          </div>
        </div>
        <div
          v-if="paragraphSearch && filteredList.length === 0"
          class="mx-2"
        >
          Ingen paragraf fundet
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import LawParagraph from "@/components/controls/LawParagraph.vue";
import StandardButton from "@/components/controls/StandardButton.vue";
import Mark from "mark.js";
import buttonExit from "@/components/controls/buttonExit.vue";

// const _ = require("lodash");
import _ from "lodash";

export default {
  name: "LawSelection",
  components: {
    // "search-box": SearchBox,
    "redaction-law-paragraph": LawParagraph,
    "redaction-button-exit": buttonExit,
    StandardButton,
  },
  props: {
    element: Object,
    elementType: {
      type: String,
      default: "redaction",
    },
    denialLaw: String,
    lawsAlreadySelected: {
      tpye: Array,
      default: () => []
    },
    paragraphFromRedaction: Object,
    documentId: Number,
    documentStatus: Object,
    documents: Array,
    processId: String,
    paragraphHeight: {
      type: Number,
      default: 500,
    },
    shouldEmit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      paragraphSearch: "",
      selectLaw: "",
      localElement: this.element,
      forceShowFilteredParagraph: false,
      localDocuments: [],
      selectedLawTab: "FVL",
    };
  },
  computed: {
    ...mapGetters(['findLawById']),
    ...mapState([
      "lawsLoaded",
      "forvaltningsloven",
      "offentlighedsloven",
      "sundhedsloven",
      "egenacces",
      "forvaltningslovenAfslag",
      "offentlighedslovenAfslag",
      "offentlighedsloven1985",
      "miljøoplysningsloven",
      "openProcess",
      "documentExceptStatus",
      "documentReadyStatus",
      "user"
    ]),
    //TODO: refactor
    showSelectedParagraph() {
      const element = this.localElement || {};
      return element.law && !this.paragraphSearch
    },
    showFilteredParagraphs() {
      const element = this.localElement || {};
      return this.paragraphSearch || !element.law || !element.law.id
    },
    lawNotChosen() {
      const element = this.localElement || {};
      return !element.law
    },
    // TODO: improve law selection
    lawsGrouped() {
      let selectedLaw = this.forvaltningsloven;
      if (this.denialLaw && this.denialLaw !== "MOLA") {
        switch (this.denialLaw) {
          case "OFLA":
            selectedLaw = this.offentlighedsloven;
            break;
          case "FVLA":
            selectedLaw = this.forvaltningsloven;
            break;
        }
      } else {
        // switch (this.openProcess.law_set.name) {
        switch (this.selectedLawTab) {
          case "OFL":
            selectedLaw = this.offentlighedsloven;
            break;
          case "SUL":
            selectedLaw = this.sundhedsloven;
            break;
          case  "EGA":
            selectedLaw = this.egenacces;
            break;
          case  "MOL":
            selectedLaw = this.miljøoplysningsloven;
            break;
          case  "OFL 1985":
            selectedLaw = this.offentlighedsloven1985;
            break;
          case "FVLA":
            selectedLaw = this.forvaltningsloven;
            break;
          default:
            selectedLaw = this.forvaltningsloven;
        }
      }
      return _(selectedLaw)
        .groupBy((x) => x.paragraph_label)
        .map((value, key) => ({ paragraph_label: key, stks: value }))
        .value();
    },
    filteredList() {
      return this.lawsGrouped.filter((law) => {
        return (
          law.paragraph_label
            .toLowerCase()
            .includes(this.paragraphSearch.toLowerCase()) ||
          law.stks.some((stk) => {
            return stk.stk_label
              .toLowerCase()
              .includes(this.paragraphSearch.toLowerCase());
          })
        );
      });
    },
    lawTabs() {
      switch (this.openProcess.law_set.name) {
        case "FVL":
          return [
            { key: "FVL", label: "Forvaltningsloven" },
          ];
        case "OFL":
          return [
            { key: "OFL", label: "Offentlighedsloven" },
          ];
        case "SUL":
          return [
            { key: "SUL", label: "Sundhedsloven" },
          ];
        case "EGA":
          return [
            { key: "EGA", label: "Egenacces" },
          ];
        case "MOL":
          if (this.denialLaw === "MOLA") {
            return [
              { key: "MOL", label: "Miljøoplysningsloven" },
              { key: "FVLA", label: "Forvaltningsloven" },
              { key: "OFL 1985", label: "Offentlighedsloven 1985" },
            ];
          } else {
            return [
              { key: "MOL", label: "Miljøoplysningsloven" },
              { key: "FVL", label: "Forvaltningsloven" },
              { key: "OFL 1985", label: "Offentlighedsloven 1985" },
            ];
          }
        default:
          return [
            { key: "FVL", label: "Forvaltningsloven" },
            { key: "OFL", label: "Offentlighedsloven" },
            { key: "SUL", label: "Sundhedsloven" },
            { key: "EGA", label: "Egenacces" },
            { key: "MOL", label: "Miljøoplysningsloven" },
            { key: "OFL 1985", label: "Offentlighedsloven 1985" },
          ];
      }
    }
  },
  watch: {
    element(newVal) {
      this.localElement = newVal;
    },
    paragraphSearch: {
      handler: function () {
        const context = document.getElementsByClassName("law-paragraph-text");
        for (var i = 0; i < context.length; i++) {
          const intance = new Mark(context[i]);
          intance.unmark(context[i]);
          intance.mark(this.paragraphSearch);
        }
      },
    },
  },
  async mounted() {
    if (this.lawsLoaded === false) {
      await this.$store.dispatch("loadLaws");
    }
    if (this.openProcess.law_set.name === "INS") {
      const paragraphObj = {
        redactionId: this.localElement.id,
        documentId: document.id,
        processId: this.processId,
        data: {},
      };
      await this.$store.dispatch("updateRedaction", paragraphObj);
      this.closeModal()
    }
    this.selectedLawTab = this.defaultLawTab()
    if (!this.documentReadyStatus.id) {
      await this.$store.dispatch("loadDocumentStatus");
    }
    if(this.documentId && this.documents) {
      // Add single document to document collection to enable support for multiple documents
      this.localDocuments = [{id: this.documentId}, ...this.documents]
    } else if (this.documents) {
      this.localDocuments = this.documents
    }
    //} else {
    //  this.localDocuments = this.documents
    //}
  },
  methods: {
    defaultLawTab() {
      switch (this.openProcess.law_set.name) {
        case "OFL":
          return "OFL";
        case "SUL":
          return "SUL";
        case "EGA":
          return "EGA";
        case "MOL":
          return "MOL";
        default:
          return "FVL";
      }
    },
    selectTab(lawKey) {
      this.selectedLawTab = lawKey;
    },
    async setLatestLaw(stk) {
      if(this.user.userData && this.user.userData.id && this.elementType === "redaction") {
        try {
          const lawId = stk.id;
          let currentLaws = this.user.userData.latest_laws || [];
          currentLaws = currentLaws.filter(id => id !== lawId);
          currentLaws.push(lawId);
          // Ensure to keep only the latest three laws
          if (currentLaws.length > 3) {
            currentLaws.shift();
          }
          currentLaws = []
          const response = await this.$store.dispatch("updateUser", {user_id: this.user.userData.id, latest_laws: currentLaws});

          if (response.status_code === 200) {
            this.user.userData.latest_laws = currentLaws;
            const laws = currentLaws
            .map(latestlawId => this.findLawById(latestlawId))
            .filter(law => law !== undefined)
            .reverse();
            const event = new CustomEvent('setLatestLaw', { detail: laws });
            window.document.querySelector('#pdfViewerIFrame').contentDocument.dispatchEvent(event);
          } else {
            console.error("Der opstod en fejl under opdatering af seneste lov");
          }
        } catch (error) {
          console.error("Fejl under forsøg på at sætte seneste lov:", error);
        }
      }
    },
    async removeRedaction() {
      // @todo payload
      // await this.$store.dispatch("posthogCapture", {event_name:"DeleteRedaction", data:{}});
      if (this.elementType === "redaction") {
        if (this.localDocuments.length > 0) {
          this.localDocuments.forEach(document => {
            const redactionObj = {
              id: this.element.id,
              documentId: document.id,
              processId: this.processId,
            }
            this.$store.dispatch("removeRedaction", redactionObj);
          })
        } else {
          const redactionObj = {
            id: this.element.id,
            documentId: this.documentId,
            processId: this.processId,
          }
          this.$store.dispatch("removeRedaction", redactionObj);
        }
        
      } else if (this.elementType === "document") {
        if (this.$attrs.update) {
          let element = this.localElement;
          element.law = 0;
          element.excepted_comment = "";
          this.$attrs.update(element);
        }
        if (this.localDocuments.length > 0) {
          this.localDocuments.forEach(async (document, index) => {
            await this.$store.dispatch("updateFile", {
              id: document.id,
              processId: this.processId,
              documentId: document.id,
              law: {
                id: 0,
              },
            });
            const documentChangesObj = {
              id: document.id,
              documentId: document.id,
              processId: this.processId,
              data: {
                document_status: {
                  id: this.documentReadyStatus.id
                },
                law: null
              }
            }
  
            // Update document status to 'klar til behandling'
            await this.$store.dispatch("setDocumentStatus", documentChangesObj)
  
            // If all documents have been processed, reload all files
            if(this.localDocuments.length - 1 === index) {
              // Reload documents for process
              await this.$store.dispatch("loadFilesFromApi", this.processId)
              await this.$store.dispatch("updateRestorationStatus", {
                processId: this.processId,
                status: "ChangesDetected"
              });
              const toast_message = 'Undtagelsen er fjernet.'
              this.$showRegisterToast(toast_message, "success", 4000, {ProcessGuid: this.processId})
            }
          });
        } else {
          const documentChangesObj = {
            id: this.documentId,
            documentId: this.documentId,
            processId: this.processId,
            data: {
              document_status: {
                id: this.documentReadyStatus.id
              },
              law: null
            }
          }
          // Update document status to 'klar til behandling'
          await this.$store.dispatch("setDocumentStatus", documentChangesObj)

          // Reload documents for process
          await this.$store.dispatch("loadFilesFromApi", this.processId)
          const toast_message = 'Undtagelsen er fjernet.'
          this.$showRegisterToast(toast_message, "success", 4000, {ProcessGuid: this.processId})
        }
      }
      this.$emit("close");
    },
    clearLaw() {
      // this.$store.dispatch("posthogCapture", {event_name:"ClearLaw", data:{}});

      if (this.$attrs.update && this.elementType === "search") {
        let element = this.localElement;
        element.law = { id: 0 };
        this.$attrs.update(element);
      }
      this.emitLawSelection({ id: 0 });
      if (this.elementType === "search") {
        this.$emit("close");
      }
    },
    customParagraphLabel({ stk_label, stk, paragraph }) {
      if (stk !== "1") {
        return `$ ${paragraph}, stk. ${stk}: ${stk_label}`;
      } else {
        return `$ ${paragraph}, ${stk_label}`;
      }
    },
    emitLawSelection(paragraph) {
      // TODO: check if this is actually used anywhere???
      this.$emit("update-law-selection", {
        id: this.documentId,
        documentId: this.documentId,
        processId: this.processId,
        law: paragraph,
      });
    },
    async updateSelectedParagraph(paragraph) {
      console.log("updating selected paragprah")
      console.log(this.elementType)
      if (this.elementType === "redaction") {
        if (this.localDocuments.length > 0) {
          this.localDocuments.forEach(async (document) => {
            const paragraphObj = {
              redactionId: this.localElement.id,
              documentId: document.id,
              processId: this.processId,
              data: {
                law: {
                  id: paragraph.id,
                },
              },
            };
            await this.$store.dispatch("updateRedaction", paragraphObj);
          })
        } else {
          const paragraphObj = {
            redactionId: this.localElement.id,
            documentId: this.documentId,
            processId: this.processId,
            data: {
              law: {
                id: paragraph.id,
              },
            },
          };
          await this.$store.dispatch("updateRedaction", paragraphObj);
        }
      } else if (this.elementType === "document") {
        if (this.localDocuments.length > 0) {
          this.localDocuments.forEach(async (document, index) => {
            const documentLawObj = {
              id: document.id,
              processId: this.processId,
              documentId: document.id,
              data: {
                law: {
                  id: paragraph.id,
                },
              },
            }
            const documentChangesObj = {
              id: document.id,
              processId: this.processId,
              documentId: document.id,
              data: {
                document_status: {
                  id: this.documentExceptStatus.id
                }
              }
            }
  
            // Update document status to 'exempt'
            await this.$store.dispatch("setDocumentStatus", documentChangesObj)
  
            // Update document law 
            await this.$store.dispatch("setDocumentLaw", documentLawObj)
  
            if(this.localDocuments.length - 1 === index) {
              // Reload documents for process
              await this.$store.dispatch("loadFilesFromApi", this.processId);
              const toast_message = 'Dokumentet er blevet undtaget.';
              this.$showRegisterToast(toast_message, "success", 4000, {ProcessGuid: this.processId})
            }
          });
        } else {
          const documentLawObj = {
            id: this.documentId,
            processId: this.processId,
            documentId: this.documentId,
            data: {
              law: {
                id: paragraph.id,
              },
            },
          }
          const documentChangesObj = {
            id: this.documentId,
            processId: this.processId,
            documentId: this.documentId,
            data: {
              document_status: {
                id: this.documentExceptStatus.id
              }
            }
          }
          // Update document status to 'exempt'
          await this.$store.dispatch("setDocumentStatus", documentChangesObj)

          // Update document law 
          await this.$store.dispatch("setDocumentLaw", documentLawObj)

          // Reload documents for process
          await this.$store.dispatch("loadFilesFromApi", this.processId);
          const toast_message = 'Dokumentet er blevet undtaget.';
          this.$showRegisterToast(toast_message, "success", 4000, {ProcessGuid: this.processId})
        }
      } else if (this.elementType === "process") {
          this.$emit('modal-event', paragraph);
      } else if (this.elementType === "search") {
        if (this.$attrs.update) {
          let element = this.localElement;
          element.law = paragraph;
          this.$attrs.update(element);
        }
        await this.$store.dispatch("loadFilesFromApi", this.processId);
        
      } else {
        // TODO: find a better solution for updating law paragraph in DocStatus.vue
        if (this.$attrs.update) {
          let element = this.localElement;
          element.law = paragraph;
          this.$attrs.update(element);
        }
        if (!this.shouldEmit) {
          if (this.localDocuments.length > 0) {
            this.localDocuments.forEach(async (document) => {
              await this.$store.dispatch("updateFile", {
                id: document.id,
                law: paragraph,
                document_status: this.documentStatus,
                processId: this.processId,
                documentId: document.id,
              });
            })
          } else {
            await this.$store.dispatch("updateFile", {
                id: this.documentId,
                law: paragraph,
                document_status: this.documentStatus,
                processId: this.processId,
                documentId: this.documentId,
              });
          }
        } else {
          this.paragraphSearch = "";
          this.localElement.law = paragraph;
          this.emitLawSelection(paragraph);
        }
      }
      this.setLatestLaw(paragraph);
      document.body.classList.remove("modal-open");
    },
    setShowFilteredParagraphs(){
      this.$store.dispatch("posthogCapture", {event_name:"ShowFilterParagraph", data:{}});

      this.forceShowFilteredParagraph = true
    },
    isLawSelected(lawId) {
      return this.lawsAlreadySelected.some(selectedLaw => selectedLaw.id === lawId);
    },
    closeModal() {
      document.body.classList.remove("modal-open"); // to enable scrolling afterwards
      this.$emit("close");
    },
  },
};
</script>

<style>
/* .highlightText {
  background: yellow;
} */
/* .tab-button {
  padding: 0.5rem 1rem;
  border: none;
  background-color: #e2e8f0;
  border-radius: 0.25rem;
  cursor: pointer;
}
.tab-button.active {
  background-color: #1c64f2;
  color: white;
} */
.tab-container {
  display: flex;
  border-bottom: 1px solid #ccc;
}

.tab-button {
  padding: 0.5rem 1rem;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-bottom: none;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  margin-right: 0.2rem;
}

.tab-button.active {
  background-color: #ffffff;
  border-bottom: 1px solid #ffffff;
  box-shadow: 0 -2px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  top: 1px;
}

.sticky-header {
  top: 0;
  z-index: 10;
  background-color: #4a4a4a; /* Dark gray */
}

</style>