<template>
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    :width="iconSize"
    :height="iconSize" 
    viewBox="0 0 22 22" 
    fill="none" 
    :stroke="iconColor"
    :stroke-width="strokeWidth" 
    stroke-linecap="round" 
    stroke-linejoin="round"
    class="icon icon-tabler icons-tabler-outline icon-tabler-restore"
  >
    <path
      stroke="none"
      d="M0 0h24v24H0z"
      fill="none"
    />
    <path 
      d="M3.06 13a9 9 0 1 0 .49 -4.087"
    />
    <path 
      d="M3 4.001v5h5"
    />
    <path
      d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"
    />
  </svg>
</template>

<script>
export default {
    name: "IconRestore",
    props: {
        strokeWidth: {
            type: String,
            default: "3",
        },
        iconSize: {
            type: String,
            default: "24",
        },
        iconColor: {
            type: String,
            default: "currentColor",
        },
    }
};
</script>

<style>
</style>