<template>
  <div class="w-full h-full">
    <div class="bg-white">
      <div>
        <div>
          <!-- search results -->
          <div>
            <!-- <ul class="flex border-b mt-4">
              <li class="-mb-px mr-1">
                <a
                  class="bg-white inline-block border-l border-t border-r rounded-t py-2 px-4 text-xs text-blue-700 cursor-pointer"
                  title="Vis overstregninger"
                  >Overstregninger</a
                >
              </li>
              <li class="mr-1">
                <a
                  class="bg-white inline-block py-2 px-4 text-blue-500 hover:text-blue-800 text-xs cursor-pointer"
                  title="Naviger i åbne dokumenter"
                  >Navigation</a
                >
              </li>
            </ul> -->
            <!-- TODO: rethink search in redactions -->
            <!-- <div class="flex items-center bg-gray-200 rounded-md mt-1">
              <div class="pl-2">
                <svg
                  class="fill-current text-gray-500 w-4 h-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"
                  />
                </svg>
              </div>
              <input
                class="w-full rounded-md bg-gray-200 text-gray-700 leading-tight focus:outline-none py-2 px-2"
                id="search"
                type="text"
                placeholder="Søg i overstregninger"
                v-model="searchRedactions"
              />
            </div> -->
            <!-- No redactions text -->
            <div
              v-if="redactions && redactions.length === 0"
              class="mt-4 text-sm"
            >
              Ingen overstregninger
            </div>
          </div>
          <!-- redactions -->
          <div
            v-for="(redaction, redactionIndex) in redactions"
            :id="'redaction-overview-' + redaction.id"
            :key="redaction.id"
            class="pt-2 text-sm"
          >
            <div v-if="redactionIndex >= 0">
              <div
                class="flex justify-start cursor-pointer text-gray-700 hover:text-blue-800 hover:bg-aktio-darkblue-200 py-2 rounded"
                :class="{
                  'text-blue-800 bg-aktio-darkblue-200 rounded-b-none': isRedactionExpanded(
                    redaction.id
                  ),
                }"
                @click="expandRedaction(redaction)"
                @mouseover="hoverRedaction(redaction.id)"
                @mouseleave="redactionHovering = false"
              >
                <span class="flex-none w-4 mx-2">
                  <span
                    v-if="
                      redactionHovering === redaction.id &&
                        redactionExpanded !== redaction.id
                    "
                  >
                    <!-- chevron right -->
                    <chevron-right class="pt-1 h-4 w-4" />
                  </span>
                  <span v-else-if="redactionExpanded === redaction.id">
                    <!-- chevron down -->
                    <chevron-down class="pt-1 h-4 w-4" />
                  </span>
                  <span v-else>{{ redactionIndex + 1 }}</span>
                </span>
                <div class="flex-grow font-medium px-2 break-words">
                  <div>
                    <span v-if="redaction.redaction_type === 'box'">BOKS [s. {{ redaction.page_number }}]</span>
                    <span v-else-if="redaction.redaction_type === 'page'">SIDE  [s. {{ redaction.page_number }}]</span>
                    <span v-else>{{ redaction.redacted_text }}</span>
                  </div>
                </div>
                <redaction-law-paragraph :law-containing-element="redaction" />
              </div>
            </div>
            <!-- BEGIN edit redaction -->
            <div
              v-show="redactionExpanded === redaction.id"
              class="bg-aktio-darkblue-200 rounded-b px-2 py-3 mb-1"
            >
              <div class="flex flex-wrap">
                <standard-button
                  class="mb-2 mr-2"
                  button-title="Slet undtagelse"
                  button-text="Slet undtagelse"
                  button-type="delete"
                  button-icon="law"
                  @click="removeRedaction(redaction)"
                />
                <standard-button
                  class="mb-2"
                  button-title="Vælg lovparagraf"
                  button-text="Vælg lovparagraf"
                  button-type="cancel"
                  button-icon="law"
                  @click="setShowTools(redaction)"
                />
              </div>
              <!-- TODO: readd comment -->
              <!-- <div>
                <label
                  :for="'redaction-comment-' + redaction.id"
                  class="text-sm"
                  >Kommentar</label
                >
                <textarea
                  v-model="redaction.comment"
                  :id="'redaction-comment-' + redaction.id"
                  @blur="updateRedactionComment(reaction)"
                  class="text-gray-800 bg-gray-100 rounded border border-gray-400 leading-normal resize-none w-full h-20 py-2 px-3 font-medium placeholder-gray-700 focus:outline-none focus:bg-white"
                  placeholder="Skriv en kommentar"
                ></textarea>
              </div> -->
            </div>
            <!-- END edit redaction -->
            <!-- </div> -->
            <!-- <div v-for="(redaction, rIndex) in redactionsSearched" :key="redaction.id">
        <div
          class="flex justify-start cursor-pointer text-gray-700 hover:text-blue-800 hover:bg-aktio-darkblue-200 rounded px-2 py-2"
        >{{ redaction.redacted_text }}</div>
        </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
// import breakpoints from "@/breakpoints.js";
// import DocStatus from "@/components/controls/DocStatus.vue";
import ChevronDown from "@/components/icons/chevronDown.vue";
import ChevronRight from "@/components/icons/chevronRight.vue";
import LawParagraph from "@/components/controls/LawParagraph.vue";
import LawSelection from "@/components/controls/LawSelection.vue";
import StandardButton from "@/components/controls/StandardButton.vue";

export default {
  name: "RedactionsOverview",
  components: {
    // "redaction-doc-status": DocStatus,
    "redaction-law-paragraph": LawParagraph,
    ChevronDown,
    ChevronRight,
    StandardButton
  },
  props: {
    searchResultFiles: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // breakpoints,
      searchRedactions: "",
      redactionExpanded: false,
      redactionHovering: false,
      smallScreenExpandRedaction: false,
      thumbnailPreviewId: "",
    };
  },
  computed: {
    ...mapState([
      "redactions",
      "pageSearchResultsGroupedByDocument",
      "searchPDFResults",
      "openFile",
    ]),
    redactionsFiltered() {
      if (this.$route.params.documentId) {
        return this.redactions.filter(
          (r) => r.document.document_id === this.$route.params.documentId
        );
      } else {
        return this.redactions;
      }
    },
    // redactionsSearched() {
    //   const search = this.searchRedactions.toLowerCase().trim();
    //   if (!search) {
    //     return this.redactionsFiltered;
    //   }
    //   return this.redactionsFiltered.filter(
    //     (r) => r.redacted_text.toLowerCase().indexOf(search) > -1
    //   );
    // },
  },
  watch: {
    "$route.params.redactionId"(redactionId) {
      this.expandRedaction(null, redactionId);
    },
    searchRedactions(text) {
      if (text) {
        this.smallScreenExpandRedaction = true;
      }
    },
  },
  methods: {
    removeRedaction(redaction) {
      const redactionObj = {
        id: redaction.id,
        documentId: redaction.document.document_id,
        processId: this.openFile.process.process_id
      }
      this.$store.dispatch('removeRedaction', redactionObj)
    },
    returnRedactionParagraph(redaction) {
      let paragraph = "";
      if (redaction.law) {
        const law = redaction.law;
        if (law.stk !== "1") {
          paragraph = "§ " + law.paragraph + ", stk. " + law.stk;
        } else {
          paragraph = "§ " + law.paragraph;
        }
      }
      return paragraph;
    },
    async expandRedaction(redaction, redactionId) {
      if (redaction && redaction.id) {
        const fileObj = {
          documentId: redaction.document.document_id,
          processId: this.openFile.process.process_id
        }
        await this.$store.dispatch("loadSpecificFile", fileObj);
        await this.$store.dispatch("setNavigationParams", {
          processId: this.$route.params.processId,
          documentId: redaction.document.document_id,
          pageNumber: redaction.page_number,
          randomNum: Math.random()*100,
        });

        // Set redaction element to scroll to
        this.$store.commit("addRedactionToScrollTo", redaction)

       // Save the id for the thumbnail preview element while we have access to the document id + page number
       this.thumbnailPreviewId = `#nav-preview-${redaction.document.document_id}-${redaction.page_number}`

        this.redactionExpanded =
          this.redactionExpanded === redaction.id ? false : redaction.id;
      } else if (redactionId) {
        // when clicked in loadhighlight component
        this.redactionExpanded =
          this.redactionExpanded === redactionId ? false : redactionId;
      }
    },
    setShowTools(redaction) {
      // disable scrolling in the background by hiding overflow on body
      document.body.classList.add("modal-open");
      this.$modal.show(
        LawSelection,
        { element: redaction },
        {
          classes: 'overflow-visible',
          height: "700px",
          width: "60%",
          clickToClose: true,
        },
        {
          "before-close": this.enableScrollAgain,
        }
      );
    },
    enableScrollAgain() {
      document.body.classList.remove("modal-open");
    },
    firstParagraph(index) {
      return index === 0 ? true : false;
    },
    lastParagraph(index, arrayLength) {
      return index === arrayLength - 1 ? true : false;
    },
    hoverRedaction(redationId) {
      this.redactionHovering = redationId;
    },
    isRedactionExpanded(redacionId) {
      return this.redactionExpanded === redacionId ? true : false;
    },
  },
};
</script>
<style>
.break-words {
  word-break: break-all;
}
</style>