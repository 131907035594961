<template>
  <div class="p-8 flex flex-col">
    <redaction-button-exit
      class="absolute right-0 top-0 m-2"
      @emit-close="closeModal()"
    />
    <h1 class="text-xl text-left pb-3">
      Du er ved at slette en sag
    </h1>
    <p>Sagsid: {{ processGuid }}</p>
    <p class="pb-3">
      Sagsnavn: {{ processName }}
    </p>
    <p class="pb-3">
      Sagen slettes for samtlige brugere i Aktio Indsigt, som kan se den.
    </p>
    <p>Før du sletter sagen, bør du sikre at sagen er afsluttet i Aktio Indsigt, samt at det endelige afgørelsesbrev med aktliste og sagens dokumenter er journaliseret jf. jeres interne regler.</p>
    <p><br></p>
    <p>Vil du fortsat slette sagen?</p>
    <div class="mt-5 items-center">
      <standard-button
        class="h-9 w-28 ml-4"
        button-title="Nej"
        button-type="grey"
        button-text="Nej"
        @click="closeModal()"
      />
      <standard-button
        class="h-9 w-28 ml-4"
        button-title="Ja"
        button-type="delete"
        button-text="Ja"
        @click="deleteProcess()"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import buttonExit from "@/components/controls/buttonExit.vue";
import StandardButton from "@/components/controls/StandardButton.vue";

export default {
  name: "ConfirmCloseCaseForm",
  components: {
    "redaction-button-exit": buttonExit,
    StandardButton,
  },
  props: {
    processId: Number,
    processGuid: String,
    processName: String
  },
  computed: {
    ...mapState([
      "processStatus",
      "openProcess"
    ]),
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async deleteProcess() {
      // Soft delete process
      await this.$store.dispatch("removeSpecificProcess", this.processId)
      // Close modal
      this.closeModal()
    },
  }
};
</script>

<style>

</style>
