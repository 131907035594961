<template>
  <div class="p-8 flex flex-col">
    <redaction-button-exit
      class="absolute right-0 top-0 m-2"
      @emit-close="closeModal"
    />
    <h1 class="text-xl">
      Er du sikker på, at du vil slette dette dokument?
    </h1>
    <p><br></p>
    <p>Du må som udgangspunkt ikke slette dokumenter på en aktindsigtssag, da alle dokumenter bør fremgå af aktlisten.</p>
    <p>Skal dokumentet undtages, må du ikke slette det. Vælg i stedet "Undtag dokument".</p>
    <p><br></p>
    <p>Ønsker du fortsat at slette dokumentet fra sagen?</p>
    <div class="mt-5 items-center">
      <standard-button
        class="h-9 w-28 ml-4"
        button-title="Nej"
        button-type="grey"
        button-text="Nej"
        @click="closeModal()"
      />
      <standard-button
        class="h-9 w-28 ml-4"
        button-title="Ja"
        button-type="delete"
        button-text="Ja"
        @click="deleteDocument()"
      />
    </div>
  </div>
</template>

<script>
import buttonExit from "@/components/controls/buttonExit.vue";
import StandardButton from "@/components/controls/StandardButton.vue";

export default {
  name: "ConfirmDeleteDocumentForm",
  components: {
    "redaction-button-exit": buttonExit,
    StandardButton,
  },
  props: {
      documents: Array,
      processId: String,
      files: Array,
  },
  methods: {
    closeModal() {
      this.$store.dispatch("posthogCapture", {event_name:"CloseDeleteDocumentModal", data:{}});

      this.$emit("close");
    },
    async deleteDocument() {
      this.documents.forEach(async (document) => {
        // Hard delete document and dependencies
        await this.$store.dispatch("removeDocument", document)
      })

      // Close modal
      this.closeModal()
    },
  }
};
</script>