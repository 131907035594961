<template>
  <div>
    <table class="w-full table-fixed shadow-lg bg-white">
      <thead>
        <tr>
          <th
            v-for="header in documentTableHeaders"
            v-show="header.text !== 'Se filer' || splittedFilesPresent"
            :key="header.name"
            class="bg-aktio-grey text-gray-800 border px-4 py-2 h-12"
            :class="[header.classes]"
          >
            <p
              v-if="selectedDocumentGroup && header.grouped"
              class="flex"
            >
              Indeks
            </p>
            <!-- @click event that will pass 'selectedDocuments' array of documents Ids and open them should be added once created in the backend-->
            <p
              v-else
              class="flex"
            >
              <span
                :class="[
                  selectedDocumentGroup === header.name
                    ? 'border-b-4 border-gray-800'
                    : '',
                ]"
              >{{ header.text }}</span>
            </p>
          </th>
        </tr>
      </thead>
      <tbody v-if="isLoading && Object.keys(filesGroupedAndSorted).length === 0">
        <tr>
          <td
            :colspan="documentTableHeaders.length-1"
            class="p-4"
          >
            Indlæser Dokumenter...
            <font-awesome-icon
              icon="fa-spinner"
              class="md:mr-2 md:mb-1 sm:mb-2 animate-spin"
            />
          </td>
        </tr>
      </tbody>
      <!-- RPA errors-->
      <tbody v-else-if="RPAMessages.length > 0">
        <tr
          v-for="message in RPAMessages"
          :key="message.id"
        >
          <td
            :colspan="documentTableHeaders.length-1"
            class="p-4 text-red-600"
          >
            {{ message.message }}
            <standard-button
              class="h-9 ml-4"
              button-title="Fjern"
              button-type="delete"
              button-text="Fjern"
              @click="
                removeRPAmessage(message.id, message.process.process_id)
              "
            />
          </td>
        </tr>
      </tbody>
      <!-- no files-->
      <tbody v-else-if="Object.keys(filesGroupedAndSorted).length === 0">
        <tr>
          <!-- RPA order -->
          <td
            v-if="openProcess.request_process_type === 'rpa'"
            :colspan="documentTableHeaders.length-1"
            class="p-4"
          >
            <div class="flex flex-wrap w-full justify-center content-center">
              <redaction-icon-robot class="h-8 w-8 mr-2" />
              <div class="pt-1">
                <p>Der er afsendt en RPA (robot) bestilling og du modtager en e-mail, når dokumenterne er indlæst i Aktio Indsigt.</p>
                <p><br></p>
                <p>Har du spørgsmål til de udskrevne dokumenter, kontakt da {{ SUPPORT_EMAIL_NAME }} på <strong><a :href="'mailto:automatisering@sonderborg.dk?subject=Aktio Indsigt: RPA forespørgsel på sagsid:' + openProcess.process_id">{{ SUPPORT_EMAIL }}</a></strong>.</p>
                <p><br></p>
                <p>Har du brug for hjælp til Aktio Indsigt, eller ris eller ros, kan du kontakte <strong><a href="mailto:support@aktio.ai">support@aktio.ai</a></strong></p>
                <p><br></p>
                <p>Seneste status fra robotten: <strong>{{ RPAStatus }}</strong>.</p>
              </div>
            </div>
          </td>
          <td
            v-else
            :colspan="documentTableHeaders.length-1"
            class="border p-4"
          >
            Ingen dokumenter - indlæs dokumenter ovenfor.
          </td>
        </tr>
      </tbody>
      <!-- when no grouping has been selected - make rows draggable  -->
      <draggable
        v-if="Object.keys(filesGroupedAndSorted).length > 0"
        v-model="filesGroupings"
        :disabled="openedGroupedFiles.length > 0 || filesStillBeingUploaded"
        tag="tbody"
        handle=".draggable-column"
      >
        <template
          v-for="(groupedFiles, originalFileId, index) in filesGroupedAndSorted"
          :key="originalFileId"
        >
          <tr
            :id="originalFileId" 
          >
            <!-- fold out grouped files column -->
            <td
              v-if="groupedFiles.length > 1 && splittedFilesPresent"
              class="border w-12 px-4 py-4 cursor-pointer"
              @click="setOpenedGroupedFiles(originalFileId)"
            >
              <div class="flex text-center justify-center">
                <font-awesome-icon
                  :icon="
                    openedGroupedFiles.includes(originalFileId)
                      ? 'fa-angle-up'
                      : 'fa-angle-down'
                  "
                />
              </div>
            </td>
            <td
              v-else-if="splittedFilesPresent"
              class="border w-12 px-4 py-4"
            />
            <!-- index column -->
            <td
              class="border w-12 px-4 py-4 draggable-column"
              @mouseover="fileHover = index"
              @mouseleave="fileHover = false"
            >
              <div class="flex text-center justify-center">
                <div class="w-5 h-5">
                  <!-- TODO: make sort order more robust -->
                  <redaction-icon-up-down
                    v-if="fileHover === index"
                    class="pt-1"
                  />
                  <span v-else>
                    <span>
                      {{ index + 1 }}
                    </span>
                  </span>
                </div>
              </div>
            </td>
            <td
              class="
              border
              px-4
              py-4
              max-w-md
              break-words
              draggable-column
            "
              @mouseover="fileHover = index"
              @mouseleave="fileHover = false"
            >
              <div class="flex justify-between">
                <div class="flex">
                  <!-- TODO: improve -->
                  <a
                    v-if="
                      blockFiles ||
                        (groupedFiles[0].document_status &&
                          groupedFiles[0].document_status.is_processing) &&
                        (filesGroupedAndSorted[originalFileId][0].original_file != null)
                    "
                    class="cursor-not-allowed text-gray-500"
                  >{{ filesGroupedAndSorted[originalFileId][0].display_name ? filesGroupedAndSorted[originalFileId][0].display_name : 'Indlæser...' }}</a>
                  <a
                    v-else-if="filesGroupedAndSorted[originalFileId][0].original_file != null"
                    class="cursor-pointer"
                    @click="
                      goToDocument(
                        groupedFiles[0].document_id,
                        groupedFiles[0].process.process_id
                      )
                    "
                  >
                    {{ filesGroupedAndSorted[originalFileId][0].display_name ? filesGroupedAndSorted[originalFileId][0].display_name : 'Indlæser...' }}
                  </a>
                  <a
                    v-else-if="
                      blockFiles ||
                        (groupedFiles[0].document_status &&
                          groupedFiles[0].document_status.is_processing)
                    "
                    class="cursor-not-allowed text-gray-500"
                  >
                    {{ filesGroupedAndSorted[originalFileId][0].display_name ? filesGroupedAndSorted[originalFileId][0].display_name : 'Indlæser...' }}
                  </a>
                  <a
                    v-else
                    class="cursor-pointer"
                    @click="
                      goToDocument(
                        groupedFiles[0].document_id,
                        groupedFiles[0].process.process_id
                      )
                    "
                  >
                    {{ filesGroupedAndSorted[originalFileId][0].display_name ? filesGroupedAndSorted[originalFileId][0].display_name : 'Indlæser...' }}
                  </a>
                </div>
              </div>
              <div class="text-orange-500 text-sm font-semi bold" v-if="groupedFiles && groupedFiles[0] && duplicateHashes[groupedFiles[0].id]">
                Denne fil har dupletter: {{ getDuplicateIndexes(groupedFiles[0].id).join(', ') }}
              </div>
            </td>
            <td
              v-if="openProcess.request_process_type === 'rpa'"
              class="border px-4 py-4"
            >
              {{ returnEffortInfo(groupedFiles[0]) }}
            </td>
            <!-- <td class="border px-4 py-4">{{ returnDate(row.timestamp_added) }} @TOOD - GET THE REAL DATES FROM DOC PROPERTIES LATER FOR UPLOADS</td> -->
            <td class="border px-4 py-4">
              {{ returnDocumentDate(groupedFiles[0]) }}
            </td>
            <td class="border px-4 py-4 text-center">
              <redaction-doc-status
                v-if="groupedFiles[0].document_status && groupedFiles.length === 1"
                :disabled="groupedFiles[0].document_status.is_processing"
                :document-status="groupedFiles[0].document_status"
                :document="groupedFiles[0]"
                :document-id="groupedFiles[0].id"
                :process-id="groupedFiles[0].process.process_id"
                :select-options="documentStatus"
              />
              <div
                v-else-if="groupedFiles.length > 1 && !openedGroupedFiles.includes(originalFileId)"
                class="rounded text-xs m-auto text-white px-2 py-1 whitespace-no-wrap bg-aktio-darkblue-900 cursor-pointer"
                @click="setOpenedGroupedFiles(originalFileId)"
              >
                {{ groupedFiles.length }} filer
              </div>
              <div
                v-else-if="groupedFiles.length > 1 && openedGroupedFiles.includes(originalFileId)"
                class="rounded text-xs m-auto text-white px-2 py-1 whitespace-no-wrap bg-aktio-darkblue-600 cursor-pointer"
                @click="setOpenedGroupedFiles(originalFileId)"
              >
                {{ groupedFiles.length }} filer
              </div>
            </td>
            <td class="border px-4 py-4">
              <standard-button
                v-if="checkReReadButton(groupedFiles[0])"
                class="h-9 ml-4 m-px"
                button-title="Genindlæs"
                button-text="Genindlæs"
                button-text-small
                button-type="standard-disableable"
                @click="reindexDocument(groupedFiles[0])"
              />
              <standard-button
                v-if="
                  blockFiles ||
                    (groupedFiles[0].document_status &&
                      groupedFiles[0].document_status.is_processing)
                "
                class="h-9 ml-4 m-px"
                button-title="Søg og overstreg"
                button-icon-fa="fa-magnifying-glass"
                button-text="Søg og overstreg"
                button-text-small
                button-type="standard-disableable"
                button-disabled
              />
              <standard-button
                v-else
                class="h-9 ml-4 m-px"
                button-title="Søg og overstreg"
                button-icon-fa="fa-magnifying-glass"
                button-text="Søg og overstreg"
                button-text-small
                @click="
                  goToDocument(
                    groupedFiles[0].document_id,
                    groupedFiles[0].process.process_id
                  )
                "
              />
              <standard-button
                v-if="
                  blockFiles ||
                    (groupedFiles[0].document &&
                      groupedFiles[0].document_status &&
                      groupedFiles[0].document_status.is_processing) ||
                    (groupedFiles[0].document_status &&
                      groupedFiles[0].document_status.is_processing &&
                      originalFilesNumber[originalFileId] > groupedFiles.length)
                "
                class="h-9 ml-4 m-px"
                button-title="Undtag dokument"
                button-type=""
                :button-icon="getButtonIcon()"
                :button-text="buttonExcemptText(groupedFiles[0])"
                button-text-small
                button-disabled
              />
              <standard-button
                v-else
                class="h-9 ml-4 m-px"
                button-title="Undtag dokument"
                button-type="blue"
                :button-icon="getButtonIcon()"
                :button-text="buttonExcemptText(groupedFiles[0])"
                button-text-small
                @click="showLawSelectionModal(groupedFiles)"
              />
              <standard-button
                v-if="originalFilesNumber[originalFileId] > groupedFiles.length"
                class="h-9 ml-4 mr-2"
                button-title="Slet dokument"
                button-type=""
                button-icon-fa="fa-trash-can"
                button-text-small
                button-disabled
              />
              <standard-button
                v-else
                class="h-9 ml-4 mr-2"
                button-title="Slet dokument"
                button-type="delete"
                button-icon-fa="fa-trash-can"
                button-text-small
                @click="showConfirmDeleteDocumentModal(groupedFiles)"
              />
            </td>
          </tr>
          <tr
            v-for="(row, rowIndex) in groupedFiles"
            v-show="checkIfOpenedGroupedFilesHasFileId(openedGroupedFiles, originalFileId, row, rowIndex)"
            :id="row.display_name"
            :key="row.document_id"
            class="border-l border-blue-900"
          >
            <td />
            <!-- index column -->
            <td class="border w-12 px-4 py-4">
              <div class="flex text-center justify-center">
                <div class="w-5 h-5">
                  <span>{{ `${index + 1}.${rowIndex + 1}` }}</span>
                </div>
              </div>
            </td>

            <td class="border px-4 py-4 max-w-md break-words">
              <div class="flex justify-between">
                <div class="flex">
                  <!-- TODO: improve -->
                  <a
                    v-if="
                      blockFiles ||
                        (row.document_status &&
                          row.document_status.is_processing)
                    "
                    class="cursor-not-allowed text-gray-500"
                  >{{ row.display_name ? row.display_name : 'Indlæser...' }}</a>
                  <a
                    v-else
                    class="cursor-pointer"
                    @click="
                      goToDocument(row.document_id, row.process.process_id)
                    "
                  >
                    {{ row.display_name ? row.display_name : 'Indlæser...' }}
                  </a>
                </div>
              </div>
            </td>
            <td
              v-if="openProcess.request_process_type === 'rpa'"
              class="border px-4 py-4"
            >
              {{ returnEffortInfo(row) }}
            </td>
            <!-- <td class="border px-4 py-4">{{ returnDate(row.timestamp_added) }} @TOOD - GET THE REAL DATES FROM DOC PROPERTIES LATER FOR UPLOADS</td> -->
            <td class="border px-4 py-4">
              {{ returnDocumentDate(row) }}
            </td>
            <td class="border px-4 py-4 text-center">
              <redaction-doc-status
                v-if="row.document_status"
                :disabled="row.document_status.is_processing"
                :document-status="row.document_status"
                :document="row"
                :document-id="row.id"
                :process-id="row.process.process_id"
                :select-options="documentStatus"
              />
            </td>
            <td class="border px-4 py-4">
              <standard-button
                v-if="checkReReadButton(row)"
                class="h-9 ml-4 m-px"
                button-title="Genindlæs"
                button-text="Genindlæs"
                button-text-small
                button-type="standard-disableable"
                @click="reindexDocument(row)"
              />
              <standard-button
                v-if="
                  blockFiles ||
                    (row.document_status && row.document_status.is_processing)
                "
                class="h-9 ml-4 m-px"
                button-title="Søg og overstreg"
                button-icon-fa="fa-magnifying-glass"
                button-text="Søg og overstreg"
                button-text-small
                button-type="standard-disableable"
                button-disabled
              />
              <standard-button
                v-else
                class="h-9 ml-4 m-px"
                button-title="Søg og overstreg"
                button-icon-fa="fa-magnifying-glass"
                button-text="Søg og overstreg"
                button-text-small
                @click="
                  goToDocument(row.document_id, row.process.process_id)
                "
              />
            </td>
          </tr>
        </template>
      </draggable>
      <template
        v-if="
          selectedDocumentGroup &&
            filesGroupedAndSorted &&
            Object.keys(filesGroupedAndSorted).length > 0
        "
      >
        <tbody
          v-for="groupType in documentGroupTypes"
          id="groupViewTable"
          :key="groupType.name"
        >
          <tr
            v-if="filteredDoc(groupType.name).length > 0"
            class="h-12"
            @mouseover="headerHover = groupType.name"
            @mouseleave="headerHover = false"
          >
            <td
              v-if="groupType.display_name_plural"
              :colspan="documentTableHeaders.length-1"
              class="cursor-pointer border py-2 px-4 text-gray-800 bg-gray-200"
            >
              <div class="flex items-center">
                {{ groupType.display_name }}
                <div v-if="!blockFiles">
                  <a
                    v-if="group === 'Dokumenttype'"
                    class="
                      ml-3
                      text-white text-sm
                      cursor-pointer
                      bg-aktio-darkblue-600
                      hover:bg-aktio-darkblue-700
                      px-2
                      py-1
                      rounded
                    "
                    @click="goToSpecificDocuments('groupType.name')"
                  >
                    Åben alle
                    {{ groupType.display_name_plural.toLowerCase() }} til
                    behandling
                  </a>
                  <a
                    v-else
                    class="
                      ml-3
                      text-white text-sm
                      cursor-pointer
                      bg-aktio-darkblue-600
                      hover:bg-aktio-darkblue-700
                      px-2
                      py-1
                      rounded
                    "
                    @click="goToSpecificDocuments(groupType.name)"
                  >
                    Åben alle {{ groupType.name }} til behandling
                  </a>
                </div>
              </div>
            </td>
          </tr>
          <tr
            v-for="row in filteredDoc(groupType.name)"
            v-show="groupVisible(groupType.name)"
            :key="row.document_id"
            :class="group"
          >
            <!-- checkbox column -->
            <td class="border w-12 px-4 py-4">
              <!-- TODO: make sort order more robust -->
              <span
                v-if="row.sort_order !== null && row.sort_order !== undefined"
              >
                {{ row.sort_order + 1 }}
              </span>
            </td>
            <td class="border px-4 py-4 text-center">
              <redaction-doc-status
                v-if="row.document_status"
                class="max-w-xs"
                :document-status="row.document_status"
                :document="row"
                :document-id="row.id"
                :process-id:="row.process.process_id"
                :select-options="documentStatus"
              />
            </td>
            <!-- document name -->
            <td class="border px-4 py-4 max-w-md break-words">
              <div class="flex justify-between">
                <div class="flex">
                  <a
                    v-if="blockFiles"
                    class="cursor-not-allowed text-gray-500"
                  >{{ row.display_name ? row.display_name : 'Indlæser...' }}</a>
                  <a
                    v-else
                    class="cursor-pointer"
                    @click="goToDocument(row.document_id, row.process.process_id)"
                  >
                    {{ row.display_name ? row.display_name : 'Indlæser...' }}
                  </a>
                </div>
              </div>
            </td>
            <td
              v-if="openProcess.request_process_type === 'rpa'"
              class="border px-4 py-4"
            >
              {{ returnEffortInfo(row) }}
            </td>
            <td class="border px-4 py-4">
              {{ returnDocumentDate(row) }}
            </td>
            <td class="border px-4 py-4">
              <standard-button
                v-if="checkReReadButton(row)"
                class="h-9 ml-4 m-px"
                button-title="Genindlæs"
                button-text="Genindlæs"
                button-text-small
                button-type="standard-disableable"
                @click="reindexDocument(row)"
              />
              <standard-button
                v-if="blockFiles || (row.document_status && row.document_status.is_processing)"
                class="h-9 ml-4 m-px"
                button-title="Søg og overstreg"
                button-icon-fa="fa-magnifying-glass"
                button-text="Søg og overstreg"
                button-text-small
                button-type="standard-disableable"
                button-disabled
              />
              <standard-button
                v-else
                class="h-9 mt-4 ml-4 m-px"
                button-title="Søg og overstreg"
                button-icon-fa="fa-magnifying-glass"
                button-text="Søg og overstreg"
                button-text-small
                @click="goToDocument(row.document_id, row.process.process_id)"
              />
            </td>
          </tr>
        </tbody>
      </template>
    </table>
  </div>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";
import iconUpDown from "@/components/icons/upDown.vue";
import { VueDraggableNext } from 'vue-draggable-next'
import dayjs from "dayjs";
import DocStatus from "@/components/controls/DocStatus.vue";
import StandardButton from "@/components/controls/StandardButton.vue";
import iconRobot from "@/components/icons/robot.vue";
import LawSelection from "@/components/controls/LawSelection.vue";
import ConfirmDeleteDocumentForm from "@/components/forms/ConfirmDeleteDocumentForm.vue";
import FEATURE_FLAGS from "@/constants/featureflags";
import isValidDocument from "@/utils/isValidDocument";
import documentStatuses from "@/constants/documentStatuses";

export default {
  name: "DocumentTable",
  components: {
    draggable: VueDraggableNext,
    "redaction-icon-up-down": iconUpDown,
    "redaction-doc-status": DocStatus,
    "redaction-icon-robot": iconRobot,
    StandardButton,
  },
  props: {
    blockFiles: Boolean,
  },
  data() {
    return {
      hidden: true,
      group: "",
      hiddenGroups: [],
      openedOptions: "",
      dropdownOpened: false,
      // groupTypes: [],
      selectedDocuments: [],
      selectedGroupTypes: [],
      fileHover: false,
      headerHover: false,
      RPAStatusPolling: null,
      SUPPORT_EMAIL: process.env.VUE_APP_RPA_SUPPORT_EMAIL,
      SUPPORT_EMAIL_NAME: process.env.VUE_APP_RPA_SUPPORT_EMAIL_NAME,
      cancelAllPolling: true,
      reloadFiles: false,
      openedGroupedFiles: [],
      originalFilesNumber: {},
      filesGroupedAndSorted: {},
      filesStillBeingUploaded: false,
      previousFiles: [],
      isLoading: true,
      splittedFilesPresent: false,
      duplicateHashes: {},
    };
  },
  computed: {
    ...mapState([
      "files",
      "openFile",
      "openProcess",
      "documentTypes",
      "documentStatus",
      "selectedDocumentGroup",
      "documentGroupTypes",
      "RPAMessages",
      "RPAStatus",
      "effortStatus",
      "processStatus",
      "defaultDocumentStatus",
      "documentExceptStatus",
      "documentReadyStatus",
      "shouldPollDocumentStatus"
    ]),
    documentTableHeaders() {
      if (this.openProcess.request_process_type === "rpa") {
        return [
          { text: "Se filer", options: false, grouped: false, classes: "w-20" },
          { text: "Dok. nr.", options: false, grouped: true, classes: "w-20" },
          { text: "Dokumentnavn", options: false },
          {
            text: "Indsats",
            name: "effort_status",
            options: true,
            classes: "w-60",
          },
          { text: "Dokumentdato", options: false, classes: "w-40" },
          {
            text: "Status",
            name: "document_status",
            options: true,
            classes: "w-36",
          },
          {
            text: 'Handlinger',
            name: "document_actions",
            options: false,
          },
        ];
      } else {
        return [
          { text: "Se filer", options: false, grouped: false, classes: "w-20" },
          { text: "Dok. nr.", options: false, grouped: true, classes: "w-20" },
          { text: "Dokumentnavn", options: false },
          { text: "Dokumentdato", options: false, classes: "w-40" },
          {
            text: "Status",
            name: "document_status",
            options: true,
            classes: "w-36",
          },
          {
            text: 'Handlinger',
            name: "document_actions",
            options: false,
          },
        ];
      }
    },
    filesGroupings: {
      get() {
        return this.getFilesName(this.files);
      },
      set(value) {
        const newOrder = value.filter((el) => el.parent);
        const sortObjs = [];

        // Collect all sortObj data to send in one batch
        newOrder.forEach(({ name }, index) => {
          const files = this.filesGroupedAndSorted[name];

          files.forEach((file) => {
            const currentOrder = file.sort_order;

            // Only add to batch if the sort order has changed
            if (currentOrder !== index) {
              sortObjs.push({
                internalDocumentId: file.id,
                processId: file.process.process_id,
                data: {
                  sort_order: index,
                },
              });
            }
          });
        });

        // Dispatch the batch update action with all sortObjs
        if (sortObjs.length > 0) {
          this.$store.dispatch("batchUpdateFileOrder", sortObjs);
        }

        // Update local data for new sort order
        let newFilesOrder = {};
        newOrder.forEach(({ name }) => {
          newFilesOrder[name] = this.filesGroupedAndSorted[name];
        });
        console.log("1 this.filesGroupedAndSorted", this.filesGroupedAndSorted)
        console.log("2 this.newOrder", newOrder)
        this.filesGroupedAndSorted = newFilesOrder;
      }
    },
  },
  watch: {
    filesGroupedAndSorted(files) {
      console.log("filesGroupedAndSorted (Watch)")
      if (files) {
        // this.isLoading = false;
        this.findDuplicates(); // Call findDuplicates when filesGroupedAndSorted is updated
      }
    },
    openProcess(process) {
      console.log("openProcess (Watch)")
      // Poll for RPA status if process is RPA, no files have been fetched and no polling has been started
      if (
        this.RPAStatusPolling === null &&
        Object.keys(this.filesGroupedAndSorted).length === 0 &&
        process.request_process_type === "rpa"
      ) {
        this.pollRPAStatus();
      }
    },
    async files(files) {
      console.log("files (Watch)")
      if (!_.isEqual(this.previousFiles, files)) {
        await this.groupFiles(files);
      } else {
        this.isLoading = false;
      }
    },
    shouldPollDocumentStatus(shouldPoll) {
      console.log("shouldPollDocumentStatus (Watch)")
      if (!shouldPoll) {
        this.cancelAllPolling = true;
        this.$store.dispatch(
          "loadFilesFromApi",
          this.$route.params.processId
        );
        // this.findDuplicates();
      }
    }
  },
  async mounted() {
    // load process into OpenProcess
    if (this.$route.params.processId) {
      await this.$store.dispatch("loadSpecificProcess", this.$route.params.processId);
      this.$store.dispatch("getRPAMessages", this.$route.params.processId);
    }
    // Poll for RPA status if process is RPA, no files have been fetched and no polling has been started
    if (
      this.RPAStatusPolling === null &&
      Object.keys(this.filesGroupedAndSorted).length === 0 &&
      this.openProcess.request_process_type === "rpa"
    ) {
      this.pollRPAStatus();
    }
  },  
  // clear rpa status polling interval
  beforeUnmount() {
    this.cancelAllPolling = true
    clearInterval(this.RPAStatusPolling);
    this.$store.commit("setDocumentStatusPoll", false)
  },
  methods: {
    getFilesName(files) {
      let fileNamesSorted = [];
      let originalFiles = [];

      files
        .sort((a, b) => a.sort_order - b.sort_order)
        .forEach(({display_name, original_file}) => {
          if (original_file) {
            if (!originalFiles.includes(`ID_${original_file.id}`)) {
              originalFiles.push(`ID_${original_file.id}`);
              fileNamesSorted.push(`ID_${original_file.id}`);
            }
            fileNamesSorted.push(display_name);
          } else {
            fileNamesSorted.push(display_name);
          }
        });

      return fileNamesSorted.map((file, index) => {
        if (originalFiles.includes(file)) {
          return { name: file, order: index, id: index, parent: true };
        }
        return { name: file, order: index, id: index };
      });
    },
    async groupFiles(files) {
      console.log("Starting to group files");
      let filesGroupedAndSorted = {};
      let originalFilesNumber = [];
      files
        .sort((a, b) => a.sort_order - b.sort_order)
        .forEach((file) => {
          let index = `ID_${file.id}`;
          if (file.original_file) {
            index = `ID_${file.original_file.id}`;
          }

          if (filesGroupedAndSorted[index] != undefined) {
            filesGroupedAndSorted[index].push(file);
          } else {
            filesGroupedAndSorted[index] = [file];
            originalFilesNumber[index] = file.original_file_split_number
            if (file.original_file_split_number) {
              this.splittedFilesPresent = true;
            }
          }
        });

      let stillUploading = false;
      Object.keys(filesGroupedAndSorted).forEach(key => {
        if(
          originalFilesNumber[key] &&
          filesGroupedAndSorted[key].length !== originalFilesNumber[key]
        ){
          stillUploading = true;
        }
      })
      this.filesStillBeingUploaded = stillUploading;
      this.filesGroupedAndSorted = filesGroupedAndSorted;
      this.originalFilesNumber = originalFilesNumber;

      this.previousFiles = files;
      this.isLoading = false
      console.log("Done grouping files");
    },
    buttonExcemptText(file) {
      if (file.document_status.name === "Undtaget") {
        return "Fjern undtagelse";
      } else {
        return "Undtag dokument";
      }
    },
    getButtonIcon() {
      if (this.openProcess.law_set && this.openProcess.law_set.name === "INS") {
        return "highlighter";  // Change icon to "highlighter" when the condition is met
      } else {
        return "law";  // Default icon
      }
    },
    pollDocumentStatuses() {
      this.$store.commit("setDocumentStatusPoll", true);
      this.$store.dispatch("loadFilesFromApi", this.$route.params.processId);
      console.log("Reload document status polling\nevery 3 sec | until files are ready");
      this.cancelAllPolling = false
      const pollInterval = 3000; // Specify the interval in milliseconds

      const pollFunction = async () => {
        if (!this.cancelAllPolling) {
          console.log("Reload - Polling documents");
          const docObj = { processId: this.$store.state.openProcess.process_id };
          await this.$store.dispatch("pollDocumentStatuses", docObj);
          if (!this.cancelAllPolling) {
            setTimeout(pollFunction, pollInterval); // Call pollFunction again after the interval
          }
        } else {
          console.log("Reload - Polling canceled");
        }
      };

      // Start the initial polling
      setTimeout(pollFunction, pollInterval);
    },
    checkReReadButton(row) {
    let retVal = false;
    try {
      if (row.document_status.guid) {
          const daysFromTodayToDate = -dayjs(row.document_modified_datetime).diff(dayjs(), "hour", true)
  
          let doc_statuses = documentStatuses.array_ready.concat(documentStatuses.array_excepted);
  
          retVal = !doc_statuses.includes(row.document_status.guid);

          let olderThan12Hours = daysFromTodayToDate > 12  || true // Last modified date should be more than 12 hours old
          retVal = retVal && olderThan12Hours
          if (this.$featureflag(FEATURE_FLAGS.enableReloadDocumentButton)) {
            return true
          } else if (this.$featureflag(FEATURE_FLAGS.enableRetryWorkflowButton)) {
            return retVal
          } else {
            return false
          }
        }
    } catch {
      return false;
    }
    },
    checkValidDocument(document) {
      return isValidDocument(document)
    },
    async showConfirmDeleteDocumentModal(documents) {
      this.$modal.show(ConfirmDeleteDocumentForm, {
        documents,
        processId: documents[0].process.process_id,
        files: this.files,
      });
    },
    removeRPAmessage(messageId, processId) {
      const RPAMessageObj = {
        messageId,
        processId
      }
      this.$store.dispatch("deleteRPAMessage", RPAMessageObj);
    },
    async goToSpecificDocuments(group) {
      // TODO: also in FinishCaseForm.vue
      const groupObj = {
        groupByColumn: this.selectedDocumentGroup,
        groupValue: group,
        processId: this.openFile.process.process_id
      };
      this.randomNum = Math.random()*100;
      this.$store.commit("setGroupedDocumentsFilters", groupObj);
      await this.$store.dispatch("loadFilesGrouped", groupObj);
      this.$router.push({name: "DocumentViewer",
        params: {
          processId: this.filteredDoc(group)[0].process.process_id,
          documentId: this.filteredDoc(group)[0].document_id,
          pageNumber: 1,
          randomNum: this.randomNum
        },
      });
    },

   // this.$router.push({path:'/persons', query:{id: 1, name: "john"}})

    async reindexDocument(row) {

      const documentChangesObj = {
        id: row.id,
        documentId: row.id,
        processId: row.process.process_id,
        data: {
          document_status: {
            id: this.defaultDocumentStatus.id
          },
        }
      }

      await this.$store.dispatch("setDocumentStatus", documentChangesObj)

      const convertQueryObj = {
        file: row.file,
        id: row.id,
        process: row.process,
        document_id: row.document_id,
        display_name: row.display_name,
        sort_order: row.sort_order,
        timestamp_added: row.timestamp_added,
        file_extension: row.file_extension,
        document_status: row.document_status,
        document_type: row.document_type,
        upload_id: row.upload_id,
        document_modified_datetime: row.document_modified_datetime
      }

      await this.$store.dispatch("reReadExistingFile", convertQueryObj)

      // Poll for updates on document statuses. 
      if (this.cancelAllPolling === true) {
        this.pollDocumentStatuses();
      }

      this.$showRegisterToast("Dokumentet bliver nu genindlæst - vent venligst.", "info", 4000, {'ProcessGuid': row.processId, documentId: row.document_id})

    },
    async goToDocument(documentId, processId) {
      if (!Array.isArray(this.files)) {
        // Handle the case where files is not an array
        this.$showRegisterToast("Fejl: Dokumentlisten er ikke tilgængelig.", "error", 4000, {'ProcessGuid': this.processId, documentId: this.documentId});
        return;
      }

      const document = this.files.find(file => file.document_id === documentId);
      if (document && isValidDocument(document)) {
        // Proceed as usual
        this.$store.commit("setGroupedDocumentsFilters", false);
        this.randomNum = Math.random() * 100;
        this.$router.push({ name: "DocumentViewer", params: { processId, documentId, pageNumber: 1, randomNum: this.randomNum } });
      } else {
        this.$showRegisterToast("Dokumentet du ønsker at åbne er endnu ikke indlæst, eller det har en fejlstatus.", "error", 4000, {'ProcessGuid': this.processId, documentId: this.documentId});
      }
    },
    returnEffortInfo(doc) {
      let effortString = doc.effort_name;
      if (doc.effort_number) effortString += ` - ${doc.effort_number}`;
      if (doc.effort_status) effortString += ` (${doc.effort_status})`;
      return effortString;
    },
    returnDocumentDate(doc) {
      if (doc.created_at) {
        const dateStr = doc.created_at;
        const date = new Date(dateStr);
  
        let created_at_timestamp = date.getTime();
  
        const timestamp = doc.doc_timestamp_from_integration || created_at_timestamp; // || doc.document_modified_datetime || doc.updated_at; // @TODO - REMOVE IRRELEVANT DATES
        return this.returnDate(timestamp);
      }
      return this.returnDate(new Date().getTime())
      //return this.returnDateAndTime(timestamp);
    },
    returnDate(dateString) {
      return dayjs(Number(dateString)).format("DD-MM-YYYY");
    },
    returnDateAndTime(dateString) {
      return dayjs(Number(dateString)).format("DD-MM-YYYY HH:mm:ss");
    },
    selectGroup(group) {
      let selectedGroup = [];

      Array.from(document.getElementsByClassName(group)).forEach((element) =>
        selectedGroup.push(element.id)
      );

      if (this.selectedGroupTypes.includes(group)) {
        this.selectedDocuments = this.selectedDocuments.filter(
          (x) => !selectedGroup.includes(x)
        );
        this.selectedGroupTypes.splice(
          this.selectedGroupTypes.indexOf(group),
          1
        );
      } else if (!this.selectedGroupTypes.includes(group)) {
        this.selectedGroupTypes.push(group);
        if (selectedGroup.some((r) => this.selectedDocuments.includes(r))) {
          (this.selectedDocuments = this.selectedDocuments.filter(
            (x) => !selectedGroup.includes(x)
          )),
            this.selectedDocuments.push.apply(
              this.selectedDocuments,
              selectedGroup
            );
        } else
          this.selectedDocuments.push.apply(
            this.selectedDocuments,
            selectedGroup
          );
      }
    },
    setClassname(group) {
      let classname = group;
      if (group == null) {
        classname = "others";
      }
      return classname;
    },
    async groupDocuments(option) {
      if (option === "document_type") {
        if (this.documentTypes && this.documentTypes.length === 0) {
          await this.$store.dispatch("loadDocumentTypes");
        }
      } else if (option === "document_status") {
        if (this.documentStatus && this.documentStatus.length === 0) {
          await this.$store.dispatch("loadDocumentStatus");
        }
      }
      if (option !== this.selectedDocumentGroup) {
        this.$store.commit("setSelectedDocumentGroup", option);
      } else {
        this.$store.commit("setSelectedDocumentGroup", false);
      }
      this.dropdownOpened = false;
    },
    openOptions(option) {
      if (this.dropdownOpened && this.openedOptions == option) {
        this.dropdownOpened = false;
      } else {
        this.openedOptions = option;
        this.dropdownOpened = true;
      }
    },
    filteredDoc(type) {
      let files_filtered_type = [];
      const group = this.selectedDocumentGroup || false;
      if (group) {
        files_filtered_type = this.files.filter((file) => {
          let fileGroupName = file[group];
          if (fileGroupName) {
            if (fileGroupName.name && fileGroupName.name === type) {
              return file;
            } else if (fileGroupName === type) {this.selectedDocumentGroup;
              return file;
            }
          } else if(fileGroupName === null && type === null) {
            return file;
          } else if (type === false) {
            return file;
          }
        });
      }
      return files_filtered_type;
    },
    hideElements(element) {
      if (this.hiddenGroups.includes(element)) {
        this.hiddenGroups.splice(this.hiddenGroups.indexOf(element), 1);
      } else {
        this.hiddenGroups.push(element);
      }
    },
    groupVisible(groupName) {
      let visible;
      if (this.hiddenGroups.includes(groupName)) {
        visible = false;
      } else visible = true;
      return visible;
    },
    // Poll for rpa status every 10 seconds
    pollRPAStatus(){
      // Do initial fetch of status
      this.$store.dispatch("getRPAStatus", this.openProcess.process_id)
      // Setup polling of rpa status
      this.RPAStatusPolling = setInterval(() => {
        this.$store.dispatch("getRPAStatus", this.openProcess.process_id)
      }, 10000);
    },
    async showLawSelectionModal(documents) {
      if (this.openProcess.law_set.name === "INS") {
        let documentStatusId;
        if (documents[0].document_status.name === "Undtaget") {
          documentStatusId = this.documentReadyStatus.id;
        } else {
          documentStatusId = this.documentExceptStatus.id;
        }
        const doc_data = {
          document_status: {
            id: documentStatusId
          }
        }
        if (documents.length > 0) {
          documents.forEach(async (document, index) => {
            const documentChangesObj = {
              id: document.id,
              processId: document.process.process_id,
              documentId: document.document_id,
              data: doc_data
            }
            await this.$store.dispatch("setDocumentStatus", documentChangesObj)
            if(documents.length - 1 === index) {
              const toast_message = 'Undtagelsen er ændret.'
              this.$showRegisterToast(toast_message, "success", 3000, {ProcessGuid: this.$route.params.processId})
            }
          })
        } else {
          const documentChangesObj = {
            id: documents[0].id,
            processId: documents[0].process.process_id,
            documentId: documents[0].document_id,
            data: doc_data
          }
          await this.$store.dispatch("setDocumentStatus", documentChangesObj)
        }
        await this.$store.dispatch("loadFilesFromApi", this.$route.params.processId);
      } else {
        let element = {
          law: documents[0].law,
        };

        try {
          // If there is no law id, remove the law entry such that the proper view
          // view show in the Law selection component
          if (
            documents[0].law === undefined ||
            documents[0].law.id === undefined
          ) {
            element.law = undefined;
          }
        } catch {
          // If the check fails, we also assume that now law is set yet
          element.law = undefined;
        }

        this.$modal.show(
          LawSelection,
          {
            element,
            elementType: "document",
            processId: documents[0].process.process_id,
            documents,
          },
          {
            classes: "overflow-visible",
            height: "700px",
            width: "60%",
            clickToClose: true,
          }
        );
      }
    },
    setOpenedGroupedFiles(fileId) {
      if (this.openedGroupedFiles.includes(fileId)) {
        this.openedGroupedFiles = this.openedGroupedFiles.filter(
          originalFile => originalFile != fileId
        );
      } else {
        this.openedGroupedFiles = [...this.openedGroupedFiles, fileId];
      }
    },
    checkIfOpenedGroupedFilesHasFileId(openedGroupedFiles, originalFileId, row, rowIndex){
      const doesContainFileID = openedGroupedFiles.includes(originalFileId)
      return doesContainFileID
    },
    findDuplicates() {
      const hashMap = {};
      this.duplicateHashes = {};
      let fileIndex = 0;

      // Traverse through each file and track hashes and their indexes
      for (const [key, value] of Object.entries(this.filesGroupedAndSorted)) {
        if (value[0]) { // Ensure value[0] exists
          const file = value[0];
          const hash = file.file_sha_512_hash;
          if (!hash) {
            fileIndex += 1;
            continue;
          }

          if (!hashMap[hash]) {
            hashMap[hash] = [];
          }
          hashMap[hash].push({ id: file.id, index: fileIndex + 1 });

          // If more than one file with the same hash is found, mark duplicates
          if (hashMap[hash].length > 1) {
            hashMap[hash].forEach(f => {
              this.duplicateHashes[f.id] = hashMap[hash]
                .filter(item => item.id !== f.id)
                .map(item => item.index);
            });
          }
          fileIndex += 1;
        }
      }
    },
    getDuplicateIndexes(fileId) {
      return this.duplicateHashes[fileId] || [];
    }
  },
};
</script>

<style>
  .w-36{
    width:8.5rem
  }
  .w-auto{
    width:auto
  }
  .hover-line:hover {
    text-decoration: underline;
  }
</style>