<template>
  <button
    class="cursor-pointer rounded-full bg-gray-600 p-1 h-5 w-5"
    @click="$emit('emit-close', true)"
  >
    <svg
      class="fill-current text-white h-3 w-3 m-auto"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
    >
      <path
        d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
      />
    </svg>
  </button>
</template>

<script>
export default {
  name: "ButtonExit",
};
</script>

<style>
</style>