<template>
  <div class="p-8 flex flex-col">
    <redaction-button-exit
      class="absolute right-0 top-0 m-2"
      @emit-close="closeModal()"
    />
    <h1 class="text-xl text-left pb-3">
      Du er ved nulstille indholdet af afgørelsesbrevet
    </h1>
    <p>Alt nuværende ændringer vil blive fjernet</p>
    <br>
    <p>Vil du fortsætte med at nulstille indholdet af afgørelsesbrevet?</p>
    <div class="mt-5 items-center">
      <standard-button
        class="h-9 w-28 ml-4"
        button-title="Nej"
        button-type="grey"
        button-text="Nej"
        @click="closeModal()"
      />
      <standard-button
        class="h-9 w-28 ml-4"
        button-title="Ja"
        button-type="delete"
        button-text="Ja"
        @click="reset()"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import buttonExit from "@/components/controls/buttonExit.vue";
import StandardButton from "@/components/controls/StandardButton.vue";

export default {
  name: "ConfirmResetDecisionLetterContentForm",
  components: {
    "redaction-button-exit": buttonExit,
    StandardButton,
  },
  props: {
    processId: String,
    decisionLetterId: Number,
  },
  computed: {
    ...mapState([
      "processStatus",
      "openProcess",
      "user"
    ]),
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async reset() {
      await this.$store.dispatch("updateRestorationStatus", {
          processId: this.$route.params.processId,
          status: "RestoreBoth"
      });
      // Reset decision letter content to template
      this.$emit("reset")
      const obj = {
        process: {
          id: this.openProcess.id,
          process_id: this.openProcess.process_id,
        },
        request_user: this.user.userData,
        decision_letter_id: this.decisionLetterId
      }

      this.$store.dispatch("resetDecisionLetterContent", obj)
      // Close modal
      this.closeModal()
    },
  }
};
</script>

<style>

</style>
