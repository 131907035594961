<template>
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs />
    <g
      transform="matrix(0.068101, 0, 0, 0.068101, -4.799325, -5.78932)"
      style=""
    >
      <line
        style="stroke: rgb(0, 0, 0); stroke-linejoin: round; stroke-linecap: round; stroke-width: 29.3681px;"
        x1="246.619"
        y1="115.029"
        x2="246.494"
        y2="226.758"
      />
      <line
        style="stroke: rgb(0, 0, 0); stroke-linejoin: round; stroke-linecap: round; stroke-width: 29.3681px;"
        x1="246.106"
        y1="100.287"
        x2="195.676"
        y2="160.022"
      />
      <line
        style="stroke: rgb(0, 0, 0); stroke-linejoin: round; stroke-linecap: round; stroke-width: 29.3681px;"
        x1="297.689"
        y1="161.29"
        x2="247.26"
        y2="101.555"
      />
    </g>
    <g
      transform="matrix(0.068101, 0, 0, -0.068101, -4.799325, 29.763386)"
      style=""
    >
      <line
        style="stroke: rgb(0, 0, 0); stroke-linejoin: round; stroke-linecap: round; stroke-width: 29.3681px;"
        x1="246.619"
        y1="115.029"
        x2="246.494"
        y2="226.758"
      />
      <line
        style="stroke: rgb(0, 0, 0); stroke-linejoin: round; stroke-linecap: round; stroke-width: 29.3681px;"
        x1="246.106"
        y1="100.287"
        x2="195.676"
        y2="160.022"
      />
      <line
        style="stroke: rgb(0, 0, 0); stroke-linejoin: round; stroke-linecap: round; stroke-width: 29.3681px;"
        x1="297.689"
        y1="161.29"
        x2="247.26"
        y2="101.555"
      />
    </g>
  </svg>
</template>

<script>
export default {
    name: "IconUpDown"
}
</script>

<style>

</style>