<template>
  <div id="login">
    <!-- <NavbarNew /> -->
    <div
      class="min-h-screen flex justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8"
    >
      <div class="max-w-md w-full">
      <!-- <div class="mt-4">
          <a
            href="/strapi/connect/kombit"
            target=""
            class="hover:text-gray-900"
          >
            <div class="mt-6">
              <standard-button
                class="w-full"
                button-type="standard"
                button-text="Log ind med dit kommunale login (Kombit SSO)"
                button-icon="lock"
              />
            </div>
          </a>
        </div> -->
        <div class="mt-6">
          <standard-button
            class="w-full"
            button-type="standard"
            button-text="Log ind med dit kommunale login. (Kombit SSO)"
            button-icon="lock"
            @click.native="testNewKombit"
          />
          <p
            v-if="errorMessage"
            class="text-red-500 m-4"
          >
            {{ errorMessage }}
          </p>
        </div>
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<script>
import { mapState } from "vuex";
import AppFooter from "@/components/Footer.vue";
import StandardButton from "@/components/controls/StandardButton.vue";

export default {
  name: "LoginKombit",
  components: {
    AppFooter,
    StandardButton,
  },
  data() {
    return {
      blockFiles: false,
      shouldOpenFilesSelector: false,
      canCopy: false,
      errorMessage: '',
    };
  },
  computed: {
    ...mapState('sso', [
      "ssoLink"
    ])
  },
  created() {
    this.errorMessage = this.$route.query.message;
  },
  methods: {
    async testNewKombit() {
      console.log("logging in...");
      await this.$store.dispatch("sso/getSSOLink")
      console.log(this.ssoLink)
    }
  }
};
</script>