<template>
  <textarea 
    ref="pageTextArea" 
    v-model="pageText"
    class="text-area"
    @input="resize"
  />
</template>
  
<script>  
  export default {
    name: 'TextEditContainer',
    props: {
      text: String,
      pageNumber: Number,
      saveTextStatus: {
        type: Boolean
      },
      saveTextNormal: {
        type: Boolean
      }
    },
    data() {
      return {
        pageText: "",
        editedText: "",
      }
    },
    watch: {
      saveTextNormal: function () {
        if(this.saveTextNormal){
          this.$emit("update-text", {text: this.pageText});

        }
      }
    },
    created() {
      this.pageText = this.text
    },
    mounted() {
      this.$nextTick(() => {
        this.$refs.pageTextArea.style.height = "auto";
        this.$refs.pageTextArea.style.height = `${this.$refs.pageTextArea.scrollHeight}px`;
    });
    },
    methods: {
      resize(event) {
      event.target.style.height = "auto";
      event.target.style.height = `${event.target.scrollHeight}px`;
    },
      updateText() {
        this.$emit("update-text", {text: this.pageText, pageNumber: this.pageNumber});
      }
    },
  }
</script>
  
<style>
  .text-area {
    width: 100%;
    height: 100%;
    resize: none;
    overflow: hidden;
    padding: 10px 10px 10px 7px;
    line-height: 1.8;
    text-align: left;
  }
</style>