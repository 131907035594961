<template>
  <div>
    <!-- search suggestions-->
    <div
      v-if="pageSearchSuggestions && pageSearchSuggestions.length > 0 && !searchPDFResults"
      class="text-sm pt-4"
    >
      Mente du
      <a
        class="underline text-blue-500 cursor-pointer"
        @click="search(returnPageSuggestionText)"
      >{{ returnPageSuggestionText }}</a>
    </div>
    <div
      v-if="!searchTerm && searchResultsCount === false"
      class="py-4"
    >
      Afventer søgning
    </div>
    <div
      v-else-if="searchResultsCount === 0 && (searchTerm || currentSearchType === 'cpr')"
      class="py-4"
    >
      Ingen resultater fundet
    </div>
    <!-- search results -->
    <redaction-redactions-search
      v-if="searchPDFResults"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
// import RedactionsSearch from "@/components/redaction/RedactionsSearch.vue"; // Old
import RedactionsSearch from "@/components/redaction/RedactionsSearchCheck.vue";

// const _ = require("lodash");
import _ from "lodash";

export default {
  name: "SearchResults",
  components: {
    "redaction-redactions-search": RedactionsSearch,
  },
  props: {
    searchResultFiles: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      results: {},
      hasSearchResultsBeenShortened: {},
    };
  },
  computed: {
    ...mapState([
      "processes",
      "files",
      "searchResultsCount",
      "pageSearchSuggestions",
      "searchPDFResults",
      "searchTerm",
      "currentSearchType"
    ]),
    returnPageSuggestionText() {
      if (
        !this.pageSearchSuggestions ||
        (this.pageSearchSuggestions && this.pageSearchSuggestions.length === 0)
      ) {
        return "";
      } else {
        let suggestionString = this.searchTerm;
        for (
          let index = 0;
          index < this.pageSearchSuggestions.length;
          index++
        ) {
          const suggestion = this.pageSearchSuggestions[index];
          const searchTermToFix = suggestionString.substr(
            suggestion.offset,
            suggestion.length
          );
          suggestionString = suggestionString.replace(
            searchTermToFix,
            suggestion.options[0].text
          );
        }
        return suggestionString;
      }
    },
  },
  methods: {
    shortenedDocResults(result, indexName) {
      const uniqueResults = _.uniqBy(result, "_source.doc_id");
      if (uniqueResults && uniqueResults.length > 3) {
        this.hasSearchResultsBeenShortened[indexName] = true;
        const uniqueResultsSliced = uniqueResults.slice(0, 4);
        return uniqueResultsSliced;
      } else {
        this.hasSearchResultsBeenShortened[indexName] = false;
        return uniqueResults;
      }
    },
    search(searchTerm) {
      const searchObj = {
        "searchTerm": searchTerm,
        "documentId": this.$route.params.documentId,
        "processId": this.$route.params.processId,
      }
      this.$store.dispatch("setSearchTerm", searchObj);
    },
    returnDocumentInfo(documentId, prop) {
      const findDocument = this.files.find((d) => d.document_id === documentId);
      if (findDocument && prop) {
        return findDocument[prop];
      } else {
        return "";
      }
    },
    returnProcessInfo(indexId, prop) {
      let processId = indexId.replace("doc_index-", "");
      processId = processId.replace("page_index-", "");
      const findProcess = this.processes.find(
        (p) => p.process_id === processId
      );
      if (findProcess && prop) {
        return findProcess[prop];
      } else {
        return "";
      }
    },
  },
};
</script>

<style>
</style>