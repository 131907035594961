const getDefaultState = () => ({
    apiUrl: process.env.VUE_APP_SERVER_URL + "/api",
    ssoLink: ""
})

const sso = {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        setSSOLink(state, payload) {
            state.ssoLink = payload;
        }
    },
    actions: {
        async getSSOLink({ dispatch, commit }) {
            let remoteResponse;
            try {
                remoteResponse = await dispatch("loginRequest");
            } catch (error) {
                dispatch("pushError", error, { root: true })
            }
            console.log(remoteResponse)
            commit("setSSOLink", remoteResponse)
        },
        async loginRequest({ state, dispatch }) {
            let response;

            try {
                response = await fetch(state.apiUrl+"/saml/login", {
                    method: "GET",
                    mode: "cors", // no-cors, *cors, same-origin
                    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: "omit", // include, *same-origin, omit
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    redirect: "follow", // manual, *follow, error
                    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                });
            } catch (error) {
                dispatch("pushError", error)
                return error;
            }
            if (response.ok) {
                const data = await response.json();
                window.location.href = data.url;
            }
            return response.json();
        },
    }
}
export default sso;