<template>
  <router-link
    to="/help"
    class="help cursor-pointer block rounded px-4 py-2 bg-gray-200 hover:bg-gray-300 text-aktio-darkblue-900 hover:text-aktio-darkblue-900"
    :class="{ 'nav-button block rounded px-4 py-2': navHelp }"
  >
    Hjælp
  </router-link>
</template>

<script>

export default {
  name: "HelpMe",
  computed: {
    navHelp() {
      return this.$route.name && (this.$route.name.includes("Help"));
    },
  },
};
</script>

<style>
  .help {
    margin-right: 10px;
  }

  .nav-button {
    background-color: rgb(219, 89, 164);
    color: white;
  }
  
  .nav-button:hover {
    background-color: rgb(199, 79, 144);
    color: white;
  }
</style>