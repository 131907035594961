<template>
  <div class="p-8 flex items-left flex-col">
    <redaction-button-exit
      class="absolute right-0 top-0 m-2"
      @emit-close="closeModal()"
    />
    <h1 class="text-xl">
      Er du sikker på, at du vil fjerne adgang?
    </h1>
    
    <div class="flex-end items-right mt-5">
      <standard-button
        class="h-9 w-28 ml-4"
        button-title="Annuller"
        button-type="grey"
        button-text="Annuller"
        @click="closeModal()"
      />
      <standard-button
        class="h-9 w-36 ml-4"
        button-title="Fjern adgang"
        button-type="delete"
        button-text="Fjern adgang"
        @click="deleteUser()"
      />
    </div>
  </div>
</template>
  <script>
import { mapState } from "vuex";
import buttonExit from "@/components/controls/buttonExit.vue";
import StandardButton from "@/components/controls/StandardButton.vue";

export default {
  name: "FormRemoveUser",
  components: {
    "redaction-button-exit": buttonExit,
    StandardButton,
  },
  props: {
    id: Number,
    users: Array
  },
  computed: {
  ...mapState(["user"]),
 },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async deleteUser(){
      const users = this.users.filter(user => user.id !== this.user.userData.id)
      const object = {
        id: this.id,
        users
      }
      const removedUserFromProcess = await this.$store.dispatch("deleteProcessUser", object)
      if(removedUserFromProcess){
        const toast_message = 'Adgangen til sagen er blevet fjernet.';
        this.$showRegisterToast(toast_message, "success", 4000, {})
      } else {
        const toast_message = 'Adgangen til sagen kunne ikke fjernes. Prøv venligst igen.';
        this.$showRegisterToast(toast_message, "error", 4000, {})
      }
      this.$emit("close");
    }
  }
}
  </script>