<template>
  <svg
    viewBox="0 0 24 24"
    class="fill-current stroke-current"
  >
    <path
      d="M 20.697 14.588 L 16.677 12 L 20.697 9.413 C 21.131 9.134 21.256 8.554 20.977 8.12 L 19.25 5.436 C 19.077 5.167 18.782 5.007 18.462 5.007 C 18.283 5.007 18.108 5.058 17.957 5.155 L 14.531 7.36 L 14.531 3.286 C 14.531 2.771 14.112 2.351 13.596 2.351 L 10.403 2.351 C 9.888 2.351 9.468 2.771 9.468 3.286 L 9.468 7.36 L 6.043 5.155 C 5.891 5.058 5.716 5.006 5.537 5.006 C 5.217 5.006 4.923 5.167 4.75 5.436 L 3.022 8.12 C 2.743 8.554 2.868 9.134 3.302 9.413 L 7.322 12 L 3.302 14.588 C 2.868 14.867 2.743 15.447 3.022 15.88 L 4.75 18.565 C 4.923 18.833 5.217 18.994 5.537 18.994 C 5.716 18.994 5.891 18.942 6.042 18.845 L 9.468 16.64 L 9.468 20.714 C 9.468 21.23 9.888 21.649 10.403 21.649 L 13.596 21.649 C 14.111 21.649 14.531 21.23 14.531 20.714 L 14.531 16.64 L 17.956 18.845 C 18.108 18.942 18.283 18.994 18.462 18.994 C 18.462 18.994 18.462 18.994 18.462 18.994 C 18.782 18.994 19.076 18.833 19.249 18.565 L 20.977 15.88 C 21.256 15.447 21.131 14.867 20.697 14.588 Z"
      style=""
    />
  </svg>
</template>

<script>
export default {
  name: "IconAsterisk",
};
</script>

<style>
</style>