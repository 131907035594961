<template>
  <div
    class="w-full bg-gray-200 h-2 relative overflow-hidden"
    :class="[{ 'rounded-full': rounded }, { indeterminate: indeterminate }]"
  >
    <div
      class="h-full progressbar"
      :class="[
        returnColorBasedOnFinishedDocs,
        { 'absolute top-0': indeterminate },
        { 'rounded-full': rounded },
      ]"
      role="progressbar"
      :style="{ width: `${returnDaysleftInPercentage}%` }"
      :aria-valuenow="returnDaysleftInPercentage"
      aria-valuemin="0"
      aria-valuemax="100"
    >
      <span class="flex items-center h-full">
        <slot />
      </span>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import dayjs from "dayjs";
export default {
  inheritAttrs: false,
  props: {
    dateFrom: {
      type: String,
      default: "",
      required: true,
    },
    dateTo: {
      type: String,
      default: "",
      required: true,
    },
    documents: {
      type: Array,
      default: () => [],
      required: false,
    },
    documentCount: {
      type: Number,
      default: () => 0,
      required: true,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    indeterminate: Boolean,
    processStatus: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState(["documentFinishStatus", "documentExceptStatus"]),
    returnColorBasedOnFinishedDocs() {
    const processStatus = this.processStatus || {};
      if (processStatus.is_finished) {
        return "bg-green-500";
      } else {
        // TODO: consider moving to a counter in Strapi
        let counter = 0;
        /*
        for (let index = 0; index < this.documentCount; index++) {
          const doc = this.documents[index];
          if (
            doc.document_status === this.documentFinishStatus.id ||
            doc.document_status === this.documentExceptStatus.id
          ) {
            counter += 1;
          }
        }
        */
        if (this.documentCount) {
          if (this.documentCount > 0) {
            const donePercentage = (counter / this.documentCount) * 100;
            if (
              this.returnDaysleftInPercentage >= 100 &&
              donePercentage < 100
            ) {
              return "bg-red-400";
            } else if (donePercentage > this.returnDaysleftInPercentage) {
              return "bg-green-500";
            } else {
              return "bg-yellow-400";
            }
          } else {
            return "bg-gray-500";
          }
        } else {
          return "bg-gray-500";
        }
      }
    },
    returnDaysleftInPercentage() {
      const daysBetween = dayjs(this.dateTo).diff(this.dateFrom, "day", true);
      const daysFromTodayToDate = dayjs(this.dateTo).diff(dayjs(), "day", true);
      const daysLeftPercentage =
        100 - (daysFromTodayToDate / daysBetween) * 100;
      return daysLeftPercentage;
    },
  },
};
</script>
<style scoped>
@keyframes progress-indeterminate {
  0% {
    width: 30%;
    left: -40%;
  }
  60% {
    left: 100%;
    width: 100%;
  }
  to {
    left: 100%;
    width: 0;
  }
}
.progressbar {
  transition: width 0.25s ease;
}
.indeterminate .progressbar {
  animation: progress-indeterminate 1.4s ease infinite;
}
</style>