import router from '../router.js';

const getDefaultState = () => ({
  isAuthenticated: false,
  userData: '',
  loginError: false
})

const user = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    authenticate(state, isLoggedIn) {
      state.isAuthenticated = isLoggedIn;
    },
    setUser(state, userData) {
      state.userData = userData;
    },
    setLoginError(state, loginError) {
      state.loginError = loginError;
    },
    resetState(state) {
      Object.assign(state, getDefaultState())
    }
  },
  actions: {
    async authRequest({ commit }, authObj) {
      let loginResponse, loginData;
      let requestObj = {
        method: authObj.method,
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "omit", // include, *same-origin, omit
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      }
      if (authObj.userObject) {
        requestObj["body"] = JSON.stringify(authObj.userObject); // body data type must match "Content-Type" header
      }
      if(authObj.userToken) {
        requestObj["headers"]["Authorization"] = `Bearer ${authObj.userToken}`
      }
      try {
        loginResponse = await fetch(authObj.authUrl, requestObj);
      } catch (error) {
        commit("setLoginError", true);
        commit("authenticate", false);

        localStorage.removeItem("aktio-ai-user-token");
      }
      loginData = await loginResponse.json();
      // console.log('user.js');
      // console.log(loginData);

      if (loginData.jwt != undefined) {
        try {
          // Do nothing
        } catch (error) {
          console.log(error);
        }
        commit("authenticate", true);
        commit("setLoginError", false);
        localStorage.setItem("aktio-ai-user-token", loginData.jwt);
        await router.push("/processes");
      } else {
        localStorage.removeItem("aktio-ai-user-token");

        commit("setLoginError", true);
        commit("authenticate", false);
      }
      return loginData;
    },
    async loginWithAccessToken({ rootState, dispatch }, access_token) {
      const authUrl = rootState.strapiUrl + "/auth/keycloak/callback?access_token=" + access_token; // @TODO - Figure out this
      await dispatch("authRequest", {
        method: "GET",
        authUrl: authUrl,
      });
    },
    async loginWithUserAndPassword({ rootState, dispatch }, userObject) {
      const authUrl = rootState.strapiUrl + "/auth/local";
      await dispatch("authRequest", {
        method: "POST",
        authUrl: authUrl,
        userObject: userObject
      });
    },
    async getUser({ rootState }) {
      //TODO: consolidate with above
      const authUrl = rootState.strapiUrl + "/users/me";
      //const last_loginUrl = rootState.apiUrl + "/user/last_login/";
      let response;
      if (localStorage.getItem("aktio-ai-user-token") != null || undefined) {
        try {
          response = await fetch(authUrl, {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "omit", // include, *same-origin, omit
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("aktio-ai-user-token")}`,
            },
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          })
          // if token is not correct redirect to login page
          if (!response.ok) {
            localStorage.removeItem("aktio-ai-user-token");
          }
        }
        catch (error) {
          console.log(error);
          localStorage.removeItem("aktio-ai-user-token");
          await router.push("/login")
        }
        return response.json();
      }
    }
  }
}

export default user;