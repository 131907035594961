<template>
  <div class="videoContainer py-5 grid grid-cols-3 gap-8 w-4/5">
    <div
      v-for="(video,index) in listdata"
      :key="index"
      class="videoItem bg-aktio-darkblue-100 cursor-pointer hover:bg-aktio-darkblue-200"
      @click="$emit('clickedItem', video)"
    >
      <h2 class="px-2 py-3">
        {{ video.title }}
      </h2>
      <img
        class="px-2 py-2"
        :src="video.thumbnail"
        :alt="video.description"
      >
    </div>
  </div>
</template>

<script>

export default {
  props: {
    listdata: {
      type: Array,
      required: true
    },
  },
}

</script>
