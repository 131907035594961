<template>
  <!-- If you change the tree hierarchy, also update the 'shouldOpenFilesSelector' function to match it -->
  <section class="upload-field font-semibold">
    <div
      ref="fileUploadElement"
      class="uppy-c-icon bg-aktio-darkblue-900 rounded text-white font-semibold hover:bg-aktio-darkblue-700 w-full mb-2 pb-2 justify-center border-gray-500 h-12"
    >
      <div class="for-ProgressBar" />
      <div
        v-show="!uploadId"
        class="for-DragDrop w-full inline-flex justify-center pr-full pl-full mb-2 h-12"
      />
      <div
        v-show="uploadId && !splittingFiles"
        class="upload-progress w-full pr-full pl-full font-semibold inline-flex justify-center pt-2 mb-2 h-12 "
      >
        <redaction-icon-spinner class="text-black" />
        {{ uploadedFilesNo }} ud af {{ noFilesToUpload }} filer overført
      </div>
      <div
        v-show="uploadId && splittingFiles"
        class="upload-progress w-full pr-full pl-full font-semibold inline-flex justify-center pt-2 mb-2 h-12 "
      >
        <redaction-icon-spinner class="text-black" />
        Splitter store filer
      </div>
      <!--    <div class="UppyInput-Progress"></div>-->
    </div>
    <!--<div class="for-ProgressBar w-full h-2"></div>-->
  </section>
</template>

<script>
import { mapState } from "vuex"; 
import { v4 as uuidv4 } from "uuid";
import iconSpinner from "@/components/icons/spinner.vue";
import Uppy from '@uppy/core'
import DragDrop from '@uppy/drag-drop'
import ProgressBar from '@uppy/progress-bar'
import XHRUpload from '@uppy/xhr-upload'
 
// import '@uppy/core/dist/style.css'
// import '@uppy/drag-drop/dist/style.css'
import '@uppy/progress-bar/dist/style.css'

export default {
  name: "FileUploadBox",
  components: { 
    "redaction-icon-spinner": iconSpinner,
  },
  props: {
    shouldOpenFilesSelector: Boolean
  },
  data() {
    return {
      uppyDragDrop: false,
      noFilesToUpload: false,
      uploadId: false,
      currentlyPollingUpload: false,
      unAllowedFileList: [],
      fileSize: [],
      splittingFiles: false,
      cancelAllPolling: false,
      cancelUploadPolling: false,
      normalPolling: false,
    };
  },
  computed: {
    ...mapState(["files", "uploadedFilesNo", "allowedFileTypes", "shouldPollDocumentStatus"]),
  },
  watch: {
    unAllowedFileList(unallowedFiles) {
      if(unallowedFiles && unallowedFiles.length > 0) {
        const toast_message = "Filerne:\n\n" + unallowedFiles.join("\n") + "\n\nkan ikke overføres"
        this.$showRegisterToast(toast_message, "warning", 4000, {ProcessGuid: this.processId}) //@TODO Consider regular toast here or add parameter to remove certain data
      }
    },
    uploadedFilesNo(noOfFiles) {
      // when the number of files succesfully processed equals the number of files send to upload stop the polling
      if(noOfFiles === this.noFilesToUpload) {
        // this.uppyDragDrop.cancelAll();
        // this.uppyDragDrop.resetProgress();
        this.uppyDragDrop.getState().info = []
        this.$emit('file-upload-block', false)
        const docObj = {processId: this.$store.state.openProcess.process_id}
        this.$store.dispatch("pollDocumentStatuses", docObj)
        this.splittingFiles = false;
        this.fileSize = []
      }
      else if(noOfFiles > this.noFilesToUpload && this.noFilesToUpload !== false) {
        this.splittingFiles = true
      }
    },
    shouldPollDocumentStatus(shouldPoll) {
      if(!shouldPoll) {
        this.cancelAllPolling = true;
        this.$store.dispatch(
          "loadFilesFromApi",
          this.$route.params.processId
        );
      }
    },
    shouldOpenFilesSelector(should) {
      if(should) {
        const uploadButton = this.$refs.fileUploadElement.children[0].children[0].children[0]
        uploadButton.click()
      }
    }
  },
  created() {
    this.pollDocumentStatusesInitial()
  },
  async mounted() {
    this.$store.commit("setUploadedFilesNo", 0);
    this.$emit('file-upload-block', false);

    if (!this.uppyDragDrop) {
      this.uppyDragDrop = new Uppy({
        debug: true,
        autoProceed: true,
        onBeforeFileAdded: (currentFile) => {
          try {
            currentFile.id = uuidv4();
            this.fileSize.push(currentFile.size);
            const currentFileName = currentFile.name || false;
            if (currentFileName) {
              const currentExt = currentFileName.substr(currentFileName.lastIndexOf('.'));
              const isAllowed = this.allowedFileTypes.some((e) => e === currentExt);
              if (!isAllowed) {
                this.unAllowedFileList.push(currentFileName);
              }
              this.$store.dispatch("posthogCapture", {
                event_name: "DocumentUpload",
                data: { allowed_file_extension: false, extension: currentExt },
              });
            }
          } catch (e) {
            const toastMessage = "Der gik noget galt med upload fra din computer, prøv igen";
            this.$showRegisterToast(toastMessage, "error", 7000, { ProcessGuid: this.processId });
            this.$emit('file-upload-block', false);
            this.uppyDragDrop.cancelAll();
            console.log("onBeforeFileAdded", e);
          }
        },
        restrictions: {
          allowedFileTypes: this.allowedFileTypes,
        },
        width: "100%",
      });

      await this.uppyDragDrop
        .use(DragDrop, {
          target: '.upload-field .for-DragDrop',
          width: "100%",
          locale: {
            strings: {
              dropHereOr: "%{browse} eller træk dokumenter ind her - Vedhæftede filer i e-mails udpakkes automatisk.",
              browse: "Overfør Dokumenter - Klik",
            },
          },
        })
        .use(ProgressBar, { target: '.upload-field .for-ProgressBar', hideAfterFinish: false })
        .on("upload", (uploadData) => {
          this.splittingFiles = false;
          this.$store.commit("setUploadedFilesNo", 0);
          const fileIds = uploadData.fileIDs || [];
          const noOfFiles = fileIds.length || 0;
          this.noFilesToUpload = noOfFiles;

          this.$emit('file-upload-block', true);
          let totalSize = 0;
          this.fileSize.map((size) => {
            totalSize += (size * Math.pow(10, -6));
          });
          totalSize = (Math.round(totalSize * 100) / 100).toFixed(2);
          let toastMessage;
          if (totalSize > 0.00 && totalSize <= 0.20) {
            toastMessage = `Upload på ${totalSize} mb behandles nu`;
          } else if (totalSize > 0.40 && totalSize <= 1.00) {
            toastMessage = `Upload på ${totalSize} mb behandles nu`;
          } else if (totalSize > 1.00 && totalSize <= 5.00) {
            toastMessage = `Upload på ${totalSize} mb behandles nu`;
          } else if (totalSize > 5.00 && totalSize <= 10.00) {
            toastMessage = `Upload på ${totalSize} mb behandles nu`;
          } else if (totalSize > 10.00 && totalSize <= 20.00) {
            toastMessage = `Du har startet et større upload på ${totalSize} mb<br/><br/>Dokumenterne behandles nu`;
          } else if (totalSize > 20.00 && totalSize <= 50.00) {
            toastMessage = `Du har startet et stort upload på ${totalSize} mb<br/><br/>Dokumenterne behandles nu`;
          } else if (totalSize > 50.00) {
            toastMessage = `Du har startet et meget stort upload på ${totalSize} mb<br/><br/>Dokumenterne behandles nu`;
          }
          this.$showRegisterToast(toastMessage, "info", 5000, { ProcessGuid: this.processId });
          this.pollFileUpload();
        })
        .on("file-added", (file) => {
          try {
            this.isUploading = true;
            if (!this.uploadId) {
              this.uploadId = uuidv4();
            }
            this.$store.dispatch("processFiles", {
              file: file.data,
              extension: file.extension,
              sort_order: this.$store.state.filesCount,
              upload: true,
              processId: this.$store.state.openProcess.process_id,
              internalProcessId: this.$store.state.openProcess.id,
              uploadId: this.uploadId,
            });
            this.$store.commit("setFilesCount", this.$store.state.filesCount + 1);
          } catch (e) {
            const toastMessage = "Der gik noget galt med upload fra din computer, prøv igen";
            this.$showRegisterToast(toastMessage, "error", 7000, { ProcessGuid: this.processId });
            this.$emit('file-upload-block', false);
            this.uppyDragDrop.cancelAll();
            console.log(e);
          }
        })
        .on("error", (error) => {
          console.log("uppy error caught as expected since we use custom upload:\n " + error);
          this.uppyDragDrop.getFiles().forEach((file) => {
            this.uppyDragDrop.setFileState(file.id, {
              progress: { uploadComplete: true, uploadStarted: true },
            });
          });
          if (!this.currentlyPollingUpload && !this.cancelUploadPolling) {
            console.log("UPLOAD POLLING STARTED (From uppy error)");
            this.pollFileUpload();
          }
        });
    }
  },
  async beforeUnmount() {
    this.cancelAllPolling = true
    this.cancelUploadPolling = true
    if (this.uppyDragDrop) {
      await this.uppyDragDrop.close(); //Seems to give issues when going back and forth a lot.
    }
    console.log("STOPPED ALL POLLING");
    this.$store.commit("setDocumentStatusPoll", false)
  },
  methods: {
    pollFileUpload() {
      this.$store.dispatch("updateRestorationStatus", {
          processId: this.$route.params.processId,
          status: "TOCChangesDetected"
      });

      this.currentlyPollingUpload = true;
      let pollCount = 0;
      let timeOutCount = 120;
      let uploadProgress = 0;
      console.log("UPLOAD POLLING STARTED");

      const pollFunction = () => {
        pollCount += 1;
        timeOutCount -= 1;
        if (!this.uploadId) {
          pollCount = 0;
        }
        const randomIncrement = Math.random() * 100;
        uploadProgress += randomIncrement;
        if (uploadProgress > 100) {
          uploadProgress = 100;
        }
        this.$store.dispatch("pollUploadedFiles", this.uploadId);
        this.uppyDragDrop.setState({ totalProgress: uploadProgress })
        if (this.cancelUploadPolling) {
          this.uppyDragDrop.cancelAll();
          this.uppyDragDrop.resetProgress();
          this.currentlyPollingUpload = false
          this.$emit('file-upload-block', false)
          this.uploadId = false;
        } else if (pollCount >= 8 || timeOutCount <= 0) {
          console.log("UPLOAD " + pollCount+" seconds passed with no fresh uploads - stopping upload pollin timeout: " + timeOutCount);
          if (!this.normalPolling) {
            this.pollDocumentStatusesNormal();
          }
          this.uppyDragDrop.cancelAll();
          this.uppyDragDrop.resetProgress();
          this.noFilesToUpload = 0;
          this.currentlyPollingUpload = false
          this.uploadId = false;
          this.$emit('file-upload-block', false)
          this.$store.commit("setUploadedFilesNo", this.noFilesToUpload)
        } else {
          // Schedule the next 
          setTimeout(pollFunction, 1000);
        }
      };

      if (!this.cancelUploadPolling) {
        // Start the upload polling
        setTimeout(pollFunction, 1000);
      }
    },
    pollDocumentStatusesInitial() {
      this.$store.commit("setDocumentStatusPoll", true);
      this.$store.dispatch("loadFilesFromApi", this.$route.params.processId);
      console.log("INITIAL DOCUMENT STATUS POLLING STARTED");

      let pollInterval = 3000; // Specify the interval in milliseconds
      let pollCount = 0

      const pollFunction = async () => {
        if (!this.cancelAllPolling) {
          pollCount += 1
          console.log("INITIAL - Polling documents");
          const docObj = { processId: this.$store.state.openProcess.process_id, pollCount: pollCount};
          await this.$store.dispatch("pollDocumentStatuses", docObj);
          if (!this.cancelAllPolling) {
            setTimeout(pollFunction, pollInterval); // Call pollFunction again after the interval
          }
        } else {
          console.log("INITIAL - Polling canceled");
          this.$store.commit("setDocumentStatusPoll", false)
        }
      };
      // Start the initial polling
      setTimeout(pollFunction, pollInterval);
    },
    pollDocumentStatusesNormal() {
      if (this.normalPolling) {
        return
      }
      this.$store.commit("setDocumentStatusPoll", true);
      this.cancelAllPolling = false;
      this.normalPolling = true;
      console.log("NORMAL DOCUMENT STATUS POLLING STARTED");

      const pollInterval = 3000; // Specify the interval in milliseconds

      const pollFunction = async () => {
        if (!this.cancelAllPolling) {
          const docObj = { processId: this.$store.state.openProcess.process_id };
          await this.$store.dispatch("pollDocumentStatuses", docObj);
          if (!this.cancelAllPolling) {
            console.log("NORMAL - Polling documents");
            setTimeout(pollFunction, pollInterval); // Call pollFunction again after the interval
          } else {
            console.log("NORMAL - Polling canceled");
            this.normalPolling = false
            this.$store.commit("setDocumentStatusPoll", false)
          }
        } else {
          console.log("NORMAL - Polling canceled");
          this.normalPolling = false
          this.$store.commit("setDocumentStatusPoll", false)
        }
      };

      // Start the normal polling
      setTimeout(pollFunction, pollInterval);
    },
  }, 
};
</script>

<style>
/* hide icons created by uppy */
/* custom css for uppy classes */
.uppy-DragDrop-arrow {
  display: none;
}
.uppy-ProgressBar-inner {
  height: 47.998px;
  border-radius:.25rem;
  background-color: #DB58A4;
  box-shadow: 0 0 3px #DB58A4;
}
.uppy-Root {
  width: 100%;
}
.for-ProgressBar {
  height: 0;
}
.uppy-DragDrop-label {
  font-weight:600;
}

</style>