<template>
  <div
    class="sticky top-0"
    style="z-index: 900"
  >
    <div class="w-full flex items-center justify-between bg-gray-100 h-16 px-6 border-b">
      <div class="flex items-center h-20">
        <router-link
          :to="rootUrl"
          class="font-semibold text-3xl text-blue-400 py-3 px-2 rounded-md hover:bg-gray-200"
        >
          <svg
            width="126px"
            height="30px"
            viewBox="0 0 13315 3180"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xml:space="preserve"
            xmlns:serif="http://www.serif.com/"
            style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
          >
            <path
              d="M11725.4,795c-439.167,0 -795,355.417 -795,795c-0,439.583 355.416,791.667 795,791.667c439.166,-0 795,-355.417 795,-795c-0,-439.584 -359.167,-791.667 -795,-791.667Zm-0,-795c-878.75,0 -1589.58,710.833 -1589.58,1589.58c0,878.75 710.834,1589.58 1589.58,1589.58c878.75,-0 1589.58,-710.834 1589.58,-1589.58c0,-878.75 -714.167,-1589.58 -1589.58,-1589.58Z"
              style="fill:url(#_Linear1);"
            />
            <rect
              x="12517.1"
              y="0"
              width="795"
              height="3176.25"
              style="fill:#db59a4;"
            />
            <path
              d="M1536.25,1267.92c-53.75,-53.75 -120.833,-97.084 -191.25,-130.834c-107.5,-53.75 -231.25,-80.416 -368.75,-80.416c-187.917,-0 -355.417,47.083 -502.917,137.5c-144.166,90.416 -261.666,217.916 -348.75,375.833c-84.166,160.833 -124.583,341.667 -124.583,546.25c0,204.583 40.417,385.833 124.167,543.333c87.083,157.5 201.25,285 348.75,375.834c147.5,90.833 315.416,137.5 502.916,137.5c137.5,-0 261.667,-26.667 372.084,-80.417c73.75,-33.333 134.166,-77.083 187.916,-127.5l0,164.167l627.084,-0l-0,-2032.5l-626.667,-0l0,171.25Zm-429.583,1327.92c-87.084,0 -164.167,-20 -234.584,-63.75c-70.416,-40.416 -124.166,-100.416 -164.166,-171.25c-40.417,-73.75 -57.084,-154.166 -57.084,-248.333c0,-94.167 20,-177.917 57.084,-251.667c40.416,-73.75 97.083,-127.5 164.166,-167.5c70.417,-40.416 150.834,-63.75 238.334,-63.75c87.5,0 167.5,20 234.583,63.75c70.417,40.417 124.167,93.75 157.5,167.5c40.417,73.75 57.083,157.5 57.083,251.667c0,140.833 -40.416,255 -124.166,348.75c-83.75,91.25 -191.25,134.583 -328.75,134.583Z"
              style="fill:#072a57;fill-rule:nonzero;"
            />
            <path
              d="M5416.67,0l-644.167,0l0,1096.67l-1072.92,-0l-660.833,912.5l0,-1760.83l-644.167,0l0,2884.17l644.167,0l0,-979.167l660.833,979.167l754.584,0l-788.334,-1076.67l647.5,-828.333l0,415.833l459.167,0l0,1489.17l644.167,0l-0,-1489.17l459.583,0l-0,-546.666l-459.583,-0l-0,-1096.67Z"
              style="fill:#072a57;fill-rule:nonzero;"
            />
            <rect
              x="6074.17"
              y="1096.67"
              width="640.417"
              height="2035.83"
              style="fill:#072a57;"
            />
            <path
              d="M8632.92,1190.83c-167.5,-90.416 -358.75,-137.5 -570,-137.5c-211.25,0 -402.5,47.084 -570,140.834c-167.5,90.416 -301.667,217.916 -399.167,375.833c-97.083,157.5 -147.5,338.75 -147.5,540c0,201.25 50.417,382.5 147.5,543.333c97.083,160.834 231.25,291.667 399.167,385.834c171.25,90.416 358.75,137.5 570,137.5c211.25,-0 402.5,-47.084 570,-137.5c167.5,-93.75 301.666,-224.584 399.166,-385.834c97.084,-160.833 147.5,-342.083 147.5,-543.333c0,-201.25 -50.416,-382.5 -147.5,-540c-97.083,-161.25 -231.25,-288.75 -399.166,-379.167Zm-167.5,1177.08c-37.084,73.75 -90.417,130.833 -157.5,171.25c-70.417,40.416 -150.834,57.083 -241.667,57.083c-90.417,0 -167.5,-20 -238.333,-57.083c-70.417,-40.417 -124.167,-100.417 -164.167,-171.25c-40.417,-73.75 -57.083,-157.5 -57.083,-251.667c-0,-94.167 20,-177.917 57.083,-251.667c40.417,-73.75 93.75,-127.5 164.167,-167.5c70.416,-40 150.833,-63.75 238.333,-63.75c90.417,0 171.25,20 238.333,63.75c70.417,40.417 124.167,93.75 164.167,167.5c40.417,70.417 57.083,150.834 57.083,248.334c0,97.5 -20.416,177.916 -60.416,255Z"
              style="fill:#072a57;fill-rule:nonzero;"
            />
            <g>
              <path
                d="M9320.42,1244.17c10,-10 16.666,-20 16.666,-37.084c0,-13.333 -6.666,-26.666 -16.666,-37.083c-10,-10.417 -23.334,-13.333 -43.75,-13.333l-50,-0l-0,164.166l26.666,0l0,-63.75l16.667,0l40.417,63.75l33.333,0l-47.083,-67.083c10.416,-2.917 20.416,-6.25 23.75,-9.583Zm-63.75,-63.75l20,-0c10,-0 16.666,3.333 23.333,6.666c6.667,3.334 10,10 10,20c0,10 -3.333,16.667 -10,20c-6.667,3.334 -13.333,6.667 -23.333,6.667l-20,0l-0,-53.333Z"
                style="fill:#072a57;fill-rule:nonzero;"
              />
              <path
                d="M9411.25,1187.5c-6.667,-16.667 -16.667,-33.333 -30,-43.75c-13.333,-13.333 -26.667,-23.333 -43.75,-30c-16.667,-6.667 -37.083,-10 -53.75,-10c-20,0 -37.083,3.333 -53.75,10c-16.667,6.667 -33.333,16.667 -43.75,30c-13.333,13.333 -23.333,26.667 -30,43.75c-6.667,16.667 -10,37.083 -10,57.083c0,20 3.333,40.417 10,57.084c6.667,16.666 16.667,33.333 30,43.75c13.333,13.333 26.667,23.333 43.75,30c16.667,6.666 37.083,10 53.75,10c20,-0 37.083,-3.334 53.75,-10c16.667,-6.667 33.333,-16.667 43.75,-30c13.333,-13.334 23.333,-26.667 30,-43.75c6.667,-16.667 10,-37.084 10,-57.084c0,-23.75 -3.333,-40.416 -10,-57.083Zm-20.417,103.75c-6.666,13.333 -13.333,26.667 -26.666,37.083c-10,10 -23.334,20 -37.084,26.667c-13.75,6.667 -30,10 -47.083,10c-17.083,-0 -33.333,-3.333 -47.083,-10c-13.75,-6.667 -26.667,-13.333 -37.084,-26.667c-10,-10 -20,-23.333 -23.333,-37.083c-6.667,-13.333 -10,-30 -10,-47.083c0,-17.084 3.333,-33.334 10,-47.084c6.667,-13.333 13.333,-26.666 23.333,-37.083c10,-10.417 23.334,-20 37.084,-26.667c13.75,-6.666 30,-10 47.083,-10c17.083,0 33.333,3.334 47.083,10c13.75,6.667 26.667,13.334 37.084,26.667c10,10 20,23.333 26.666,37.083c6.667,13.334 10,30 10,47.084c0,17.083 -2.916,30.416 -10,47.083Z"
                style="fill:#072a57;fill-rule:nonzero;"
              />
            </g>
            <path
              d="M6385.83,888.75c177.917,0 322.084,-144.167 322.084,-322.083c-0,-177.917 -144.167,-322.084 -322.084,-322.084c-177.916,0 -322.083,144.167 -322.083,322.084c-0,181.25 144.583,322.083 322.083,322.083Z"
              style="fill:#072a57;fill-rule:nonzero;"
            />
            <defs>
              <linearGradient
                id="_Linear1"
                x1="0"
                y1="0"
                x2="1"
                y2="0"
                gradientUnits="userSpaceOnUse"
                gradientTransform="matrix(2321.34,0,0,2321.34,10494.2,1589.58)"
              >
                <stop
                  offset="0"
                  style="stop-color:#d85baa;stop-opacity:1"
                />
                <stop
                  offset="0.63"
                  style="stop-color:#db59a4;stop-opacity:1"
                />
                <stop
                  offset="1"
                  style="stop-color:#072a57;stop-opacity:1"
                />
              </linearGradient>
            </defs>
          </svg>
        </router-link>
        <redaction-top-nav
          v-if="user.isAuthenticated"
          class="ml-8"
        />
      </div>
      <div
        v-if="user.isAuthenticated"
        class="flex items-center"
      >
        <!-- right navbar -->
        <HelpMe />
        <div class="flex items-center relative">
          <!-- TODO: add user image -->
          <div
            id="navbar-user-icon"
            class="w-12 h-12 overflow-hidden rounded-full border border-black bg-gray-200 shadow-lg text-black cursor-pointer hover:bg-gray-300" 
            @click="dropDownOpen = !dropDownOpen"
          >
            <!-- no-user image icon -->
            <svg
              viewBox="161.219 123.111 109.655 152.425"
              class="w-14 h-14 pt-2 pr-2 rounded-full fill-current stroke-current"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                style="stroke-width: 8px; fill: none"
                d="M 186.484 181.44 C 231.93 181.44 260.334 230.637 237.612 269.994 C 236.765 271.46 235.856 272.888 234.887 274.276"
                transform="matrix(0.462444, -0.886649, 0.886649, 0.462444, -85.892891, 314.043701)"
              />
              <path
                d="M 509.345 292.021 m -30.7 0 a 30.7 30.7 0 1 0 61.4 0 a 30.7 30.7 0 1 0 -61.4 0 Z M 509.345 292.021 m -23.025 0 a 23.025 23.025 0 0 1 46.05 0 a 23.025 23.025 0 0 1 -46.05 0 Z"
                style=""
                transform="matrix(-0.68771, 0.725986, -0.725986, -0.68771, 778.223877, -2.419824)"
              />
            </svg>
            <!--            <div class="fixed text-sm bottom-0">-->
            <!--              {{ userFirstName }}-->
            <!--            </div>-->
          </div>
        </div>
      </div>

      <!-- dropdown menu -->
      <div
        class="absolute block border-gray-300 border-t-0 shadow-sm bg-white hover:bg-grey-200 rounded-md text-gray-700 w-48 bottom-10 right-0 mr-6 h-20"
        style="top: 5rem"
        :class="dropDownOpen ? '' : 'hidden'"
      >
        <!-- <a href="#" class="block px-4 py-2 bg-gray-100 hover:bg-gray-200"
          >Konto</a
        >
        <a href="#" class="block px-4 py-2 bg-gray-100 hover:bg-gray-200"
          >Indstillinger</a
        > -->
        <router-link
          to="/profile"
          class="cursor-pointer block px-4 py-2 hover:bg-gray-200"
          @click="sendEvent()"
        >
          Min profil
        </router-link>
        <a
          class="cursor-pointer block px-4 py-2 hover:bg-gray-200"
          @click="logout()"
        >Log ud</a>
      </div>
      <!-- dropdown menu end -->
    </div>
    <NotificationAlert ref="notifications" />
  </div>
</template>

<script>
import posthog from "posthog-js";

import { mapState } from "vuex";
import TopNav from "@/components/navigation/TopNav.vue";
// import SearchRefiner from "@/components/search/SearchRefiner.vue";
import NotificationAlert from "@/components/NotificationAlert.vue";
import HelpMe from "@/components/navigation/HelpMe.vue";
// import SearchBox from "@/components/search/SearchBox.vue";
// import BreadcrumbNavigation from "@/components/navigation/Breadcrumb.vue";

export default {
  name: "Navbar",
  components: {
    // SearchRefiner,
    HelpMe,
    NotificationAlert,
    "redaction-top-nav": TopNav,
  },
  data() {
    return {
      dropDownOpen: false,
      generatedPdfLink: false,
      showRefiner: false,
      rootUrl: '/'
    };
  },
  computed: {
    ...mapState(["user"]),
    userFirstName() {
      const userData = (this.user || {}).userData;
      const firstName = userData.first_name || "Bruger";
      return firstName;
    },
  },
  watch: {
    $route() {
      this.generatedPdfLink = false;
    },
    "user.isAuthenticated"(isAuthenticated) {
      this.setRootUrl(isAuthenticated)
    }
  },
  mounted() {
    this.setRootUrl(this.user.isAuthenticated)
  },
  methods: {
    setShowRefiner(show) {
      this.showRefiner = show;
    },
    toggleSidebar() {
      this.$store.dispatch("toggleSidebar");
    },
    logout() {
      this.$store.dispatch("posthogCapture", { event_name: "LogMeOut", data: {} });

      localStorage.removeItem("aktio-ai-user-token");
      this.$store.commit("authenticate", false);
      this.$store.commit("user/authenticate", false);

      // Reset Vuex store 
      this.$store.commit("user/resetState");
      this.$store.commit("notification/resetState")
      this.$store.commit("resetState");

      this.dropDownOpen = false;
      try {
        posthog.reset();
      } catch (error) {
        console.log(error);
      }
      //TODO: fetch the provider name from a variable, that is also used in Strapi
      if (process.env.NODE_ENV !== "development" && this.user.userData.provider == "kombit") {
        const redirectURI = encodeURIComponent(`${location.origin}/login`);

        window.location.href = `${process.env.VUE_APP_KEYCLOAK_URL}/protocol/openid-connect/logout?redirect_uri=${redirectURI}`;
      } else {
        // Redirect to the previously used login method
        if (localStorage.getItem("loginMethod") === "kombitSSO") {
          this.$router.push({ name: "LoginKombit" });
        } else {
          this.$router.push({ name: "Login" });
        }
      }
    },
    setRootUrl(authenticated) {
      // Set root url depending on authenticated and which login method is used
      if (authenticated) {
        this.rootUrl = '/processes'
      } else {
        if (localStorage.getItem("loginMethod") === "kombitSSO") {
          this.rootUrl = '/login'
        } else {
          this.rootUrl = '/login-strapi'
        }
      }
    },
    sendEvent() {
      this.dropDownOpen = false;
    }
  }
};
</script>