<template>
  <div class="p-8 flex flex-col">
    <redaction-button-exit
      class="absolute right-0 top-0 m-2"
      @emit-close="closeModal()"
    />
    <h1 class="text-xl text-left pb-3 title">
      Du er ved at oprette et afslag på en aktindsigt.
    </h1>
    <div class="content">
      <p>
        Tilføj relevant lovgivning til afslag.
        <standard-button
          class="h-9 ml-4"
          button-title="Tilføj lovgivning til afslag af sag"
          button-type="standard-disableable"
          button-text="Tilføj lovgivning"
          button-icon="plus"
          @click="addLawSelected()"
        />
      </p>
    </div>
    <div 
      v-for="law in lawsSelected" 
      :key="law.id" 
      class="flex m-2"
    >
      <span
        v-if="law.stk !== '1'"
        class="mx-2 bg-gray-100 p-2 rounded text-gray-900"
      >
        {{ law.display_name }} - {{ law.paragraph_label }} Stk. {{ law.stk }}
      </span>
      <span
        v-else
        class="mx-2 bg-gray-100 p-2 rounded text-gray-900"
      >
        {{ law.display_name }} - {{ law.paragraph_label }}
      </span>
      <standard-button
        class="h-9 ml-4"
        button-title="Tilføj lov til sag"
        button-type="delete"
        button-text="Fjern"
        @click="removeSelectedLaw(law)"
      />
    </div>
    <div class="mt-5 flex justify-end save-button">
      <standard-button
        class="h-9 ml-4"
        button-title="Opret afslag af afgørelsesbrev"
        button-type="standard-disableable"
        button-text="Opret afgørelsesbrev"
        @click="saveCase()"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import buttonExit from "@/components/controls/buttonExit.vue";
import StandardButton from "@/components/controls/StandardButton.vue";
import LawSelection from "@/components/controls/LawSelection.vue";

export default {
  name: "DenialProcessModal",
  components: {
    "redaction-button-exit": buttonExit,
    StandardButton,
  },
  props: {
    processId: String,
    decisionLetterId: Number,
    denialLaw: String,
  },
  data() {
    return {
      lawsSelected: [],
    };
  },
  computed: {
    ...mapState(["processStatus", "openProcess", "user"]),
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    addLawSelected() {
      this.$modal.show(
        LawSelection,
        {
          element: {},
          processId: this.openProcess.process_id,
          elementType: "process",
          denialLaw: this.denialLaw + "A",
          lawsAlreadySelected: this.lawsSelected,
        },
        {
          classes: "overflow-visible",
          height: "700px",
          width: "60%",
          clickToClose: true,
        },
        {
          modalEventHandler: this.handleParagraphSelected,
        }
      );
    },
    handleParagraphSelected(paragraph) {
      // Handle the selected paragraph here
      console.log("Selected Paragraph:", paragraph);
      this.lawsSelected.push(paragraph);
      // Add your logic to process the selected paragraph
    },
    removeSelectedLaw(law) {
      this.lawsSelected = this.lawsSelected.filter(
        (selectedLaw) => selectedLaw.id !== law.id
      );
    },
    async saveCase() {
      await this.$store.dispatch("updateProcess", {
        id: this.openProcess.id,
        laws: this.lawsSelected,
      });
      await this.$store.dispatch("updateRestorationStatus", {
        processId: this.$route.params.processId,
        status: "RestoreBoth",
      });
      this.$emit("reset");
      const obj = {
        process: {
          id: this.openProcess.id,
          process_id: this.$route.params.processId,
        },
        request_user: this.user.userData,
        decision_letter_id: this.decisionLetterId,
      };
      this.$store.dispatch("resetDecisionLetterContent", obj);
      this.closeModal();
      // navigate to decisionletter page
      this.$router.push({
        name: "Decision",
        params: {
          processId: this.$route.params.processId,
          decision: "beslutning",
        },
      });
    },
  },
};
</script>

<style>
.title {
  margin-bottom: 5px;
}

.content p {
  word-break: keep-all;
}

.content p:first-of-type {
  margin-top: 10px;
  margin-bottom: 15px;
}

.option-2 {
  margin-top: 15px;
}

.save-button {
  position: absolute;
  bottom: 30px;
  right: 30px;
}
</style>
