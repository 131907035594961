<template>
  <div class="accordion-item">
    <h2>
      <button
        class="accordion-header flex items-center justify-between font-medium w-full p-5 text-left border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-800"
        :class="['accordion-header', { 'open': isOpen }]"
        @click="toggle"
      >
        <span class="text-gray-900">{{ title }}</span>
        <svg
          data-accordion-icon=""
          class="w-6 h-6 shrink-0"
          :class="{ 'rotate-180': !isOpen }"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        ><path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        /></svg>
      </button>
    </h2>
    <div
      v-if="isOpen"
      class="accordion-body"
    >
      <slot />
    </div>
  </div>
</template>
  
<script>
    export default {
        name: "AccordionItem",
        props: {
            title: {
                type: String,
                required: true,
            },
            open: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                isOpen: this.open,
            };
        },
        methods: {
            toggle() {
                this.isOpen = !this.isOpen;
            },
        },
    };
</script>
  
<style scoped>
    .accordion-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-top: 1px solid rgb(229 231 235);
        border-left: 1px solid rgb(229 231 235);
        border-right: 1px solid rgb(229 231 235);
        text-align: left;
        cursor: pointer;
    }
    .accordion-header.open {
        background-color: rgb(243 244 246);
    }

    .accordion-header:focus {
        box-shadow: none;
    }

    .accordion-body {
        padding: 1rem;
        background-color: white;
        border-left: 1px solid rgb(229 231 235);
        border-right: 1px solid rgb(229 231 235);
        border-bottom: 1px solid rgb(229 231 235);
    }
</style>
  