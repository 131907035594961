<template>
  <div>
    <div class="flex mt-2 mb-2 items-center text-l font-semibold">
      <button @click="goBack()">
        <font-awesome-icon  
          class="mr-2"
          icon="fa-arrow-left"
        />
        Dokumentoversigt
      </button>
      <div class="ml-16 flex cursor-pointer">
        <redaction-icon-pdf class="h-6 w-6 mt-2 mr-2" />
        <multiselect
          id="documents"
          v-model="openDocument"
          class="w-150"
          name="documents"
          :options="documentsWithAdjustedIndex"
          :multiple="false"
          :searchable="false"
          :allow-empty="false"
          :close-on-select="true"
          track-by="document_id"
          :option-height="150"
          select-label
          deselect-label
          selected-label
        >
          <template #option="{ option }">
            <div class="flex justify-between w-full">
              <span class="flex-shrink-0 mr-1 text-aktio-pink-600">
                {{ option.adjustedIndex }}
              </span>
              <span class="flex-grow">
                {{ option.display_name }}
              </span>
              <span
                v-if="isExceptedById(option)"
                class="flex-shrink-0 rounded text-xs text-white px-2 py-1 text-align-right bg-red-500"
              >
                {{ option.document_status.display_name }}
              </span>
              <span
                v-else-if="isFinishedById(option)"
                class="flex-shrink-0 rounded text-xs text-white px-2 py-1 text-align-right bg-green-500"
              >
                {{ option.document_status.display_name }}
              </span> 
            </div>
          </template>
          <template #singleLabel="{ option }">
            <div class="flex justify-between w-full">
              <span class="flex-shrink-0 mr-1 text-aktio-pink-600">
                {{ findIndexById(option) }}
              </span>
              <span class="flex-grow">
                {{ option.display_name }}
              </span>
              <span
                v-if="isExceptedById(option)"
                class="flex-shrink-0 rounded text-xs text-white px-2 py-1 text-align-right bg-red-500"
              >
                {{ option.document_status.display_name }}
              </span>
              <span
                v-else-if="isFinishedById(option)"
                class="flex-shrink-0 rounded text-xs text-white px-2 py-1 text-align-right bg-green-500"
              >
                {{ option.document_status.display_name }}
              </span> 
            </div>
          </template>
        </multiselect>
      </div>
      <div class="flex self-end">
        <standard-button
          class="h-10 ml-4 mr-2"
          button-icon-fa="fa-arrow-up"
          :button-disabled="openDocumentIndex === 0"
          button-type="standard-disableable"
          @click="nextPreviousDocument('previous')"
        />
        <standard-button
          class="h-10 mr-2"
          button-icon-fa="fa-arrow-down"
          :button-disabled="openDocumentIndex + 1 >= openFiles.length"
          button-type="standard-disableable"
          @click="nextPreviousDocument('next')"
        />
        <standard-button
          v-show="!shouldShowDocumentExceptedElement"
          class="h-10 ml-4 mr-2"
          button-title="Undtag Dokument"
          button-type="blue"
          :button-icon="getButtonIcon()"
          button-text="Undtag Dokument"
          button-text-small
          @click="showLawSelectionModal()"
        />
        <div 
          v-show="shouldShowDocumentExceptedElement"
          class="flex items-center cursor-pointer"
          @click="showLawSelectionModal()"
        >
          <redaction-doc-status
            v-if="openFile.document_status"
            :document-status="openFile.document_status"
            :document="openFile"
            :document-id="openFile.id"
            :process-id:="openFile.process.process_id"
          />
        </div>
        <div
          v-show="shouldShowFinishButton"
          class="flex items-center ml-2"
        >
          <toggle-switch 
            v-model="localShouldBeChecked"
            :disabled="isToggleDisabled"
          />
          <span class="ml-2 text-base font-normal">Færdigbehandlet</span>
        </div>
      </div>
      <standard-button
        v-if="showTextAnnotateViewButton"
        class="ml-auto self-end"
        button-text="NLP annotering"
        button-type="grey"
        @click="goToTextAnnotateViewer"
      />
    </div>
    <div class="flex">
      <iframe
        title="PDF"
        id="pdfViewerIFrame"
        ref="iframe"
        :src="`/static/viewer/web/viewer.html?pagemode=thumbs`"
        width="100%"
        style="height: calc(96vh - 8rem)"
      />
      <div
        id="sidebarContainer"
        class="w-4/12 ml-2"
      >
        <div
          id="sidebarContent"
          class="fixed bg-white border border-gray-300 overflow-y-auto rounded flex flex-col"
          style="height: calc(96vh - 8rem)"
        >
          <div class="sticky top-0 bg-white">
            <div class="px-4 mt-3 mb-3 flex flex-wrap">
              <button
                :disabled="waitingForSearch === true"
                title="Navn og CPR#"
                class="btn-entity bg-aktio-darkblue-600 hover:bg-aktio-pink-500 focus:outline-none rounded px-2 py-1 mt-1 w-20 mr-1 text-white text-sm shadow block items-center"
                @click="searchEntity('person')"
              >
                Person
              </button>
              <button
                :disabled="waitingForSearch === true"
                title="Telefon og Mail."
                class="btn-entity bg-aktio-darkblue-600 hover:bg-aktio-pink-500 focus:outline-none rounded px-2 py-1 mt-1 w-20 mr-1 text-white text-sm shadow block items-center"
                @click="searchEntity('kontakt')"
              >
                Kontakt
              </button>
              <button
                :disabled="waitingForSearch === true"
                title="Adresse."
                class="btn-entity bg-aktio-darkblue-600 hover:bg-aktio-pink-500 focus:outline-none rounded px-2 py-1 mt-1 w-20 mr-1 text-white text-sm shadow block items-center"
                @click="searchEntity('adresse')"
              >
                Lokation
              </button>
              <button
                :disabled="waitingForSearch === true"
                title="Diagnoser og Medikament"
                class="btn-entity bg-aktio-darkblue-600 hover:bg-aktio-pink-500 focus:outline-none rounded px-2 py-1 mt-1 w-20 mr-1 text-white text-sm shadow block items-center"
                @click="searchEntity('helbred')"
              >
                Helbred
              </button>
              <button
                :disabled="waitingForSearch === true"
                title="Priser, Reg#, Konto#, IBAN og SWIFT."
                class="btn-entity bg-aktio-darkblue-600 hover:bg-aktio-pink-500 focus:outline-none rounded px-2 py-1 mt-1 w-20 mr-1 text-white text-sm shadow block items-center"
                @click="searchEntity('økonomi')"
              >
                Økonomi
              </button>
              <button
                :disabled="waitingForSearch === true"
                title="Årstal og Dato."
                class="btn-entity bg-aktio-darkblue-600 hover:bg-aktio-pink-500 focus:outline-none rounded px-2 py-1 mt-1 w-20 mr-1 text-white text-sm shadow block items-center"
                @click="searchEntity('dato')"
              >
                År & dato
              </button>
              <button
                :disabled="waitingForSearch === true"
                title="CVR, Organisation, Fagforening og Trosamfund"
                class="btn-entity bg-aktio-darkblue-600 hover:bg-aktio-pink-500 focus:outline-none rounded px-2 py-1 mt-1 w-20 mr-1 text-white text-sm shadow block items-center truncate"
                @click="searchEntity('organisation')"
              >
                Organisation
              </button>
              <button
                :disabled="waitingForSearch === true"
                title="Klagenævn, Myndigheder, Kommune og Region"
                class="btn-entity bg-aktio-darkblue-600 hover:bg-aktio-pink-500 focus:outline-none rounded px-2 py-1 mt-1 w-20 mr-1 text-white text-sm shadow block items-center truncate"
                @click="searchEntity('myndighed')"
              >
                Myndighed
              </button>
              <button
                :disabled="waitingForSearch === true"
                title="KVHX-nummer, GPS og Matrikelnummer"
                class="btn-entity bg-aktio-darkblue-600 hover:bg-aktio-pink-500 focus:outline-none rounded px-2 py-1 mt-1 w-20 mr-1 text-white text-sm shadow block items-center"
                @click="searchEntity('lokation')"
              >
                Position
              </button>
              <button
                :disabled="waitingForSearch === true"
                title="Ideologi, Nummerplade, Direktiv Nummer, Stelnummer, Forbrydelse, GUID, URL, Nationalitet, Køn, Sexualitet og Etnicitet"
                class="btn-entity bg-aktio-darkblue-600 hover:bg-aktio-pink-500 focus:outline-none rounded px-2 py-1 mt-1 w-20 mr-1 text-white text-sm shadow block items-center"
                @click="searchEntity('andet')"
              >
                Andet
              </button>
              <button
                v-show="waitingForSearch || searchResultsCount > 0"
                title="Annuller søgningen og resultaterne"
                class="btn-entity bg-red-600 hover:bg-red-500 focus:outline-none rounded px-2 py-1 mt-1 w-20 mr-1 text-white text-sm shadow block items-center"
                @click="clearSearch()"
              >
                Annuller
              </button>
            </div>
            <div class="px-4 py-2 flex">
              <redaction-search-box @show-refiner="setSelectedNav" />
            </div>
            <ul class="flex pl-2 py-2 justify-around">
              <li>
                <div class="bg-white text-gray-800 text-sm">
                  <a
                    class="cursor-pointer no-underline"
                    :class="[
                      {
                        'text-blue-500 xl:border-b-2 xl:pb-1 xl:border-blue-500':
                          documentViewerNav === 'redactions',
                      },
                    ]"
                    title="Vis overstregninger"
                    @click="$store.commit('setDocumentViewerNav', 'redactions')"
                  >Overstregninger
                    <span
                      v-if="loading"
                      class="bg-gray-600 rounded-full 2xl:mx-1 px-2 text-white text-xs"
                      :class="[
                        { 'bg-blue-500': documentViewerNav === 'redactions' },
                      ]"
                    >Indlæser</span>
                    <span
                      v-else
                      class="bg-gray-600 rounded-full 2xl:mx-1 px-2 text-white text-xs"
                      :class="[
                        { 'bg-blue-500': documentViewerNav === 'redactions' },
                      ]"
                    >{{ redactions.length }}</span>
                  </a>
                </div>
              </li>
              <li class="mx-2">
                <div class="bg-white text-gray-800 text-sm">
                  <a
                    class="cursor-pointer no-underline"
                    :class="[
                      {
                        'text-blue-500 xl:border-b-2 xl:pb-1 xl:border-blue-500':
                          documentViewerNav === 'search',
                      },
                    ]"
                    title="Vis søgeresultater"
                    @click="$store.commit('setDocumentViewerNav', 'search')"
                  >
                    Søgeresultater
                    <span
                      v-if="searchResultsCount >= 0"
                      class="bg-gray-600 rounded-full 2xl:mx-1 px-2 text-white text-xs"
                      :class="[
                        { 'bg-blue-500': documentViewerNav === 'search' },
                      ]"
                    >{{ searchResultsCount }}</span>
                    <span
                      v-else
                      class="bg-gray-600 rounded-full 2xl:mx-1 px-2 text-white text-xs"
                      :class="[
                        { 'bg-blue-500': documentViewerNav === 'search' },
                      ]"
                    >0</span>
                  </a>
                </div>
              </li>
            </ul>
          </div>
          <div
            v-if="documentViewerNav === 'search'"
            class="px-4"
          >
            <redaction-search-results />
          </div>
          <div
            v-if="documentViewerNav === 'redactions'"
            class="px-4 overflow-y-scroll"
          >
            <standard-button
              class="mt-2 block my-0 mx-auto"
              button-title="Hent overstregninger"
              button-text="Hent overstregninger"
              :button-text-small="true"
              @click="loadRedactions()"
            />
            <redaction-redactions-overview />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapState } from "vuex";
import { v4 as uuidv4 } from "uuid";
import { debounce } from 'lodash';
import SearchBox from "@/components/search/SearchBox.vue";
import SearchResults from "@/components/search/SearchResults.vue";
import DocStatus from "@/components/controls/DocStatus.vue";
import RedactionsOverview from "@/components/redaction/RedactionsOverview.vue";
import Multiselect from "vue-multiselect";
import StandardButton from "@/components/controls/StandardButton.vue";
import LawSelection from "@/components/controls/LawSelection.vue";
import ConfirmCloseCaseForm from "@/components/forms/ConfirmCloseCaseForm.vue";
import FEATURE_FLAGS from '@/constants/featureflags';
import iconPdf from "@/components/icons/pdf.vue";
import documentStatuses from "@/constants/documentStatuses";
import isValidDocument from "@/utils/isValidDocument";
import ToggleSwitch from "@/components/controls/ToggleSwitch.vue";

const PDFJS = require("pdfjs-dist/build/pdf.js");

PDFJS.GlobalWorkerOptions.workerSrc = "//" + location.host + "/static/pdf.worker.js";
const CMAP_URL = "../../node_modules/pdfjs-dist/cmaps/";
const CMAP_PACKED = true;
const BASE64_MARKER = ";base64,";

// Convert base64 data to Uint8array data
const convertDataURIToBinary = (dataURI) => {
  const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  const base64 = dataURI.substring(base64Index);
  const raw = window.atob(base64);
  const rawLength = raw.length;
  const array = new Uint8Array(new ArrayBuffer(rawLength));

  for (let i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
};

export default {
  name: "DocumentViewer",
  components: {
    "redaction-search-box": SearchBox,
    "redaction-search-results": SearchResults,
    "redaction-redactions-overview": RedactionsOverview,
    "redaction-icon-pdf": iconPdf,
    "redaction-doc-status": DocStatus,
    Multiselect,
    StandardButton,
    ToggleSwitch
  },
  data() {
    return {
      iframe: null,
      pdf: undefined,
      currentPdfUrl: undefined,
      documents: [],
      openDocument: undefined,
      openDocumentIndex: 0,
      pdfData: undefined,
      readyToSendPDFData: false,
      showTextAnnotateViewButton: false,
      localShouldBeChecked: false,
      loading: true,
      searched: false,
      splitIndexDiff: 0,
      ignoreInitialUpdate: true,
    };
  },
  computed: {
    ...mapGetters(['findLawById']),
    ...mapState([
      "openFile",
      "openProcess",
      "openFiles",
      "redactions",
      "documentViewerNav",
      "searchPDFResults",
      "searchResultsCount",
      "waitingForSearch",
      "strapiUrl",
      "fileData",
      "fileDataLoadingData",
      "redactionsToScrollTo",
      "amountOfRedactionsChanges",
      "user",
      "documentFinishStatus",
      "documentReadyStatus",
      "documentExceptStatus",
      "lawsLoaded"
    ]),
    shouldShowFinishButton() {
      if (this.openFile && this.openFile.document_status && this.openFile.document_status.guid) {
        const documentStatus = this.openFile.document_status.guid;
        return this.$featureflag(FEATURE_FLAGS.enableToggleFinishDocument) &&
          (documentStatus === documentStatuses.file_parsed || documentStatus === documentStatuses.finished);
      } else {
        return false;
      }
    },
    shouldShowDocumentExceptedElement() {
      if (this.openFile && this.openFile.document_status && this.openFile.document_status.guid) {
        const documentStatus = this.openFile.document_status.guid;
        if (this.openFile.document_status.id === this.documentExceptStatus.id) {
          this.openFile.document_status = this.documentExceptStatus;
          this.openDocument.document_status = this.documentExceptStatus;
          return true;
        }
      } else {
        return false;
      }
    },
    documentsWithAdjustedIndex() {
      let localSplitIndexDiff = 0;
      let localCurrentSplitIndicator = 0; 
      let previousFileName = "";
      return this.openFiles.map((document, index) => {
        let adjustedIndex = index + 1 - localSplitIndexDiff;
        if (document.original_file_split_number) {
          localCurrentSplitIndicator += 1;
          if (localCurrentSplitIndicator > document.original_file_split_number || previousFileName !== document.display_name.split(" del ")[0]) {
            localCurrentSplitIndicator = 1;
          }
          previousFileName = document.display_name.split(" del ")[0];
          adjustedIndex += '.' + localCurrentSplitIndicator;
          localSplitIndexDiff += 1;
          if (localCurrentSplitIndicator === document.original_file_split_number) {
            localSplitIndexDiff -= 1;
          }
        } else {
          localCurrentSplitIndicator = 0;
        }
        return {
          ...document,
          adjustedIndex: adjustedIndex
        };
      });
    },
    isToggleDisabled() {
      return (
        this.openDocument &&
        this.openDocument.document_status &&
        this.openDocument.document_status.display_name === "Undtaget" &&
        this.openFile &&
        this.openFile.document_status &&
        this.openFile.document_status.display_name === "Undtaget"
      );
    },
  }, // End of computed
  async mounted() {
    try {
      if (this.$route.params.documentId) {
        const fileObj = { 
          documentId: this.$route.params.documentId,
          processId: this.$route.params.processId 
        };
        await this.$store.dispatch("loadSpecificFile", fileObj);
        await this.$store.dispatch("loadUserFromAPI");
        const iframe = this.$refs.iframe;
        if (iframe) {
          iframe.removeEventListener("load", this.sendPDFData);
          iframe.addEventListener("load", this.sendPDFData, { once: true });
        }
        if (this.user && this.user.userData && this.user.userData.latest_laws) {
          if (!this.lawsLoaded) {
            await this.$store.dispatch("loadLaws");
          }
          const laws = this.user.userData.latest_laws
            .map(lawId => this.findLawById(lawId))
            .filter(law => law !== undefined)
            .reverse();
          if (laws.length > 0) {
            const event = new CustomEvent('setLatestLaw', { detail: laws });
            window.document.querySelector('#pdfViewerIFrame').contentDocument.dispatchEvent(event);
          }
        }

        if (this.$featureflag(FEATURE_FLAGS.showTextAnnotateViewButton)) {
          this.showTextAnnotateViewButton = true;
        }

        if (this.searchPDFResults) {
          const emptyResult = {query_result: []};
          this.$store.commit("setSearchTerm", "");
          this.$store.commit("setPageSearchResults", emptyResult);
        }

        if (this.openFile && this.openFile.file && this.openFile.process) {
          const fileDataObj = {
            file: this.openFile.file,
            process_id: this.openFile.process.process_id
          };
          await this.$store.dispatch("loadFileData", fileDataObj);
        } else {
          throw new Error("openFile or its properties are undefined");
        }

        if (this.openFile.process.process_id && !this.openProcess.process_id) {
          await this.$store.dispatch("loadSpecificProcess", this.openFile.process.process_id);
          await this.$store.dispatch("loadFilesFromApi", this.openFile.process.process_id);
        }

        this.openDocument = this.openFile;
        this.openDocumentIndex = this.openFiles.findIndex(({id}) => id === this.openDocument.id);
        this.openDocument = this.openFiles[this.openDocumentIndex];

        this.sendRedactions();
        this.loading = false;
        await this.ensureDocumentStatusLoaded();

        if (this.openFile && this.openFile.document_status) {
          this.localShouldBeChecked = this.openFile.document_status.is_finished;
          this.openDocument.document_status = this.openFile.document_status;
        }

        this.ignoreInitialUpdate = false;
      }
    } catch (error) {
      console.error("Error in mounted hook:", error);
    }
  }, // End of mounted
  created() {
    this.ensureDocumentStatusLoaded();
    window.document.addEventListener('newRedaction', this.addRedaction, false);
    window.document.addEventListener('showLawForRedaction', this.showLawForRedaction, false);
    window.document.addEventListener('getRedactions', this.sendRedactions, false);
    window.document.addEventListener('getPreviousDocument', () => this.nextPreviousDocument('previous'));
    window.document.addEventListener('getNextDocument', () => this.nextPreviousDocument('next'));
    window.document.addEventListener("keydown", this.keyboardShortcuts, false);
    window.document.addEventListener("goToRedactionResult", this.goToRedactionResult);
    window.document.addEventListener("showErrorMessage", this.showErrorMessage, false);
    window.document.addEventListener("undoRedaction", this.undoRedaction, false);
  },
  beforeUnmount() {
    window.document.removeEventListener('newRedaction', this.addRedaction);
    window.document.removeEventListener('showLawForRedaction', this.showLawForRedaction);
    window.document.removeEventListener('getRedactions', this.sendRedactions);
    window.document.removeEventListener('getPreviousDocument', () => this.nextPreviousDocument('previous'));
    window.document.removeEventListener('getNextDocument', () => this.nextPreviousDocument('next'));
    window.document.removeEventListener("keydown", this.keyboardShortcuts, false);
    window.document.removeEventListener("goToRedactionResult", this.goToRedactionResult);
    window.document.removeEventListener("showErrorMessage", this.showErrorMessage, false);
    window.document.removeEventListener("undoRedaction", this.undoRedaction, false);

    this.$store.commit("resetFileData");
    this.$store.commit("setRedactionsToScrollTo", []);
  },
  
  watch: {
    localShouldBeChecked: {
      handler: async function (checked) {
        if (this.ignoreInitialUpdate) {
          this.ignoreInitialUpdate = false;
          return;
        }
        if (this.isToggleDisabled) return;

        try {
          if (checked) {
            await this.updateDocumentStatus(this.documentFinishStatus);
          } else {
            await this.updateDocumentStatus(this.documentReadyStatus);
          }
        } catch (error) {
          console.error("Error updating document status:", error);
        }
      },
      immediate: true,
    },
    openDocument: {
      handler(document) {
        console.log("WATCH openDocument");
        if (!document) return;
        if (isValidDocument(document)) {
          this.loadDocumentData(document);
        } else {
          this.handleInvalidDocument();
        }
      },
      immediate: true
    },
    fileData: {
      handler: debounce(async function(data) {
        console.log("WATCH fileData");
        if (!data) return;
        try {
          const formattedData = convertDataURIToBinary(data);
          this.pdfData = formattedData;
          this.currentPdfUrl = this.openFile.file.url;
          this.openDocument = this.openFile;
          this.localShouldBeChecked = this.openFile.document_status.is_finished;
          this.openDocument.document_status = this.openFile.document_status;
          this.sendPDFData();
        } catch (error) {
          console.error("Error processing file data:", error);
        }
      }, 300), // Adjust debounce delay as needed
      immediate: true,
    },
    openFile: {
      handler: debounce(async function({ file, process }) {
        console.log("WATCH openFile");
        if (file && process) {
          try {
            if (this.currentPdfUrl !== file.url) {
              const fileDataObj = {
                file,
                process_id: process.process_id
              };
              await this.$store.dispatch("loadFileData", fileDataObj);
            }
            if (file && file.document_status) {
              this.localShouldBeChecked = file.document_status.is_finished;
            }
          } catch (error) {
            console.error("Error processing openFile:", error);
          }
        }
      }, 300), // Adjust debounce delay as needed
      immediate: true,
    },
    redactions: {
      handler: async function() {
        console.log("WATCH redactions");
        this.sendRedactions();
      },
      immediate: true,
    },
    redactionsToScrollTo: {
      handler: async function(data) {
        console.log("WATCH redactionsToScrollTo");
        if (data.length === 1) {
          if (data[0].retries > 5) {
            this.$store.commit("setRedactionsToScrollTo", data.slice(1));
            const toast_message = "Overstregningen kunne ikke hentes korrekt. Prøv venligst igen.";
            this.$showRegisterToast(toast_message, "error", 4000, {});
          } else {
            if (data[0].redaction.type === "search") {
              const event = new CustomEvent('scrollToSearch', { detail: data[0].redaction });
              window.document.querySelector('#pdfViewerIFrame').contentDocument.dispatchEvent(event);
            } else {
              const event = new CustomEvent('scrollToRedaction', { detail: data[0].redaction });
              window.document.querySelector('#pdfViewerIFrame').contentDocument.dispatchEvent(event);
            }
          }
        }
      },
      immediate: true,
    },
    amountOfRedactionsChanges: {
      handler: async function(amount) {
        console.log("WATCH amountOfRedactionsChanges");
        if (amount !== undefined) {
          if (amount === 0) {
            const toast_message = "Der er ingen nye overstregninger.";
            this.$showRegisterToast(toast_message, "success", 4000, {});
          } else if (amount > 0) {
            const toast_message = `${amount} ${amount > 1 ? 'nye' : 'ny'} overstregning${amount > 1 ? 'er' : ''} blev hentet.`;
            this.$showRegisterToast(toast_message, "success", 4000, {});
          } else {
            const absoluteValue = Math.abs(amount);
            const toast_message = `Der er blevet fjernet ${absoluteValue} ${absoluteValue > 1 ? 'overstregninger' : 'overstregning'}`;
            this.$showRegisterToast(toast_message, "success", 4000, {});
          }
        }
      },
      immediate: true,
    }
  }, // End of watch
  methods: {
    async updateDocumentStatus(status) {
      if (this.openFile.document_status.id !== status.id) {
        const documentChangesObj = {
          id: this.openFile.id,
          documentId: this.openFile.id,
          processId: this.openFile.process.process_id,
          data: {
            document_status: {
              id: status.id
            },
          }
        };
        try {
          const dispatch_status = await this.$store.dispatch("setDocumentStatus", documentChangesObj);
          if (dispatch_status === 200) {
            this.openFile.document_status = status;
            this.$store.commit("updateFile", this.openFile);
            this.openDocument.document_status = status;
          }
        } catch (error) {
          console.error("Error updating document status:", error);
        }
      }
    },
    async loadDocumentData(document) {
      await this.$store.dispatch("setNavigationParams", {
        processId: this.$route.params.processId,
        documentId: document.document_id,
        pageNumber: 1,
        randomNum: Math.random() * 100,
      });

      const fileObj = {
        documentId: document.document_id,
        processId: this.$route.params.processId
      };
      await this.$store.dispatch("loadSpecificFile", fileObj);

      this.openDocumentIndex = this.openFiles.findIndex(({id}) => id === document.id);

      this.sendRedactions();
      this.localShouldBeChecked = this.openFile.document_status.is_finished;
      this.openDocument.document_status = this.openFile.document_status;
    },
    handleInvalidDocument() {
      this.openDocument = this.openFile;
      this.localShouldBeChecked = this.openFile.document_status.is_finished;
      this.openDocument.document_status = this.openFile.document_status;
      this.$showRegisterToast("Dokumentet du ønsker at åbne er endnu ikke indlæst, eller det har en fejlstatus.", "error", 4000, { 'ProcessGuid': this.processId, documentId: this.documentId });
    },
    showErrorMessage({detail}){
      this.$showRegisterToast(detail.message, detail.type, 6000, {})
    },
    clearSearch() {
      const emptyResult = {query_result: []};
      this.$store.commit("setPageSearchResults", emptyResult);
      this.$store.commit("setWaitingForSearch", false);
      this.$store.commit("setCancelSearch", true);
      const event = new CustomEvent('clearSearches')
      window.document.querySelector('#pdfViewerIFrame').contentDocument.dispatchEvent(event)
    },
    async searchEntity(entityType) {
      const event = new CustomEvent('clearSearches')
      window.document.querySelector('#pdfViewerIFrame').contentDocument.dispatchEvent(event)
      this.$store.commit("setCancelSearch", false);
      this.$store.commit("setCurrentSearchType", entityType);
      this.$store.commit("setDocumentViewerNav", "search");
      const emptyResult = {query_result: []};
      this.$store.commit("setPageSearchResults", emptyResult);
      this.$store.commit("setWaitingForSearch", true);
      await this.$store.dispatch("getEntitySearchResults", {
        entity: entityType,
        documentId: this.$route.params.documentId,
      })
      this.searched = true;
      this.sendSearches();
    }, 
    goToTextAnnotateViewer(){
      this.$router.push({name: "TextAnnotateViewer", params: {
        processId: this.$route.params.processId,
        documentId: this.$route.params.documentId,
        pageNumber: this.$route.params.pageNumber,
      }});
    },
    async goToRedactionResult({detail: {success, redaction}}){
      if(this.redactionsToScrollTo.length > 0 &&
        this.redactionsToScrollTo[0].redaction &&
        redaction &&
        this.redactionsToScrollTo[0].redaction.id === redaction.id
      ){
        if(success) {
          // Remove redaction from queue
          this.$store.commit("setRedactionsToScrollTo", this.redactionsToScrollTo.slice(1))
        } else if(this.redactionsToScrollTo[0]) {
          // Increase the retries amount for the redaction
          const redactionWithMoreRetries = {
            redaction: this.redactionsToScrollTo[0].redaction,
            retries: this.redactionsToScrollTo[0].retries + 1
          }
          // Reset the redactions since we are updating a value inside the array next 
          // and the vuex store does not see that as a change
          await this.$store.commit("setRedactionsToScrollTo", [])
          // Update the redaction queue with the new data
          await this.$store.commit("setRedactionsToScrollTo", [redactionWithMoreRetries, ...this.redactionsToScrollTo.slice(1)])
        }
      }
    },
    loadRedactions(){
      this.$store.dispatch(
        "loadRedactions",
        this.openFile.process.process_id
      );
    },
    customDocumentLabel(option) {
      const index = this.openFiles.findIndex(file => file.document_id === option.document_id) + 1;
      return `${index}. ${option.display_name}`;
    },
    sendRedactions() {
      if (!this.openDocument || !this.openDocument.id) {
        console.error('openDocument or openDocument.id is undefined (DocumentViewer - sendRedactions: ca line 940)');
        return;
      }
      
      let redactionForDocument = [];
      this.redactions.forEach((redaction, index) => {
        if (redaction && redaction.document && redaction.document.id === this.openDocument.id) {
          redactionForDocument.push({
            ...redaction,
            number: index + 1
          });
        }
      });

      const iframe = window.document.querySelector('#pdfViewerIFrame');
      if (!iframe || !iframe.contentDocument) {
        console.error('pdfViewerIFrame or its contentDocument is null');
        return;
      }
      
      const event = new CustomEvent('setRedactions', { detail: redactionForDocument });
      iframe.contentDocument.dispatchEvent(event);
    },
    sendSearches(){
      let searchForDocument = []
      const searchObject = this.transformData(this.searchPDFResults)
      searchObject.forEach((search, index) => {
        searchForDocument.push(
          {
            ...search,
            number: index + 1
          }
        )
      })
      // Send redactions to pdf-viewer
      const event = new CustomEvent('setSearches', { detail: searchForDocument })
      window.document.querySelector('#pdfViewerIFrame').contentDocument.dispatchEvent(event)
    },
    transformData(inputData) {
      if (!inputData || !Array.isArray(inputData) || inputData.length === 0) {
        console.error("Invalid input data");
        return [];
      }
      // Extract the actual data from the outer array
      const actualData = inputData[0];
      return actualData.flatMap(page => 
        (page.page_matches || []).map(matchItem => {
          const { match } = matchItem;
          const fullText = match.map(m => m.text).join(" ");
          return {
            comment: page.display_name,
            guid: null, // page.document_id,
            page_number: page.page_number,
            id: page.page_number + "-" + uuidv4(),
            redacted_text: fullText,
            positions: match.map(matchData => ({
              height: (matchData.bottom * 1.33227731) - (matchData.top * 1.33227731),
              text: matchData.text,
              ner: matchData.ner,
              id: matchData.match_id.toString(),
              oldY: 0,
              page_scale: 4 / 3,
              width: (matchData.x1 * 1.33227731) - (matchData.x0 * 1.33227731),
              x: matchData.x0 * 1.33227731,
              y: matchData.top * 1.33227731,
            })),
          };
        })
      );
    },
    sendPDFData(retries = 3) {
      try {
        console.log("Attempting to send PDF data...");
        const iframe = window.document.querySelector('#pdfViewerIFrame');
        if (!iframe) {
          throw new Error("pdfViewerIFrame is null");
        }
        if (!iframe.contentDocument) {
          throw new Error("iframe contentDocument is null");
        }
        const detail = {
          data: this.pdfData,
          pageNumber: this.redactionsToScrollTo.length > 0 ? parseInt(this.redactionsToScrollTo[0].redaction.page_number, 10) : parseInt(this.$route.params.pageNumber, 10),
        };
        const event = new CustomEvent('setPDFData', { detail });
        iframe.contentDocument.dispatchEvent(event);
        console.log("pdf file dispatched to PDFJS");
      } catch (error) {
        console.error("Error in sendPDFData:", error);
        if (retries > 0) {
          console.log(`Retrying sendPDFData, attempts remaining: ${retries - 1}`);
          setTimeout(() => {
            this.sendPDFData(retries - 1);
          }, 1000); // 1 second delay
        } else {
          console.error("Failed to send PDF data after multiple attempts");
        }
      }
    },
    nextPreviousDocument(kind) {
      if(kind === 'next'){
        // Open the next document in the files list
        if(this.openDocumentIndex + 1 < this.openFiles.length){
          if(isValidDocument(this.openFiles[this.openDocumentIndex+1])){
            this.openDocumentIndex += 1;
            this.openDocument = this.openFiles[this.openDocumentIndex]
            this.localShouldBeChecked = this.openFile.document_status.is_finished
            this.openDocument.document_status = this.openFile.document_status
          } else {
            this.$showRegisterToast("Dokumentet du ønsker at åbne er endnu ikke indlæst, eller det har en fejlstatus.", "error", 4000, { 'ProcessGuid': this.processId, documentId: this.documentId})
          }
        } else {
          // Inform user that there are no more documents
          const toast_message = 'Dette dokument er det sidste i listen.';
          this.$showRegisterToast(toast_message, "info", 4000, {})
        }
      } else if(kind === 'previous') {
        // Open the previous document in the files list
        if(this.openDocumentIndex - 1 >= 0){
          if(isValidDocument(this.openFiles[this.openDocumentIndex-1])){
            this.openDocumentIndex -= 1;
            this.openDocument = this.openFiles[this.openDocumentIndex]
            this.localShouldBeChecked = this.openFile.document_status.is_finished
            this.openDocument.document_status = this.openFile.document_status
          } else {
            this.$showRegisterToast("Dokumentet du ønsker at åbne er endnu ikke indlæst, eller det har en fejlstatus.", "error", 4000, { 'ProcessGuid': this.processId, documentId: this.documentId})
           }
        } else {
          // Inform user that there are no more documents
          const toast_message = 'Dette dokument er det første i listen.';
          this.$showRegisterToast(toast_message, "info", 4000, {})
        }
      }
    },
    showLawForRedaction({detail: redaction}) {
      // Show law for redaction
      if (this.openProcess.law_set.name === "INS") {
        const paragraphObj = {
        redactionId: redaction.id,
        documentId: this.openDocument.id,
        processId: this.openProcess.process_id,
        data: {},
      };
      this.$store.dispatch("updateRedaction", paragraphObj);
      } else {
        this.$modal.show(
          LawSelection,
          { 
            element: redaction,
            processId: this.$route.params.processId
          },
          {
            classes: 'overflow-visible',
            height: "700px",
            width: "60%",
            clickToClose: true,
          }
        );
      }
    },
    async ensureDocumentStatusLoaded() {
      if (!this.documentFinishStatus.id) {
        await this.$store.dispatch("loadDocumentTypes");
        await this.$store.dispatch("loadDocumentStatus");
      }
    },
    findIndexById(document_object) {
      try {
        const foundDocument = this.documentsWithAdjustedIndex.find(file => file.id === document_object.id);
        return foundDocument ? foundDocument.adjustedIndex : null;
      } catch {
        return null;
      }
    },
    isExceptedById(document_object) {
      try {
        const foundDocument = this.openFiles.find(file => file.id === document_object.id);
        return foundDocument.document_status.id === this.documentExceptStatus.id
      } catch {
        return false;
      }
    },
    isFinishedById(document_object) {
      try {
        const foundDocument = this.openFiles.find(file => file.id === document_object.id);
        return foundDocument.document_status.id === this.documentFinishStatus.id
      } catch {
        return false;
      }
    },
    async addRedaction({detail}) {
      let lawId = 0;
      if (detail.redactionData) {
        // Replace id in all positions with UUID's
        const updatedPositions = detail.redactionData.positions.map(position => ({...position, id: uuidv4()}));
        detail.redactionData.positions = updatedPositions;
        lawId = detail.isLatestLaw;
        detail = detail.redactionData;
      }
      // Construct necessary data to create the redaction
      const redactionData = {
        ...detail,
        redaction_id: uuidv4(),
        document: {id: this.openFile.id},
        utc: new Date().toISOString(),
        user: { id: this.user.userData.id },
        created_with_new_viewer: true,
      }
      const redactionObj = {
        documentId: this.openFile.document_id,
        processId: this.$route.params.processId,
        data: redactionData
      }

      // Add redaction to DB
      const newRedaction = await this.$store.dispatch("pushRedaction", redactionObj);
      // Updates redaction this makes it show even if no law is selected
      const paragraphObj = {
        redactionId: redactionData.id,
        documentId: this.openFile.document_id,
        processId: this.$route.params.processId,
      };
      await this.$store.dispatch("updateRedaction", paragraphObj);
      if (lawId === 0) {
        // Select law for redaction once it's been created
        if (this.openProcess.law_set.name === "INS") {
            const paragraphObj = {
            redactionId: newRedaction.id,
            documentId: this.openDocument.id,
            processId: this.openProcess.process_id,
            data: {},
          };
          this.$store.dispatch("updateRedaction", paragraphObj);
        } else {
        this.$modal.show(
          LawSelection,
          { 
            element: newRedaction,
            processId: this.$route.params.processId
          },
          {
            classes: 'overflow-visible',
            height: "700px",
            width: "60%",
            clickToClose: true,
          }
        );
        }
      } else {
        this.selectLatestLaw(lawId, newRedaction);
      }
    },
    async selectLatestLaw(lawId, newRedaction) {
        if (newRedaction.length > 0) {
          newRedaction.forEach(async (document) => {
            const paragraphObj = {
              redactionId: newRedaction.id,
              documentId: this.openFile.id,
              processId: this.openProcess.process_id,
              data: {
                law: {
                  id: lawId,
                },
              },
            };
            await this.$store.dispatch("updateRedaction", paragraphObj);
          })
        } else {
          const paragraphObj = {
            redactionId: newRedaction.id,
            documentId: this.openFile.id,
            processId: this.openProcess.process_id,
            data: {
              law: {
                id: lawId,
              },
            },
          };
          await this.$store.dispatch("updateRedaction", paragraphObj);
        }
    },
    // Thomas
    async undoRedaction({ detail }) {
      const redactionObj = {
        id: detail.redactionObj.id,
        documentId: detail.redactionObj.document.document_id,
        processId: this.openFile.process.process_id
      }
      await this.$store.dispatch('removeRedaction', redactionObj)
    },
    openConfirmCloseCaseModal() {
      this.$modal.show(ConfirmCloseCaseForm);
    },
    async reopenProcess() {
      // Find the default process status
      const openProcessStatus = this.openProcess.process_status.find(({is_default_value_for_new}) => is_default_value_for_new)
      if(openProcessStatus) {
        // To update the process status, the id + new process status is needed
        const projectObjectWithOpenProcessStatus = {id: this.openProcess.id, process_status: openProcessStatus}
        // Update the status
        await this.$store.dispatch("updateProcessStatus", projectObjectWithOpenProcessStatus );
        // Reload the process data to reflect the new process status 
        await this.$store.dispatch("loadSpecificProcess", this.$route.params.processId);
      }
    },
    async showLawSelectionModal(){
      if (this.openProcess.law_set.name === "INS") {
        let documentStatusId;
        if (this.openFile.document_status.name === "Undtaget") {
          documentStatusId = this.documentReadyStatus.id;
        } else {
          documentStatusId = this.documentExceptStatus.id;
        }
        const documentChangesObj = {
          id: this.openFile.id,
          processId: this.openFile.process.process_id,
          documentId: this.openFile.document_id,
          data: {
            document_status: {
              id: documentStatusId
            }
          }
        }
        await this.$store.dispatch("setDocumentStatus", documentChangesObj)
        await this.$store.dispatch("loadFilesFromApi", this.$route.params.processId);
      } else {
        const element = {
          law: this.openFile.law
        }
        this.$modal.show(
          LawSelection,
          { 
            element, 
            elementType: 'document', 
            documentId: this.openFile.id, 
            processId: this.openProcess.process_id 
          },
          {
            classes: 'overflow-visible',
            height: "700px",
            width: "60%",
            clickToClose: true,
          }
        );
      }
    },
    goBack() {
      this.$store.dispatch("posthogCapture", {event_name:"BackToProcesses", data: {}});

      this.$router.push({name: "Processes", params: { processId: this.openFile.process.process_id }});
    },
    returnIndex(fileIndex, fileLength) {
      if (fileIndex === 0) {
        return "above";
      } else if (fileIndex === fileLength - 1) {
        return "below";
      } else {
        return "current";
      }
    },
    setSelectedNav(searchFocus) {
      if (searchFocus) {
        this.$store.commit("setDocumentViewerNav", "search");
      }
    },
    getButtonIcon() {
      if (this.openProcess.law_set && this.openProcess.law_set.name === "INS") {
        return "highlighter";  // Change icon to "highlighter" when the condition is met
      } else {
        return "law";  // Default icon
      }
    },
  },
};
</script>

<style>
.w-150{
  width:30rem
}
</style>