<template>
  <div class="p-8 flex items-left flex-col">
    <redaction-button-exit
      class="absolute right-0 top-0 m-2"
      @emit-close="closeModal"
    />
    <h1 class="text-xl">
      Bekræft af du ønsker at afslutte sagen.
    </h1>
    <div class="text-normal mt-2">
      Når du afslutter sagen, skal du huske at journalisere aktindsigten (endeligt afgørelsesbrev samt aktliste og dokumenter) jf. jeres retningslinjer.<br><br> Når sagen er afsluttet forsvinder den efter 7 dage fra din oversigt.
    </div>
    <div class="flex-end items-right mt-5">
      <standard-button
        class="h-9 w-28 ml-4"
        button-title="Annuller"
        button-type="grey"
        button-text="Annuller"
        @click="closeModal()"
      />
      <standard-button
        class="h-9 w-36 ml-4"
        button-title="Afslut sagen"
        button-type="delete"
        button-text="Afslut sagen"
        @click="closeProcess()"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import buttonExit from "@/components/controls/buttonExit.vue";
import StandardButton from "@/components/controls/StandardButton.vue";

export default {
  name: "ConfirmCloseCaseForm",
  components: {
    "redaction-button-exit": buttonExit,
    StandardButton,
  },
  computed: {
    ...mapState([
      "processStatus",
      "openProcess"
    ]),
  },
  methods: {
    closeModal() {
      this.$store.dispatch("posthogCapture", {event_name:"CloseCaseModal", data:{}});
      
      this.$emit("close");
    },
    async closeProcess() {
      // Find the finished process status
      const finishedProcessStatus = this.processStatus.find(({is_finished}) => is_finished)
      if(finishedProcessStatus) {
        // To update the process status, the id + new process status is needed
        const projectObjectWithFinishedProcessStatus = {id: this.openProcess.id, process_status: finishedProcessStatus}
        // Update the status
        await this.$store.dispatch("closeProcess", projectObjectWithFinishedProcessStatus );

        // Reload open process to reflect changes
        await this.$store.dispatch("loadSpecificProcess", this.$route.params.processId);

        // Close modal
        this.closeModal()
      } 
    },
  }
};
</script>
