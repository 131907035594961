import { createStore } from 'vuex';
import router from '../router.js'
import sso from './sso.js'
import user from './user.js'
import notification from './notification.js'
import acadre from './acadre.js'
import dayjs from "dayjs";
import posthog from 'posthog-js'
const _ = require("lodash")

import tooltip_json from '../assets/json/tooltips.json';

const actionsWorker = new Worker('../../../static/actions.worker.js', { type: 'module' });

// Handle incoming messages as commits!
actionsWorker.onmessage = e => {
  console.log('Main script: Received message from worker', e.data);
  store.commit(e.data.type, e.data.payload);
};
  
actionsWorker.onerror = e => {
  console.error('Main script: Worker error', e);
};

const RPAStatusDefault = "Afventer status"

const getDefaultState = () => ({
    strapiUrl: process.env.VUE_APP_SERVER_URL + "/strapi",
    cprSearchUrl: process.env.VUE_APP_SERVER_URL + "/api/search/entities",
    gdprSearchUrl: process.env.VUE_APP_SERVER_URL + "/api/search/entity",
    pageSearchUrl: process.env.VUE_APP_SERVER_URL + "/api/search/query",
    pageSearchPrefix: "page_index-",
    apiUrl: process.env.VUE_APP_SERVER_URL + "/api",
    redactions: [],
    amountOfRedactionsChanges: undefined,
    errors: [],
    // drop down values
    processStatus: [],
    defaultProcessStatus: {},
    documentStatus: [],
    documentStatuses: [],
    documentFinishStatus: {},
    documentExceptStatus: {},
    documentReadyStatus: {},
    entitySearchStatus: {},
    defaultDocumentStatus: {},
    documentTypes: [],
    defaultDocumentType: {},
    // search
    searchTerm: "",
    currentSearchType: "text",
    searchPDFResults: [],
    documentViewerNav: "redactions",
    waitingForSearch:false,
    cancelSearch:false,
    // searchResultsGrouped: {},
    searchResultsGroupedByDocument: {},
    searchResultsCount: 0,
    pageSearchSuggestions: [],
    // search filters
    filtersFileTypes: [],
    filtersFileTypesSelected: [],
    filtersFileStatus: [],
    filtersFileStatusSelected: [],
    groupedDocumentsFilters: false,
    // laws
    lawSets: [],
    defaultLawSet: {},
    offentlighedsloven: [],
    forvaltningsloven: [],
    sundhedsloven: [],
    forvaltningslovenAfslag: [],
    offentlighedslovenAfslag: [],
    offentlighedsloven1985: [],
    egenacces: [],
    miljøoplysningsloven: [],
    lawsLoaded: false,
    // process and files
    processes: [],
    files: [],
    filesCount: 0,
    openProcess: {},
    openFile: {}, // used for editing file info and document viewer
    openFiles: [],
    openRedactedFile: {},
    fileTransfer: "",
    fileTransferStatus: "",
    selectedDocumentGroup: false,
    documentGroupTypes: [],
    uploadedFilesNo: false,
    shouldPollDocumentStatus: true,
    shouldPollDecisionLetter: false,
    noProcessedFiles: 0,
    allowedFileTypes: [
        ".pdf",
        ".PDF",
        ".docx",
        ".DOCX",
        ".doc",
        ".DOC",
        ".xlsx",
        ".XLSX",
        ".xls",
        ".XLS",
        ".csv",
        ".CSV",
        ".ppt",
        ".PPT",
        ".pptx",
        ".PPTX",
        ".jpg",
        ".JPG",
        ".jpeg",
        ".JPEG",
        ".png",
        ".PNG",
        ".msg",
        ".MSG",
        ".eml",
        ".EML",
        ".tif",
        ".TIF",
        ".tiff",
        ".html",
        ".TIFF",
        ".webp",
        ".WEBP",
        ".RTF",
        ".rtf",
    ],
    // stats
    redactionGraphStats: {},
    processGraphStats: {},
    // process finishing
    redactedProcessStatus: {},
    redactedDocuments: [],
    // highlight
    currentToolSelection: "text",
    defaultHighlightColor: "rgba(217, 30, 24, 0.35)",
    // documentation
    documentationToUpload: [],
    openDocumentations: [],
    //tooltips
    tooltips: [],
    users: [],
    collaborateRequest: [],
    //RPA
    RPAMessages: [],
    RPAStatus: RPAStatusDefault,
    effortStatus: [{name: "Aktive", display_name: "Aktiv", display_name_plural:"Aktive"}, {name: "Afsluttede", display_name: "Afsluttet", display_name_plural: "Afsluttede"}],
    pageLoaded: false,
    jobSections: [],
    jobDepartments: [],
    fileData: undefined,
    //Surveys
    surveys: [],
    fileDataLoadingData: {isLoading: false, file: undefined},
    shareUsers: [],
    redactionsToScrollTo: [],
    shouldShowDecisionDocuments: false,
    //NER
    nerData: {},
})

// const store = new Vuex.Store({
const store = createStore({
    modules: {
        sso,
        user,
        notification,
        acadre
    },
    state: getDefaultState(),
    getters: {
        getTooltips: (state) => (field_name) => {
            const tooltip = state.tooltips.find(tooltip => tooltip.field_name === field_name) || {}
            return tooltip.description || "";
        },
        getSurveyByGuid: (state) => (guid) => {
            return state.surveys.find(survey => survey.guid === guid)           
        },
        findLawById: (state) => (id) => {
            // Antager, at alle love er samlet i et enkelt array
            const allLaws = [
                ...state.offentlighedsloven, 
                ...state.forvaltningsloven, 
                ...state.sundhedsloven, 
                ...state.miljøoplysningsloven, 
                ...state.forvaltningslovenAfslag, 
                ...state.egenacces, 
                ...state.offentlighedslovenAfslag,
                ...state.offentlighedsloven1985];
            return allLaws.find(law => law.id === id);
        },
    },
    mutations: {
        authenticate(state, isLoggedIn) {
            state.isAuthenticated = isLoggedIn;
        },
        PUSH_FILE(state, file) {
            state.files.push(file);
        },
        setSelectedDocumentGroup(state, selectedDocumentGroup) {
            state.selectedDocumentGroup = selectedDocumentGroup;
            if(selectedDocumentGroup === "document_status") {
                state.documentGroupTypes = state.documentStatus
            } else if(selectedDocumentGroup === "document_type") {
                state.documentGroupTypes = state.documentTypes
            } else if(selectedDocumentGroup === "effort_status") {
                state.documentGroupTypes = state.effortStatus;
            }
        },
        removeDocumentation(state, documentationId) {
            state.openDocumentations = state.openDocumentations.filter(d => d.id !== documentationId)
        },
        setDocumentations(state, documentations) {
            state.openDocumentations = documentations
        },
        setUploadedFilesNo(state, uploadedFilesNo) {
            state.uploadedFilesNo = uploadedFilesNo;
        },
        setDocumentStatusPoll(state, shouldPoll) {
          state.shouldPollDocumentStatus = shouldPoll;
        },
        setShouldPollForDecisionLetter(state, shouldPoll) {
            state.shouldPollDecisionLetter = shouldPoll
        },
        setRedactedProcessStatus(state, redactedProcessStatus) {
            state.redactedProcessStatus = redactedProcessStatus;
        },
        setRedactedDocuments(state, redactedDocuments) {
            state.redactedDocuments = redactedDocuments;
        },
        setDocumentViewerNav(state, documentViewerNav) {
            state.documentViewerNav = documentViewerNav;
        },
        removeDocumentationToUpload(state, docIndex) {
            state.documentationToUpload.splice(docIndex, 1)
        },
        clearDocumentationToUpload(state) {
            state.documentationToUpload = [];
        },
        pushDocumentationToUpload(state, documentationToUpload) {
            state.documentationToUpload.push(documentationToUpload)
        },
        clearFilters(state) {
            state.filtersFileTypesSelected = [];
            state.filtersFileStatus = [];
            state.filters = false;
        },
        setFilters(state, filters) {
            state.filtersFileTypes = filters.fileTypes;
            state.filtersFileStatus = filters.fileStatus;
        },
        setFiltersDocTypesSelected(state, filters) {
            state.filtersFileTypesSelected = filters;
        },
        setFiltersDocStatusSelected(state, filters) {
            state.filtersFileStatusSelected = filters;
        },
        setRedactedFile(state, redactedFile) {
            state.openRedactedFile = redactedFile;
        },
        setToolSelection(state, tool) {
            state.currentToolSelection = tool;
        },
        setFileTransferStatus(state, status) {
            state.fileTransferStatus = status;
        },
        pushError(state, error) {
            state.errors.push(error)
        },
        setRedactions(state, redactions) {
            state.redactions = redactions;
        },
        setAmountOfRedactionChanges(state, amount) {
            state.amountOfRedactionsChanges = amount;
        },
        setGroupedDocumentsFilters(state, groupedDocumentsFilters) {
            state.groupedDocumentsFilters = groupedDocumentsFilters;
        },
        setLawSets(state, lawSets) {
            state.lawSets = lawSets.map(lawSet => ({ ...lawSet, $isDisabled: lawSet.is_disabled }));
            state.defaultLawSet = lawSets.find(lawSet => lawSet.is_default_value_for_new === true) || {}
        },
        setLaws(state, laws) {
            const OFL = laws.filter((l) => { return l.name === "OFL" })
            const FVL = laws.filter((l) => { return l.name === "FVL" })
            const SUL = laws.filter((l) => { return l.name === "SUL" })
            const MOL = laws.filter((l) => { return l.name === "MOL" })
            const EGA = laws.filter((l) => { return l.name === "EGA" })
            const FVLA = laws.filter((l) => { return l.name === "FVLA" })
            const OFLA = laws.filter((l) => { return l.name === "OFLA" })
            const OFL1985 = laws.filter((l) => { return l.name === "OFL 1985" })
            state.offentlighedsloven = OFL;
            state.forvaltningsloven = FVL;
            state.sundhedsloven = SUL;
            state.miljøoplysningsloven = MOL;
            state.egenacces = EGA;
            state.forvaltningslovenAfslag = FVLA;
            state.offentlighedslovenAfslag = OFLA;
            state.offentlighedsloven1985 = OFL1985;
            state.lawsLoaded = true;
        },
        setJobSections(state, jobSections) {
            state.jobSections = jobSections
        },
        setJobDepartments(state, jobDepartments) {
            state.jobDepartments = jobDepartments
        },
        setSurveys(state, surveys) {
            state.surveys = surveys;
        },
        setShouldShowDecisionDocuments(state, shouldShow) {
            state.shouldShowDecisionDocuments = shouldShow;
        },
        updateProcess(state, updatedProcess) {
            state.processes = state.processes.map(process => {
                return process.id === updatedProcess.id
                    ? updatedProcess
                    : process;
            })
        },
        updateProcessDocCountRemove(state) {
            if(state.openProcess.document_count > 0) {
                state.openProcess.document_count = state.openProcess.document_count - 1
            }
        },
        updateProcessDocCountAdd(state) {
            if(state.openProcess.document_count > 0) {
                state.openProcess.document_count = state.openProcess.document_count + 1
            }
        },
        updateFile(state, updatedFile) {
            state.files = state.files.map(o => {
                return o.id === updatedFile.id
                    ? updatedFile
                    : o;
            })
            state.openFiles = state.openFiles.map(o => {
                return o.id === updatedFile.id
                    ? updatedFile
                    : o;
            })

        },
        updateListFiles(state, updatedFiles) {
            updatedFiles.forEach(updatedFile => {
                state.files = state.files.map(o => {
                    return o.id === updatedFile.id ? updatedFile : o;
                });
                state.openFiles = state.openFiles.map(o => {
                    return o.id === updatedFile.id ? updatedFile : o;
                });
            });
        },
        updateRedaction(state, updatedRedaction) {
            state.redactions = state.redactions.map(o => {
                return o.id === updatedRedaction.id
                    ? updatedRedaction
                    : o;
            })
        },
        updateCollaborateRequest(state, collaborateRequest) {
            state.collaborateRequest = state.collaborateRequest.map(o => {
                return o.id === collaborateRequest.id
                    ? collaborateRequest
                    : o;
            })
        },
        removeDocument(state, removeDocument) {
            //dispatch("posthogCapture", {event_name:"RemoveDocument", data:{}})
            state.files = state.files.filter(d => d.id !== removeDocument.id)
            state.openFiles = state.openFiles.filter(d => d.id !== removeDocument.id)
        },
        removeRedaction(state, removedRedaction) {
            //dispatch("posthogCapture", {event_name:"RemoveRedaction", data:{}})
            state.redactions = state.redactions.filter(r => r.id !== removedRedaction.id)
        },
        pushProcess(state, process) {
            state.processes.unshift(process);
        },
        pushRedaction(state, redaction) {
            state.redactions.push(redaction);
        },
        setSpecificProcess(state, process) {
            process = Array.isArray(process) ? process : [process];
            state.openProcess = process[0];
            // Find the current index for the given process and replace with the new process
            const indexOfExistingProcess = state.processes.findIndex(({id}) => id === process[0].id)
            state.processes[indexOfExistingProcess] = process[0]
        },
        setOpenProcess(state, openProcess) {
            state.openProcess = openProcess;
        },
        setSpecificFile(state, file) {
            if (file && file[0]) {
                state.openFile = file[0];
            } else if (file === undefined) {
                state.openFile = {};
            } else {
                state.openFile = file;
            }
        },
        setSpecificFiles(state, files) {
            state.openFile = files[0]
            state.openFiles = files
        },
        setDocumentStatus(state, documentStatus) {
            state.documentStatus = documentStatus.filter(status => !status.is_processing);
            const defaultDocumentStatus = _.filter(documentStatus, { is_default_value_for_new: true })
            const documentFinishStatus = _.filter(documentStatus, { is_finished: true })
            const documentExceptStatus = _.filter(documentStatus, { is_excepted_status: true })
            const documentReadyStatus = _.filter(documentStatus, { is_default_value_for_ready: true})
            const entitySearchStatus = documentStatus.filter(docStatus => {
                return docStatus.guid === "df780ce7-e527-40d6-95b4-d00bcb2ce0e0"
            });
            state.defaultDocumentStatus = defaultDocumentStatus[0] || {};
            state.documentExceptStatus = documentExceptStatus[0] || {};
            state.documentFinishStatus = documentFinishStatus[0] || {};
            state.documentReadyStatus = documentReadyStatus[0] || {};
            state.entitySearchStatus = entitySearchStatus[0] || {};
        },
        setDocumentStatuses(state, documentStatuses) {
            state.documentStatuses = documentStatuses
        },
        setDocumentTypes(state, documentTypes) {
            state.documentTypes = documentTypes;
            const defaultDocumentType = _.filter(documentTypes, { is_default_value_for_new: true });
            state.defaultDocumentType = defaultDocumentType[0] || {};
        },
        setProcessStatus(state, processStatus) {
            state.processStatus = processStatus;
            const defaultProcessStatus = _.filter(processStatus, { is_default_value_for_new: true });
            state.defaultProcessStatus = defaultProcessStatus[0] || {};
        },
        setProcesses(state, processes) {
            state.processes = processes.filter(({soft_deleted}) => !soft_deleted);

            // set stats for dashboard
            const processesGroupedByMonth = _.groupBy(processes, (p) => {
                return dayjs(p.created_at).format("MMM YYYY")
            })
            let processGraphStats = {
                type: "bar",
                data: {
                    labels: Object.keys(processesGroupedByMonth),
                    datasets: [
                        {
                            label: "Sager",
                            backgroundColor: "rgba(226,231,238)",
                            strokeColor: "#63b3ed",
                            pointColor: "#fff",
                            pointStrokeColor: "#63b3ed",
                            data: [],
                        },
                    ],
                },
                options: {
                    legend: {
                        display: false,
                    },
                    scales: {
                        x: {
                            gridLines: {
                                display: false,
                            },
                        },
                        y: {
                            gridLines: {
                                display: true,
                            },
                            ticks: {
                                stepSize: 1,
                                display: true,
                                beginAtZero: true,
                            },
                        }
                    },
                },
            }
            for (const month_label in processesGroupedByMonth) {
                if (Object.hasOwnProperty.call(processesGroupedByMonth, month_label)) {
                    const ps = processesGroupedByMonth[month_label];
                    processGraphStats.data.datasets[0].data.push(ps.length)
                }
            }
            state.processGraphStats = processGraphStats
        },
        setFilesCount(state, filesCount) {
            state.filesCount = filesCount;
        },
        // setShownFiles(state, files) {
        //     state.shownFiles = files;
        // },
        setFiles(state, files) {
            state.files = files;
            // TODO: reconsider how openFiles should work
            state.openFiles = files;

            // Get the updated open file, if there is an open file
            const updatedOpenFile =  files.find(({id}) => state.openFile.id === id)
            if(updatedOpenFile){
                state.openFile = updatedOpenFile
            }
            state.filesCount = files.length;
        },
        // clearOpenFileSearch(state) {
        //     state.openFileSearchResults = []
        // },
        setPageSearchResults(state, pageSearchResults) {
            if (pageSearchResults.query_result.length > 0) {
                pageSearchResults.query_result.sort((docA,docB) => {
                    return docA[0].internal_id - docB[0].internal_id
                });
                pageSearchResults.query_result.forEach(docu => {
                    docu.sort((a,b) => {
                            return a.page_number - b.page_number 
                        });
                    docu.forEach(page => {
                        page.page_matches.sort((a,b) => {
                            return a.match[0].page_word_id - b.match[0].page_word_id
                        })
                    });
                });
            }
            // state.searchResultsGrouped = _.groupBy(pageSearchResults, "_index");
            // state.pageSearchResultsGroupedByDocument = _.groupBy(pageSearchResults, "_id");
            state.searchResultsCount = pageSearchResults.matches_found;
            state.searchPDFResults = pageSearchResults.query_result;
        },
        setWaitingForSearch(state, waitingForSearch) {
            state.waitingForSearch = waitingForSearch;
        },
        setCancelSearch(state, cancelSearch) {
            state.cancelSearch = cancelSearch
        },
        setPageSearchCount(state, searchResultsCount) {
            state.searchResultsCount = searchResultsCount;
        },
        setPageSearchSuggestions(state, pageSearchSuggestions) {
            state.pageSearchSuggestions = pageSearchSuggestions.filter(s => s.options.length > 0);
        },
        setSearchTerm(state, searchTerm) {
            state.searchTerm = searchTerm;
        },
        setCurrentSearchType(state, currentSearchType) {
            state.currentSearchType = currentSearchType;
        },
        setTooltips(state, tooltips) {
            state.tooltips = tooltips;
        },
        setShareUsers(state, {data}) {
          if (Object.keys(data).length === 0) {
            state.shareUsers = [];
          } else {
            state.shareUsers = data;
          }
        },
        setRedactionGraphStats(state, redactionGraphStats) {
            const graphFormat = {
                type: "bar",
                options: {
                    legend: {
                        display: true,
                    },
                    scales: {
                        x: {
                            stacked: true,
                            gridLines: {
                                display: false,
                            },
                        },
                        y: {
                            stacked: true,
                            gridLines: {
                                display: true,
                            },
                            ticks: {
                                stepSize: 1,
                                display: true,
                                beginAtZero: true,
                            },
                        }
                    },
                },
            }
            // use state.backgroundColors
            let groupedRedactions = _(redactionGraphStats)
                .filter(r => _.has(r, "law.paragraph_label"))
                .groupBy("law.paragraph_label")
                .value()

            let datasets = [
                {
                    label: "Lovparagraffer",
                    backgroundColor: "rgba(226,231,238)",
                    strokeColor: "#63b3ed",
                    pointColor: "#fff",
                    pointStrokeColor: "#63b3ed",
                    data: [],
                }
            ]
            for (const paragraph_label in groupedRedactions) {
                if (Object.hasOwnProperty.call(groupedRedactions, paragraph_label)) {
                    const redactions = groupedRedactions[paragraph_label];
                    datasets[0].data.push(redactions.length)
                }
            }
            graphFormat.data = {
                labels: Object.keys(groupedRedactions),
                datasets: datasets
            },
            state.redactionGraphStats = graphFormat;
        },
        setNumberOfProcessedFiles(state, noProcessedFiles) {
            state.noProcessedFiles = noProcessedFiles;
        },
        setRPAMessages(state, RPAMessages) {
            state.RPAMessages = RPAMessages;
        },
        setRPAStatus(state, RPAStatus) {
            state.RPAStatus = RPAStatus
        },
        deleteRPAMessage(state, messageId) {
            state.RPAMessages = state.RPAMessages.filter(m => m.id !== messageId)
        },
        setPageLoaded(state, loaded) {
            state.pageLoaded = loaded
        },
        setFileData(state, data){
            const isCurrentOpenFile = state.openFile.file.id === data.file.id
            if(isCurrentOpenFile && data && data.response && data.response.data){
                state.fileData = JSON.parse(data.response.data)
            }
        },
        setFileDataLoadingData(state, data) {
            state.fileDataLoadingData = data
        },
        setRedactionsToScrollTo(state, redactions){
            state.redactionsToScrollTo = redactions
        },
        addRedactionToScrollTo(state, redaction) {

            const newElement = {redaction, retries: 0}
            state.redactionsToScrollTo = [newElement];
        },
        setNerData(state, nerData) {
            state.nerData = nerData
        },
        resetFileData(state) {
            state.fileData = undefined
        },
        resetState(state) {
            // Reset state to its default state
            Object.assign(state, getDefaultState())

            // Reset posthog instance
            posthog.reset()
        }
    },
    actions: {
        
        // WEBWORKERS
        async processFiles({ commit, state, rootState }, fileObj) {
            const actionsWorker = new Worker('../../../static/actions.worker.js', { type: 'module' });
            
            actionsWorker.onmessage = e => {
              console.log('Main script: Received message from worker', e.data);
              store.commit(e.data.type, e.data.payload);
            };
          
            actionsWorker.onerror = e => {
              console.error('Main script: Worker error', e);
            };
          
            const userToken = localStorage.getItem("aktio-ai-user-token") || false;
            console.log('index.js:processFiles() - Processing file');
          
            if (userToken) {
              const fileAndToken = {
                ...fileObj,
                userToken: userToken,
                userData: JSON.parse(JSON.stringify(rootState.user.userData)),
                defaultDocumentStatus: JSON.parse(JSON.stringify(state.defaultDocumentStatus)),
                defaultDocumentType: JSON.parse(JSON.stringify(state.defaultDocumentType)),
              };
          
              try {
                console.log('index.js:processFiles() - Sending file to worker');
                actionsWorker.postMessage(fileAndToken);
                commit("setFileTransferStatus", "Done");
                console.log('index.js:processFiles() - File successfully sent to worker');
              } catch (error) {
                console.error('index.js:processFiles() - Error sending file to worker:', error);
              }
            } else {
              console.error('index.js:processFiles() - User token is missing. Cannot process file:', fileObj);
            }
        },
        async pushRPAEmail({ dispatch, state }, emailObject) {
            let remoteResponse
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "POST",
                    url: state.apiUrl + "/messaging/send_rpa_email",
                    data: emailObject
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            return remoteResponse
        },
        async reReadExistingFile({dispatch, state}, docObj) {
            let remoteResponse
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "PUT",
                    url: state.apiUrl + "/preprocessing/ocrmypdf",
                    data: docObj
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            // Reload files 
            dispatch("loadFilesFromApi", docObj.process.process_id);

            return remoteResponse
        },
        async setDocumentLaw({state, dispatch}, documentLawObj) {
            try {
                await dispatch("apiRequest", {
                    method: "Put",
                    url: state.strapiUrl + "/documents/" + documentLawObj.documentId + "?process.process_id=" + documentLawObj.processId + "&only_documents=true",
                    data: documentLawObj.data
                })
                await dispatch("updateRestorationStatus", {
                    processId: documentLawObj.processId,
                    status: "ChangesDetected"
                });
            } catch (error) {
                dispatch("pushError", error)
            }
        },
        async setDocumentStatus({state, dispatch}, documentStatusObj) {
            
            try {
                await dispatch("apiRequest", {
                    method: "Put",
                    url: state.strapiUrl + "/documents/" + documentStatusObj.id + "?process.process_id=" + documentStatusObj.processId + "&only_documents=true",
                    data: documentStatusObj.data
                })
                await dispatch("updateRestorationStatus", {
                    processId: documentLawObj.processId,
                    status: "ChangesDetected"
                });
                return 200
            } catch (error) {
                dispatch("pushError", error)
                return 500
            }
        },
        async setNavigationParams({ dispatch }, navParams) {
            let routeParams = {};
            // router.currentRoute.params.processId virker ikke til man kan det mere i vue 3 men der er _rawValue og _value under currentRoute
            const route = router.currentRoute._value.params
            // if process change
            if (route.processId !== navParams.processId) {
                routeParams.processId = navParams.processId;
            }
            // if doc change
            if (route.documentId !== navParams.documentId) {
                routeParams.documentId = navParams.documentId;
            }
            // if page change
            if (Number(route.pageNumber) !== Number(navParams.pageNumber)) {
                routeParams.pageNumber = navParams.pageNumber;
            }
            // if random number is present
            if (Number(navParams.randomNum)) {
                routeParams.randomNum = navParams.randomNum;
            }
            if (routeParams.processId || routeParams.documentId || routeParams.pageNumber) {
                try {
                    router.replace({ params: routeParams });
                } catch (error) {
                    dispatch("pushError", error)
                }
            }
            return routeParams;
        },
        setFiltersDocTypesSelected({ commit }, filters) {
            commit("setFiltersDocTypesSelected", filters)
        },
        setFiltersDocStatusSelected({ commit }, filters) {
            commit("setFiltersDocStatusSelected", filters)
        },
        setFilters({ commit, state }) {
            // filter by flags, so the array is only looped once
            let fileTypeFlag = {};
            let fileStatusFlag = {};
            let fileTypesMapped = [];
            let fileStatusMapped = [];

            for (let index = 0; index < state.openFiles.length; index++) {
                const element = state.openFiles[index];
                if (element.document_type && !fileTypeFlag[element.document_type.name]) {
                    fileTypeFlag[element.document_type.name] = true;
                    fileTypesMapped.push(element.document_type.name);
                }
                if (element.document_status && !fileStatusFlag[element.document_status.name]) {
                    fileStatusFlag[element.document_status.name] = true;
                    fileStatusMapped.push(element.document_status.name);
                }
            }
            const filterObj = {
                fileStatus: fileStatusMapped,
                fileTypes: fileTypesMapped
            }
            commit("setFilters", filterObj)
        },
        async setSearchTerm({ commit, dispatch, state }, searchObj) {
            commit("setSearchTerm", searchObj.searchTerm)
            if (searchObj.searchTerm && searchObj.searchTerm.length > 0) {
                await dispatch("getSearchResults", {
                    ...searchObj,
                })
            }
        },
        async deleteRequestSubject({ dispatch, state }, requestObjId) {
            dispatch("posthogCapture", {event_name:"DeleteRequestSubject", data:{}})
            let remoteResponse;
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "Delete",
                    url: state.strapiUrl + "/request-subjects/" + requestObjId,
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            return await remoteResponse;
        },
        async updateRequestSubject({ dispatch, state }, requestObj) { //@TODO - CHECK
            let remoteResponse;
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "Put",
                    url: state.strapiUrl + "/request-subjects/" + requestObj.id,
                    data: requestObj
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            return await remoteResponse;
        },
        async pushRequestSubject({ dispatch, state }, requestObj) {
            let remoteResponse;
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "Post",
                    url: state.strapiUrl + "/request-subjects",
                    data: requestObj
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            return await remoteResponse;
        },
        async updateProcess({ commit, dispatch, state }, processObj) {
            let remoteResponse = [];
            let countResponse;
            try {
                remoteResponse.push(await dispatch("apiRequest", {
                    method: "Put",
                    url: state.strapiUrl + "/processes/" + processObj.id,
                    data: processObj
                }));
            } catch (error) {
                dispatch("pushError", error)
            }
            Promise.all(remoteResponse.map(async process => {
                try {
                    countResponse = await dispatch("apiRequest", {
                        method: "Get",
                        url: state.strapiUrl + "/documents/count?process.process_id=" + processObj.processId
                    })
                } catch (error) {
                    dispatch("pushError", error)
                }
                process.document_count = countResponse
            })).then(() => {
                commit("setSpecificProcess", remoteResponse[0]);
                return remoteResponse[0];
            })
            return remoteResponse[0];
        },
        async closeProcess({ commit, dispatch, state }, processObj) {
            dispatch("posthogCapture", {event_name:"CloseProcess", data:{}})
            let remoteResponse;
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "Put",
                    url: state.strapiUrl + "/processes/" + processObj.id,
                    data: {
                        process_status: processObj.process_status,
                        process_finished: new Date().toISOString()
                    }
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            commit("closeProcess", await remoteResponse);
            return remoteResponse;
        },
        async updateProcessStatus({ commit, dispatch, state }, processObj) {
            let remoteResponse = []
            let countResponse;
            try {
                remoteResponse.push(await dispatch("apiRequest", {
                    method: "Put",
                    url: state.strapiUrl + "/processes/" + processObj.id,
                    data: {
                        process_status: processObj.process_status
                    }
                }))
            } catch (error) {
                dispatch("pushError", error)
            }
            Promise.all(remoteResponse.map(async process => {
                try {
                    countResponse = await dispatch("apiRequest", {
                        method: "Get",
                        url: state.strapiUrl + "/documents/count?process.process_id=" + processObj.processId
                    })
                } catch (error) {
                    dispatch("pushError", error)
                }
                process.document_count = countResponse
            })).then(() => {
                commit("setSpecificProcess", remoteResponse[0]);
                return remoteResponse[0];
            })
        },
        async updateFile({ commit, dispatch, state }, fileObj) {
            const fileProcessId = fileObj.processId ?? fileObj.process.process_id
            dispatch("posthogCapture", {event_name:"UpdateFile", data:{ processId: fileProcessId }})
            let remoteResponse;
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "Put",
                    url: state.strapiUrl + "/documents/" + fileObj.id + "?process.process_id=" + fileProcessId + "&only_documents=true",
                    data: fileObj
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            commit("updateFile", await remoteResponse); // @TODO - is_processing
            return remoteResponse;
        },
        async deleteProcessUser({ dispatch, state }, obj) {
            let remoteResponse;
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "Put",
                    url: state.strapiUrl + "/processes/" + obj.id,
                    data: {
                        users: obj.users
                    }
                })
                if(remoteResponse.statusCode && remoteResponse.statusCode !== 200){
                    return false
                } else {
                    dispatch("loadProcessesForUser")
                    return true
                }
            } catch (error) {
                dispatch("pushError", error)
                return false
            }
        },
        async updateProcessUser({ commit, dispatch, state }, processObj) {
            let remoteResponse = []; 
            let countResponse;
            try {
                remoteResponse.push(await dispatch("apiRequest", {
                    method: "Put",
                    url: state.strapiUrl + "/processes/" + processObj.id,
                    data: {
                        users: processObj.users
                    }
                }))
            } catch (error) {
                dispatch("pushError", error)
            }
            Promise.all(remoteResponse.map(async process => {
                try {
                    countResponse = await dispatch("apiRequest", {
                        method: "Get",
                        url: state.strapiUrl + "/documents/count?process.process_id=" + processObj.processId
                    })
                } catch (error) {
                    dispatch("pushError", error)
                }
                process.document_count = countResponse
            })).then(() => {
                commit("setSpecificProcess", remoteResponse[0]);
                return remoteResponse[0];
            })
            return remoteResponse[0];
        },
        async createCaseWithAcadre({ dispatch, state }, obj) {
            try {
                await dispatch("apiRequest", {
                    method: "Post",
                    url: state.apiUrl + "/acadre/processCase",
                    data: {
                        acadre_id: obj.acadre_case_id,
                        process: {
                            id: obj.internalProcessId,
                            process_id: obj.processId
                        },
                        text_annotate_category_guid: obj.annotationCategory.guid
                    }
                })
            } catch (error) {
                dispatch("pushError", error)
            }
        },
        async pushCollaborateRequest({ commit, dispatch, state }, collaborateRequest) {
            let remoteResponse;
            const receiver_email = collaborateRequest.assigned_user[0].email;
            const receiver_name = collaborateRequest.assigned_user[0].first_name + " " + collaborateRequest.assigned_user[0].last_name;
            try {
                await dispatch("apiRequest", {
                    method: "Post",
                    url: state.apiUrl + "/messaging/share_case_email",
                    data: {
                        receiver_email: receiver_email,
                        receiver_name: receiver_name,
                        subject: "En aktindsigtssag er blevet delt med dig",
                        message: collaborateRequest.message,
                        process_id: collaborateRequest.process.process_id,
                        request_user_data: collaborateRequest.request_user_data,

                    }
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "Post",
                    url: state.strapiUrl + "/collaborate-requests/",
                    data: {
                        process: collaborateRequest.process,
                        assigned_user: collaborateRequest.assigned_user,
                        message: collaborateRequest.message
                    }
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            commit("updateCollaborateRequest", await remoteResponse);
            return remoteResponse;
        },
        async setFileOrder({ commit, dispatch, state }, sortObj) {
            let remoteResponse
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "Put",
                    url: state.strapiUrl + "/documents/" + sortObj.internalDocumentId + "?process.process_id=" + sortObj.processId + "&only_documents=true",
                    data: sortObj.data
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            commit("updateFile", await remoteResponse);
        },
        async batchUpdateFileOrder({ commit, dispatch, state }, sortObjs) {
            try {
              const promises = sortObjs.map(sortObj => 
                dispatch("apiRequest", {
                  method: "Put",
                  url: `${state.strapiUrl}/documents/${sortObj.internalDocumentId}?process.process_id=${sortObj.processId}&only_documents=true`,
                  data: sortObj.data,
                })
              );
          
              // Wait for all requests to complete
              const responses = await Promise.all(promises);
          
              // Commit the mutation with all updated files
              commit("updateListFiles", responses);
            } catch (error) {
              dispatch("pushError", error);
            }
        },
        async getRedactionGraphStats({ commit, dispatch, state }) {
            let remoteResponse
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "Get",
                    url: state.strapiUrl + "/redactions?_limit=-1"
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            commit("setRedactionGraphStats", await remoteResponse);
        },
        async getNumberOfProcessedFiles({ commit, dispatch, state }, user_id) {
            let remoteResponse
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "Get",
                    url: state.strapiUrl + "/documents/count?process.users.id=" + user_id
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            if (remoteResponse && Number.isInteger(remoteResponse)) {
                commit("setNumberOfProcessedFiles", await remoteResponse);
            } else {
                commit("setNumberOfProcessedFiles", 0);
            }
        },
        async pollUploadedFiles({ commit, dispatch, state }, uploadId) {
            let remoteResponse
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "Get",
                    url: state.strapiUrl + "/documents/count?upload_id=" + uploadId
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            if (remoteResponse && Number.isInteger(remoteResponse)) {
                commit("setUploadedFilesNo", await remoteResponse);
            } else {
                commit("setUploadedFilesNo", 0);
            }
        },
        async getDocumentStatuses({ commit, dispatch, state}) {
            let remoteResponse
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "Get",
                    url: state.strapiUrl + "/document-statuses",
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            commit("setDocumentStatuses", await remoteResponse)
        },
        async pollDocumentStatuses({ commit, dispatch, state}, docObj) {
            let remoteResponse
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "Get",
                    url: state.strapiUrl + "/documents?_limit=-1&document_status.is_processing=true" + "&process.process_id=" + docObj.processId + "&only_documents=true",
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            // Once all new files have been processed, stop polling for new updates
            if (remoteResponse && remoteResponse.length === 0 && state.shouldPollDocumentStatus) {
                if (docObj.pollCount && docObj.pollCount <= 3) {
                    dispatch("loadFilesFromApi", state.openProcess.process_id);
                } else {
                    console.log("stopping document status polling - nothing to poll");
                    commit("setDocumentStatusPoll", false);
                }
            } else {
                dispatch("loadFilesFromApi", state.openProcess.process_id);
            }
        },
        async getRedactedProcess({ dispatch, state }, processObj) {
            let remoteResponse
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "Get",
                    url: state.strapiUrl + "/redacted-processes?process.process_id=" + processObj.process.process_id
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            return await remoteResponse;
        },
        async pollRedactedProcessStatus({ commit, dispatch, state }) {
            let remoteResponse
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "Get",
                    url: state.strapiUrl + "/redacted-process-statuses?process.process_id=" + state.openProcess.process_id
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            const redactedProcessStatus = await remoteResponse;
            if (redactedProcessStatus && redactedProcessStatus[0]) {
                commit("setRedactedProcessStatus", await redactedProcessStatus[0]);
            }
        },
        async getRPAStatus({commit, dispatch, state}, processId) {
            let rpaStatusResponse;
            try {
                rpaStatusResponse = await dispatch("apiRequest", {
                    method: "Get",
                    url: `${state.strapiUrl}/integration-messages?_limit=1&_sort=created_at:DESC&integration_name=rpa&process_id=${processId}`
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            const RPAStatusObject = await rpaStatusResponse
            // If there is no status, revert to the default status message
            if(RPAStatusObject.length === 0) {
                commit("setRPAStatus", RPAStatusDefault)
            } else {
                commit("setRPAStatus", RPAStatusObject[0].message.message)
            }
        },
        async getRPAMessages({ commit, dispatch, state}, processId) {
            let messageResponse;
            try {
                messageResponse = await dispatch("apiRequest", {
                    method: "Get",
                    url: state.strapiUrl + "/errors?process.process_id=" + processId
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            commit("setRPAMessages", await messageResponse)
        },
        async deleteRPAMessage({ commit, dispatch, state}, obj) {
            let messageResponse;
            try {
                messageResponse = await dispatch("apiRequest", {
                    method: "Delete",
                    url: state.strapiUrl + "/errors/" + obj.messageId + "?process.process_id=" + obj.processId
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            commit("deleteRPAMessage", messageResponse.id)
        },
        async getEntitySearchResults({ commit, dispatch, state }, searchObj) {
            let searchResponse;
            try {
                searchResponse = await dispatch("apiRequest", {
                    method: "Post",
                    url: state.gdprSearchUrl,
                    data: {
                        "process_id": state.openProcess.process_id,
                        "document_id": searchObj.documentId,
                        "entity": searchObj.entity,
                    }
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            const searchContent = searchResponse.content
            if (searchContent && !state.cancelSearch) {
                commit("setPageSearchResults", searchContent)
                commit("setWaitingForSearch", false);
                // set search suggestions (Currently not implemented after ES Replacement)
                if (searchContent.suggest) {
                    commit("setPageSearchSuggestions", await searchContent.suggest.defaultSuggestion)
                }
            } else {
                const emptyResult = {query_result: []}
                commit("setPageSearchResults", emptyResult)
                commit("setWaitingForSearch", false);
            }
        },
        async getSearchResults({ commit, dispatch, state }, searchObj) {
            let pageSearchResponse;
            try {
                pageSearchResponse = await dispatch("apiRequest", {
                    method: "Post",
                    url: state.pageSearchUrl,
                    data: {
                        "process_id": searchObj.processId,
                        "document_id": searchObj.documentId,
                        "query": searchObj.searchTerm,
                    }
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            const searchContent = pageSearchResponse.content
            if (searchContent) {
                commit("setPageSearchResults", searchContent)
                commit("setWaitingForSearch", false);
                // set search suggestions (Currently not implemented after ES Replacement)
                if (searchContent.suggest) {
                    commit("setPageSearchSuggestions", await searchContent.suggest.defaultSuggestion)
                }
            } else {
                const emptyResult = {query_result: []}
                commit("setPageSearchResults", emptyResult)
                commit("setWaitingForSearch", false);
            }
        },
        pushError({ commit }, error) {
            commit("pushError", error);
        },
        setToolSelection({ commit }, tool) {
            commit("setToolSelection", tool);
        },
        async loadLaws({ commit, dispatch, state }) {
            let lawResponse, lawSetResponse;
            try {
                lawSetResponse = await dispatch("apiRequest", {
                    method: "Get",
                    url: state.strapiUrl + "/law-sets?_sort=sort_order:ASC&is_disabled=false",
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            try {
                lawResponse = await dispatch("apiRequest", {
                    method: "Get",
                    url: state.strapiUrl + "/laws?_sort=sort_order:ASC&is_disabled=false",
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            commit("setLawSets", await lawSetResponse)
            commit("setLaws", await lawResponse)
        },
        async loadJobSections({ commit, dispatch, state}) {
            let jobSectionResponse;
            try {
                jobSectionResponse = await dispatch("apiRequest", {
                    method: "Get",
                    url: state.strapiUrl + "/sections?_sort=sort_order:ASC",
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            commit("setJobSections", await jobSectionResponse)
        },
        async loadJobDepartments({ commit, dispatch, state }) {
            let jobDepartmentsResponse;
            try {
                jobDepartmentsResponse = await dispatch("apiRequest", {
                    method: "Get",
                    url: state.strapiUrl + "/departments",
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            commit("setJobDepartments", await jobDepartmentsResponse)
        },
        async updateRedaction({ commit, dispatch, state }, redactionObj) {
            let remoteResponse;
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "Put",
                    url: `${state.strapiUrl}/redactions/${redactionObj.redactionId}?document.document_id=${redactionObj.documentId}&document.process.process_id=${redactionObj.processId}`,
                    data: redactionObj.data
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            commit("updateRedaction", await remoteResponse)

            // Update restorationStatus enum in process collection
            await dispatch("updateRestorationStatus", {
                processId: redactionObj.processId,
                status: "ChangesDetected"
            });
        },
        async removeDocument({ commit, dispatch, state }, documentObj) {
            let remoteResponse;
            const redactions = documentObj.redactions || [];
            const redacted_documents = documentObj.redacted_documents || [];
            const docIndex = state.openFiles.findIndex(f => f.document_id === documentObj.document_id)
            // deleting all redactions
            if (redactions && redactions.length > 0) {
                try {
                    for (let index = 0; index < redactions.length; index++) {
                        const redaction = redactions[index];
                        const obj = {
                            id: redaction.id,
                            documentId: documentObj.document_id,
                            processId: documentObj.process.process_id
                        }
                        await dispatch("removeRedaction", obj)
                    }

                } catch (error) {
                    dispatch("pushError", error)
                }
            }
            // deleting all redacted documents @TODO - PUT ERROR
            if (redacted_documents && redacted_documents.length > 0) {
                try {
                    for (let index = 0; index < redacted_documents.length; index++) {
                        const redacted_document = redacted_documents[index];
                        await dispatch("apiRequest", {
                            method: "Delete",
                            // TODO: figure out if it should be  "document.process.process_id" or "process.process_id"
                            url: `${state.strapiUrl}/redacted-documents/${redacted_document.id}?document_id=${documentObj.document_id}&process.process_id=${documentObj.process.process_id}`
                        })
                    }

                } catch (error) {
                    dispatch("pushError", error)
                }
            }
            // deleting file in media library
            if (documentObj.file) {
                try {
                        await dispatch("apiRequest", {
                            method: "Delete",
                            url: `${state.strapiUrl}/upload/files/${documentObj.file.id}?document.document_id=${documentObj.document_id}`,
                        })
                        // Update restorationStatus enum in process collection
                        await dispatch("updateRestorationStatus", {
                            processId: documentObj.process.processId,
                            status: "TOCChangesDetected"
                        });
                } catch (error) {
                    dispatch("pushError", error)
                }
            }
            // deleting file in media library
            if (documentObj.original_file) {
                try {
                    await dispatch("apiRequest", {
                        method: "Delete",
                        url: `${state.strapiUrl}/upload/files/${documentObj.original_file.id}?document.document_id=${documentObj.document_id}`,
                    })
                } catch (error) {
                    dispatch("pushError", error)
                }
            }
            // deleting the document itself
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "Delete",
                    url: state.strapiUrl + "/documents/" + documentObj.id  + "?process.process_id=" + documentObj.process.process_id + "&only_documents=true",
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            // updating sort_order
            try {
                for (let index = docIndex; index < state.files.length; index++) {
                    const f = state.files[index];
                    if (f && f.sort_order && f.document_id !== documentObj.document_id) {
                        let newSortOrder = f.sort_order - 1;
                        const sortObj = {
                            internalDocumentId: f.id,
                            processId: documentObj.process.process_id,
                            data: {
                                sort_order: newSortOrder,
                            },
                        };
                        await dispatch("setFileOrder", sortObj);
                    }
                }
            } catch (error) {
                dispatch("pushError", error)
            }
            commit("removeDocument", await remoteResponse)
            commit("updateProcessDocCountRemove")
            
            await dispatch("updateRestorationStatus", {
                processId: documentObj.process.process_id,
                status: "ChangesDetected"
            });
            return await remoteResponse
        },
        async removeRedaction({ commit, dispatch, state }, obj) {
            let remoteResponse;
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "Delete",
                    url: `${state.strapiUrl}/redactions/${obj.id}?document.document_id=${obj.documentId}&document.process.process_id=${obj.processId}`
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            commit("removeRedaction", await remoteResponse)

            // Update restorationStatus enum in process collection
            await dispatch("updateRestorationStatus", {
                processId: obj.processId,
                status: "ChangesDetected"
            });
        },
        async removeSpecificProcess({ dispatch, state }, processId) {
            try {
                await dispatch("apiRequest", {
                    method: "Put",
                    url: state.strapiUrl + "/processes/" + processId,
                    data: {
                        soft_deleted: true,
                        soft_deleted_date: new Date().toISOString(),
                        soft_deleted_by: user.state.userData.id,
                    }
                })

                // Reload processes after successful soft deletion of process
                dispatch("loadProcessesForUser")

            } catch (error) {
                dispatch("pushError", error)
            }
        },
        async getRestorationStatus({ state, dispatch }, { processId }) {
            let remoteResponse;
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "Get",
                    url: state.strapiUrl + "/processes?process_id=" + processId
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            if (remoteResponse && remoteResponse.length > 0) {
                console.log("Restoration status", remoteResponse[0].restorationStatus)
                return {
                  id: remoteResponse[0].id,
                  restorationStatus: remoteResponse[0].restorationStatus,
                  decisionLetterId: remoteResponse[0]?.decision_letter?.id
                };
            }
            return null;
        },
        async updateRestorationStatus({ state, dispatch }, { processId, status }) {
            try {
                const processInfo = await dispatch("getRestorationStatus", {
                    processId: processId,
                });
                if (!processInfo) {
                    throw new Error(`Process with ID ${processId} not found`);
                }
                // Early return if the status is "NotCreated", matches the current status, or is exactly "ChangesDetected"
                if (processInfo.restorationStatus === "NotCreated" || 
                    processInfo.restorationStatus === status) {
                    return;
                }
                await dispatch("apiRequest", {
                    method: "Put",
                    url: `${state.strapiUrl}/processes/${processInfo.id}`,
                    data: {
                        restorationStatus: status
                    }
                });
            } catch (error) {
                dispatch("pushError", error);
            }
        },
        async setDecisionText({ dispatch, state }, decisionObj) {
            let remoteResponse;
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "Post",
                    url: state.strapiUrl + "/process-decisions",
                    data: decisionObj
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            return await remoteResponse
        },
        async createDecisionLetter({dispatch, state}, processObj){
            dispatch("posthogCapture", {event_name:"CreateDecisionLetter", data:{}})

            let remoteResponse;
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "Post",
                    url: state.apiUrl + "/redaction/create-decision-letter",
                    data: processObj
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            return await remoteResponse
        },
        async finalizeDecisionLetter({dispatch, state}, processObj) {
            dispatch("posthogCapture", {event_name:"FinishProcess", data:{}})

            let remoteResponse;
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "Post",
                    url: state.apiUrl + "/redaction/complete-process",
                    data: processObj
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            return await remoteResponse

        },
        async getDecisionLetter({dispatch, state}, processObj) {
            dispatch("posthogCapture", {event_name:"Download decision letter", data:{}})

            let remoteResponse;
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "GET",
                    url: `${state.strapiUrl}/decision-letters?process.process_id=${processObj.process.process_id}`,
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            // commit("setProcesses", await remoteResponse)
            return await remoteResponse
        },
        async updateDecisionLetter({dispatch, state}, obj){
            let remoteResponse;
            /* if (obj.data.editable_content) {
                obj.data.editable_content = obj.data.editable_content.replace(/style="([^"]*?)(?<!;)"(?=[\s>])/g, (match, styles) => {
                    // Check if the last character in the style rule is a semicolon; if not, add it.
                    return `style="${styles.trim().endsWith(';') ? styles : `${styles};`}"`;
                });
            } */
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "PUT",
                    url: `${state.strapiUrl}/decision-letters/${obj.id}?process.process_id=${obj.processId}`,
                    data: obj.data
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            // commit("setProcesses", await remoteResponse)
            return await remoteResponse
        },
        async resetDecisionLetterContent({dispatch, state, commit}, obj) {
            dispatch("posthogCapture", {event_name:"Reset decision letter content", data:{}})

            let remoteResponse;
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "POST",
                    url: `${state.apiUrl}/redaction/reset-decision-letter`,
                    data: obj
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            commit("setShouldPollForDecisionLetter", true)
            return await remoteResponse
        },
        async createFullDocument({ dispatch, state }, processObj) {
            dispatch("posthogCapture", {event_name:"FinishProcess", data:{}})
            let remoteResponse;
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "Post",
                    url: state.apiUrl + "/redaction/create-aktlist",
                    data: processObj
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            return await remoteResponse
        },
        async pushProcess({ commit, dispatch, state }, processObj) {
            let remoteResponse;
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "Post",
                    url: state.strapiUrl + "/processes",
                    data: processObj
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            commit("pushProcess", await remoteResponse)
            return remoteResponse
        },
        async pushRedaction({ commit, dispatch, state }, redactionObj) {
            let remoteResponse;
            let redactionProcessId;
            try {
                if (Array.isArray(redactionObj)) {
                    redactionProcessId = redactionObj[0].processId
                    for await (const singleRedaction of redactionObj) {
                        singleRedaction.data.number = state.redactions.length + 1
                        remoteResponse = await dispatch("apiRequest", {
                            method: "Post",
                            url: `${state.strapiUrl}/redactions?document.document_id=${singleRedaction.documentId}&document.process.process_id=${singleRedaction.processId}`,
                            data: singleRedaction.data
                        })
                        commit("pushRedaction", await remoteResponse)
                    }
                } else {
                    redactionProcessId = redactionObj.processId
                    redactionObj.data.number = state.redactions.length + 1
                    remoteResponse = await dispatch("apiRequest", {
                        method: "Post",
                        url: `${state.strapiUrl}/redactions?document.document_id=${redactionObj.documentId}&document.process.process_id=${redactionObj.processId}`,
                        data: redactionObj.data
                    })
                    commit("pushRedaction", await remoteResponse)
                }
            } catch (error) {
                dispatch("pushError", error)
            }

            // Update restorationStatus enum in process collection
            await dispatch("updateRestorationStatus", {
                processId: redactionProcessId,
                status: "ChangesDetected"
            });
            return await remoteResponse
        },
        clearSpecificFile({ commit }) {
            commit("setSpecificFile", {})
            // commit("clearOpenFileSearch")
        },
        setRedactions({ commit }, redactions) {
            commit("setRedactions", redactions);
        },
        async loadRedactions({commit, dispatch, state}, processId) {
            let remoteResponse;
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "Get",
                    url: state.strapiUrl + "/redactions?_limit=-1&document.process.process_id=" + processId
                })
            } catch (error) {
                dispatch("pushError", error)
            }

            commit("setAmountOfRedactionChanges", remoteResponse.length - state.redactions.length)
            commit("setRedactions", await remoteResponse);
        },
        async loadSpecificProcess({ commit, dispatch, state }, processId) {
            let remoteResponse, countResponse, redactionResponse;
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "Get",
                    url: state.strapiUrl + "/processes?process_id=" + processId
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            Promise.all(remoteResponse.map(async process => {
                try {
                    countResponse = await dispatch("apiRequest", {
                        method: "Get",
                        url: state.strapiUrl + "/documents/count?process.process_id=" + processId
                    })
                } catch (error) {
                    dispatch("pushError", error)
                }
                process.document_count = countResponse
            })).then(() => {
                commit("setSpecificProcess", remoteResponse);
            })
            
            // TODO: consider moving  
            // get redactions from api
            // NOTE: No possible to add document id as there can be multiple documents associated
            try {
                redactionResponse = await dispatch("apiRequest", {
                    method: "Get",
                    url: state.strapiUrl + "/redactions?_limit=-1&document.process.process_id=" + processId
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            commit("setRedactions", await redactionResponse);
        },
        async loadOnlyProcess({ commit, dispatch, state }, processId) {
            let remoteResponse, countResponse;
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "Get",
                    url: state.strapiUrl + "/processes?process_id=" + processId
                })
                
                remoteResponse.document_count = countResponse;
            } catch (error) {
                dispatch("pushError", error)
            }
            Promise.all(remoteResponse.map(async process => {
                try {
                    countResponse = await dispatch("apiRequest", {
                        method: "Get",
                        url: state.strapiUrl + "/documents/count?process.process_id=" + processId
                    })
                } catch (error) {
                    dispatch("pushError", error)
                }
                process.document_count = countResponse
            })).then(() => {commit("setSpecificProcess", remoteResponse);})
            
            // TODO: consider moving
            // NOTE: Not possible to add document id as there can be multiple documents associated
        },
        async loadSpecificFile({ commit, dispatch, state }, payload) {
            let remoteResponse;
            // get file from api
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "Get",
                    url: state.strapiUrl + "/documents?document_id=" + payload.documentId + "&process.process_id=" + payload.processId + "&only_documents=true"
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            commit("setSpecificFile", await remoteResponse);
            return await remoteResponse[0];
        },
        async addTextAnnotation({dispatch, state}, obj) {
            const data = {
                Annotation: JSON.stringify(obj.annotation),
                PageNumber: obj.pageNumber,
                document: {
                    id: obj.documentId
                },
                process: {
                    id: obj.process.id
                },
                Text: obj.text
            }
            // save text annotations
            try {
                await dispatch("apiRequest", {
                    method: "POST",
                    url: `${state.strapiUrl}/text-redactions?process.process_id=${obj.process.process_id}&document.id=${obj.documentId}`,
                    data
                })
            } catch (error) {
                dispatch("pushError", error)
            }
        },
        async removeTextAnnotation({dispatch, state}, obj){
            // remove text annotations
            try {
                await dispatch("apiRequest", {
                    method: "DELETE",
                    url: `${state.strapiUrl}/text-redactions/${obj.id}?process.process_id=${obj.process.process_id}`,
                })
            } catch (error) {
                dispatch("pushError", error)
            }
        },
        async setDocumentTextAnnotateData({dispatch, state}, obj){
            // Update the document text annotate state
            try {
                await dispatch("apiRequest", {
                    method: "Put",
                    url: state.strapiUrl + "/documents/" + obj.documentId + "?process.process_id=" + obj.processId + "&has_text_annotations=true",
                    data: obj.data
                })
            } catch (error) {
                dispatch("pushError", error)
            }

            // Reload files after change
            dispatch("loadFilesFromApiWithAnnotations", obj.processId);
        },
        async reSearchEntities({dispatch, state}, obj){
            // Update the document with user edited Text
            try {
                await dispatch("apiRequest", {
                    method: "Post",
                    url: state.apiUrl + "/entity/textSearch",
                    data: obj.data
                })
            } catch (error) {
                dispatch("pushError", error)
            }

            // Reload files after change
            dispatch("loadFilesFromApi", obj.processId);

        },
        async fetchNerData({commit, dispatch}, fileInternalId) {
            let remoteResponse
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "Get",
                    url: state.strapiUrl + "/document-pdf-data?document.id=" + fileInternalId,
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            commit("setNerData", remoteResponse[0])
        },
        async updateNerData({commit, dispatch}, updatedNerData) {
            let remoteResponse
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "Put",
                    url: state.strapiUrl + "/document-pdf-data/" + updatedNerData.id,
                    data: updatedNerData
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            commit("setNerData", remoteResponse[0])
        },
        async loadFileData({commit, dispatch, state}, fileDataObj) {
            let remoteResponse;
            // Stop same file from being loaded multiple times
            if(
                state.fileDataLoadingData.file !== undefined && 
                state.fileDataLoadingData.isLoading && 
                (state.fileDataLoadingData.file.id === fileDataObj.file.id)
            ){
              return;
            }
            // Set loading state 
            commit("setFileDataLoadingData", {isLoading: true, file: fileDataObj.file });

            // get file data from api
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "Post",
                    url: `${state.apiUrl}/file/loadPDF`,
                    data: fileDataObj
                })
                const data = {response: remoteResponse, file: fileDataObj.file}
                commit("setFileData", data);
                commit("setFileDataLoadingData", {isLoading: false, file: undefined});
            } catch (error) {
                dispatch("pushError", error)
            }
        },
        async loadFilesGrouped({ commit, dispatch, state }, groupObj) {
            let remoteResponse, redactionResponse;
            let groupNameString = groupObj.groupByColumn === "effort_status" ? "=" : ".name=";
            let groupString = groupObj.groupByColumn + groupNameString + groupObj.groupValue;
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "Get",
                    url: state.strapiUrl + "/documents?_limit=-1&_sort=sort_order:ASC&process.process_id=" + groupObj.processId + "&" + groupString + "&only_documents=true"
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            // NOTE: No possible to add document id as there can be multiple documents associated 
            try {
                redactionResponse = await dispatch("apiRequest", {
                    method: "Get",
                    url: state.strapiUrl + "/redactions?_limit=-1&document.process.process_id=" + state.openProcess.process_id + "&document." + groupString
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            commit("setRedactions", await redactionResponse);
            commit("setSpecificFiles", await remoteResponse);
            // commit("sortFiles")
        },
        async loadDocumentStatus({ commit, dispatch, state }) {
            let remoteResponse;
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "Get",
                    url: state.strapiUrl + "/document-statuses?_sort=sort_order:ASC"
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            commit("setDocumentStatus", await remoteResponse)
        },
        async loadDocumentTypes({ commit, dispatch, state }) {
            let remoteResponse;
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "Get",
                    url: state.strapiUrl + "/document-types?_sort=sort_order:ASC&show_as_default=true&is_disabled=false"
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            commit("setDocumentTypes", await remoteResponse)
        },
        async loadProcessStatus({ commit, dispatch, state }) {
            let remoteResponse;
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "Get",
                    url: state.strapiUrl + "/process-statuses?_sort=sort_order:ASC&is_disabled=false"
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            commit("setProcessStatus", await remoteResponse)
        },
        async loadProcessesFromApi({ commit, dispatch }) {
            let loginResponse;
            try {
                loginResponse = await dispatch("user/getUser");
            } catch (error) {
                dispatch("pushError", error)
            }
            if (loginResponse && loginResponse.id) {
                commit("user/setUser", loginResponse);
                await dispatch("loadProcessStatus");
                await dispatch("loadProcessesForUser");
            }
        },
        async loadUserFromAPI({ commit, dispatch }) {
            let loginResponse;
            try {
                loginResponse = await dispatch("user/getUser");
            } catch (error) {
                dispatch("pushError", error)
            }
            if (loginResponse && loginResponse.id) {
                
                commit("user/setUser", loginResponse);
            }
        },
        async loadProcessesForUser({ commit, dispatch, state, rootState }) {
            let remoteResponse, countResponse;
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "Get",
                    url: state.strapiUrl + "/processes?_limit=-1&_sort=process_status.name:DESC,request_deadline_date:ASC,request_received_date:DESC,created_at:DESC&_where[users.username]=" + rootState.user.userData.username
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            Promise.all(remoteResponse.map(async process => {
                try {
                    countResponse = await dispatch("apiRequest", {
                        method: "Get",
                        url: state.strapiUrl + "/documents/count?process.process_id=" + process.process_id
                    })
                } catch (error) {
                    dispatch("pushError", error)
                }
                process.document_count = countResponse;
            })).then(() => {commit("setProcesses", remoteResponse)})
        },
        async loadFilesFromApi({ commit, dispatch, state }, processId) {
            let remoteResponse;
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "Get",
                    url: state.strapiUrl + "/documents?_limit=-1&_sort=sort_order:ASC&process.process_id=" + processId + "&only_documents=true"
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            commit("setFiles", await remoteResponse)
        },
        async loadFilesFromApiWithAnnotations({ commit, dispatch, state }, processId) {
            let remoteResponse;
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "Get",
                    url: state.strapiUrl + "/documents?_limit=-1&_sort=sort_order:ASC&process.process_id=" + processId + "&only_documents=true"
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            commit("setFiles", await remoteResponse)
        },
        async getSurveys({commit}) {
            let elements; 
        
            await  fetch("/surveys/surveys.json")
            .then(response => response.json())
            .then(jsonData => {
                elements = jsonData;
                return(elements)
            } )
            commit("setSurveys", elements)
        },
        async apiRequest({ dispatch, commit }, request) {
            const jwt_token = localStorage.getItem('aktio-ai-user-token');
            
            if (!jwt_token) {
                console.log("Authentication not found - redirecting");
                commit("authenticate", false);
                commit("user/authenticate", false);
                commit("user/resetState");
                commit("notification/resetState")
                commit("resetState");
                router.push('/login');
                
                return null;
            }
            const base64Url = jwt_token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = atob(base64);
            const payload = JSON.parse(jsonPayload);
            
            if (payload.exp < Date.now() / 1000) {
                console.log("Authentication expired - redirecting");
                commit("authenticate", false);
                commit("user/authenticate", false);
                commit("user/resetState");
                commit("notification/resetState")
                commit("resetState");
                router.push('/login');
                return null;
            }
            
            try {
                const response = await fetch(request.url, {
                    method: request.method,
                    mode: "cors",
                    cache: "no-cache",
                    credentials: "omit",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${jwt_token}`,
                    },
                    redirect: "follow",
                    referrerPolicy: "no-referrer",
                    body: JSON.stringify(request.data)
                });
                // Handle non-JSON responses
                if (response.headers.get('Content-Type')?.includes('application/json')) {
                    return await response.json();
                } else {
                    return response;
                }
            
            } catch (error) {
                dispatch("pushError", error);
                return error;
            }
        },
        toggleSidebar({ commit }) {
            commit('toggleSidebar')
        },

        async getTooltipsList({ commit }) {
            commit("setTooltips", tooltip_json);
        },
        async getUsersToShareWith({dispatch, commit, state}, searchObj) {
            let remoteResponse;
            try {
                remoteResponse = await dispatch("apiRequest", {
                    method: "GET",
                    url: `${state.apiUrl}/user/employee/${searchObj.processId}/${searchObj.query}`
                })
            } catch (error) {
                dispatch("pushError", error)
            }
            commit("setShareUsers", await remoteResponse);
        },
        async updateUser({dispatch, commit, state}, userObj) {
            let remoteResponse;     
            try {
              remoteResponse = await dispatch("apiRequest", {
                  method: "PUT",
                  url: `${state.apiUrl}/user/me`,
                  data: userObj
              })
              // Update user object with response, if it was a 
              // successful update
              if(remoteResponse.status === 200){
                commit("user/setUser", await remoteResponse.data.json())
              }
            } catch (error) {
                dispatch("pushError", error)
            }
            return await remoteResponse;
        },
        async saveSurvey({dispatch, state}, surveyObj){
            try {
              await dispatch("apiRequest", {
                  method: "POST",
                  url: `${state.strapiUrl}/surveys`,
                  data: surveyObj
              })
            } catch (error) {
                dispatch("pushError", error)
            }
        },
        async posthogCapture({ state }, event) {
            // Check if posthog is available and initialized
            if (!window.posthog || typeof posthog.capture !== 'function') {
              console.warn("PostHog is not available or not properly initialized.");
              return;
            }
          
            try {
              const jobSection = user.state.userData.job_section_new;
              const enrichedData = {
                ...event.data,
                id: user.state.userData.id,
                username: user.state.userData.username,
                client_name: process.env.VUE_APP_CUSTOMER_NAME,
                client_id: '',
                user_id: user.state.userData.id,
                email: user.state.userData.email,
                section_id: jobSection,
                section: jobSection ? jobSection.name : undefined,
              };
          
              let captureEvents = true;
          
              try {
                captureEvents = !posthog.isFeatureEnabled("disable-capture-events");
              } catch (error) {
                console.warn("Error checking PostHog feature flag:", error);
                // Default to capturing events if there's an error
              }
          
              if (captureEvents) {
                posthog.capture(event.event_name, enrichedData);
              }
            } catch (error) {
              console.error("Error capturing PostHog event:", error);
            }
          }
    },
})

export default store;