<template>
  <svg
    class="stroke-current"
    fill="currentColor"
    stroke="currentColor"
    viewBox="0 0 24 24"
  >
    <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
  </svg>
</template>

<script>
export default {
  name: "ChevronRight",
};
</script>