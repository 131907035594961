<template>
  <div>
    <div class="flex justify-between">
      <div>
        <div class="text-xs">
          Modtaget
        </div>
        <div>{{ returnDate(process.request_received_date) }}</div>
      </div>
      <div class="ml-2">
        <div class="text-xs">
          Frist
        </div>
        <div>{{ returnDate(process.request_deadline_date) }}</div>
      </div>
    </div>
    <redaction-progress-bar
      :date-from="process.request_received_date || ''"
      :date-to="process.request_deadline_date || ''"
      :document-count="process.document_count"
      :process-status="process.process_status"
    />

    <div v-if="returnProcessFinished(process)">
      <pre class="text-xs text-center mt-1">{{ returnDateDiff(Date.now(), process.request_deadline_date) }}</pre>
    </div>
    <!--        <div v-else>-->
    <!--          <div v-if="process.process_status.name === 'Færdigbehandlet'">-->
    <!--            <div class="flex justify-center">-->
    <!--              <redaction-process-status :process="process" />-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
  </div>
</template>

<script>
import ProgressBar from "@/components/ProgressBar.vue";
import dayjs from "dayjs";

export default {
  name: "ProcessDates",
  components: {
    "redaction-progress-bar": ProgressBar,
  },
  props: {
    process: Object,
  },
  methods: {
    returnProcessFinished(process) {
      if (process.process_status && process.process_status.name !== 'Færdigbehandlet') {
        return true
      } else {
        return false
      }
    },
    returnDate(dateString) {
      if(dateString) {
        return dayjs(dateString).format("DD-MM-YYYY");
      } else {
        return "Dato mangler"
      }
    },
    returnDateDiff(fromDate, toDate) {
      let day_diff = parseInt(Math.ceil(dayjs(toDate).diff(fromDate, "day", true)))
      if (day_diff > 1) {
        return 'Frist om ' + day_diff + ' dage';
      } else if (day_diff === 1) {
        return 'Frist om ' + Math.abs(day_diff) + ' dag';
      } else if (day_diff === 0) {
        return 'Frist i dag';
      } else if (day_diff === -1) {
        return 'Frist overskredet ' + Math.abs(day_diff) + ' dag';
      } else {
        return 'Frist overskredet ' + Math.abs(day_diff) + ' dage';
      }
    },
  },
};
</script>

<style>
</style>