<template>
  <div
    @click="goToService()"
    :class="[
      'service p-4 rounded-lg text-center transition duration-200 h-64 ',
      disabled ? 'bg-gray-200 cursor-not-allowed' : 'bg-gray-200 shadow hover:text-aktio-pink-500 hover:shadow-lg hover:bg-gray-300 cursor-pointer'
    ]"
  >
    <div class="w-full h-16 object-cover rounded-md mb-2 mt-4 center">
      <font-awesome-icon
        size="3x"
        :icon="iconName"
        color="#092853"
      />
    </div>
    <h2
      :class="[
        'service-title text-3xl font-semibold mb-2',
        disabled ? 'text-gray-500' : 'text-gray-800'
      ]"
      style="font-family: 'Outfit', sans-serif;"
    >
      aktio <span class="text-aktio-pink-500">{{ name }}</span>
    </h2>
    <p
      :class="[
        'service-description text-gray-600',
        disabled ? 'text-gray-400' : ''
      ]"
    >
      {{ description }}
    </p>
  </div>
</template>

<script>
  export default {
    name: "ServiceComponent",
    props: {
      link: {
        type: String,
        default: "/"
      },
      title: {
        type: String,
        required: true,
      },
      name: {
        type: String,
        required: true,
      },
      description: {
        type: String,
        required: true,
      },
      iconName: {
        type: String,
        required: true,
        default: "fa-file"
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      goToService() {
        this.$router.push(this.link)
      },
    }
  };
</script>  

<style>
</style>
  