<template>
  <svg
    class="fill-current stroke-current"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        transform="translate(-380.000000, -1399.000000)"
        fill="#ffffff"
      >
        <g transform="translate(56.000000, 160.000000)">
          <path
            id="file_doc-[#1723]"
            d="M337.9792,1239.0005 L324.0002,1239.0005 L324.0002,1249.0005 L326.0002,1249.0005 L326.0002,1241.0005 L336.0002,1241.0005 L336.0002,1247.0005 L342.0002,1247.0005 L342.0002,1249.0005 L344.0002,1249.0005 L344.0002,1245.4385 L337.9792,1239.0005 Z M333.0002,1254.0005 L333.0002,1256.0005 C333.0002,1256.5525 333.4482,1257.0005 334.0002,1257.0005 C334.5522,1257.0005 335.0002,1256.5525 335.0002,1256.0005 L335.0002,1254.0005 C335.0002,1253.4485 334.5522,1253.0005 334.0002,1253.0005 C333.4482,1253.0005 333.0002,1253.4485 333.0002,1254.0005 L333.0002,1254.0005 Z M331.0002,1257.0005 L331.0002,1253.0005 C331.0002,1251.8955 331.8952,1251.0005 333.0002,1251.0005 L335.0002,1251.0005 C336.1042,1251.0005 337.0002,1251.8955 337.0002,1253.0005 L337.0002,1257.0005 C337.0002,1258.1055 336.1042,1259.0005 335.0002,1259.0005 L333.0002,1259.0005 C331.8952,1259.0005 331.0002,1258.1055 331.0002,1257.0005 L331.0002,1257.0005 Z M328.0002,1254.1055 C328.0002,1253.5525 327.5522,1253.0005 327.0002,1253.0005 L326.0002,1253.0005 L326.0002,1257.0005 L327.0002,1257.0005 C327.5522,1257.0005 328.0002,1256.6575 328.0002,1256.1055 L328.0002,1254.1055 Z M330.0002,1254.0005 L330.0002,1256.0005 C330.0002,1257.6575 328.6572,1259.0005 327.0002,1259.0005 L324.0002,1259.0005 L324.0002,1257.1055 L324.0002,1253.1055 L324.0002,1251.0005 L327.0002,1251.0005 C328.6502,1251.0005 330.0002,1252.3505 330.0002,1254.0005 L330.0002,1254.0005 Z M342.0002,1251.0005 L344.0002,1251.0005 L344.0002,1253.0005 L342.0002,1253.0005 C340.8952,1253.0005 340.0002,1253.8955 340.0002,1255.0005 C340.0002,1256.1045 340.8952,1257.0005 342.0002,1257.0005 L344.0002,1257.0005 L344.0002,1259.0005 L342.0002,1259.0005 C339.7912,1259.0005 338.0002,1257.2095 338.0002,1255.0005 C338.0002,1252.7915 339.7912,1251.0005 342.0002,1251.0005 L342.0002,1251.0005 Z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconDoc",
};
</script>

<style>
</style>