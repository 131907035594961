<template>
  <VueCustomTooltip
    :multiline="true"
    :size="size"
    :label="$store.getters.getTooltips(field_name)"
  >
    <icon-question-mark class="w-4 h-4 text-black-500 mx-1" />
  </VueCustomTooltip>
</template>

<script>
import { mapState } from "vuex";
import IconQuestionMark from "@/components/icons/questionmark.vue";

export default {
    name: "Tooltip",
    components:{
        IconQuestionMark
    },
    props: {
      field_name: {
        type: String,
        required: true,
      },
      size: {
        type: String,
        default: "is-medium",
      },
    },
    computed: {
    ...mapState([
      "tooltips"
         ])
    },
    mounted(){
        this.$store.dispatch("getTooltipsList")
    }
};
</script>

<style>
</style>

