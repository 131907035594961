<template>
  <label
    class="toggle-switch"
  >
    <input
      type="checkbox"
      :checked="modelValue"
      :disabled="disabled || isUpdating"
      @change="handleChange"
      @click.stop="handleClick"
    >
    <span
      class="slider round"
      :class="{ updating: isUpdating }"
    />
  </label>
</template>
  
<script>
  export default {
    props: {
        modelValue: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        isUpdating: {
            type: Boolean,
            default: false
        }
    },
    emits: ['update:modelValue', 'click'],
    methods: {
        handleChange(event) {
            if (this.isUpdating) return;
            this.$emit('update:modelValue', event.target.checked);
        },
        handleClick(event) {
            if (!this.disabled) {
                this.$emit('click', event); // Emit click event for custom click actions
            }
        },
    },
  }
</script>
  
<style scoped>
  .toggle-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 28px;
  }
  .toggle-switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 28px;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  .slider.updating {
    transition: none !important;
  }

  input:checked + .slider {
    background-color: #2196F3;
  }
  input:checked + .slider:before {
    transform: translateX(23px);
  }
  input:disabled + .slider {
    background-color: #ddd;
    cursor: not-allowed;
  }
</style>
  