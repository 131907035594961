import { createApp, reactive, shallowRef } from 'vue';
import ModalManager from '@/components/modalHandler/ModalManager.vue';
import VueCustomTooltip from '@adamdehaven/vue-custom-tooltip';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// import FeatureFlag from './featureflag.js'

export default {
    install(app, store) {
        app.config.globalProperties.$modal = {
            activeModals: [],

            show(component, props = {}, options = {}, additionalOptions = {}) {
                const modalId = `modal-${this.activeModals.length}`;
                const modalState = reactive({
                    isVisible: true,
                    currentModal: {
                        component: shallowRef(component),
                        props,
                        options
                    },
                    close: () => this.hide(modalId),
                    modalId: this.activeModals.length,
                    ...additionalOptions, // Merge additional options into modalState
                });

                const modalApp = createApp(ModalManager, { modalState });
                modalApp.config.globalProperties = app.config.globalProperties; // Ensure global properties like store are passed
                modalApp.use(VueCustomTooltip, { multiline: true, size: 'is-medium' });
                modalApp.component('FontAwesomeIcon', FontAwesomeIcon);
                modalApp.use(store);

                const mountPoint = document.createElement('div');
                mountPoint.className = modalId;
                document.body.appendChild(mountPoint);

                modalApp.mount(mountPoint);

                document.body.style.overflow = 'hidden'; // Apply overflow: hidden; when modal opens

                this.activeModals.push({ id: modalId, app: modalApp, el: mountPoint, modalState });
            },

            hide(modalId) {
                const index = this.activeModals.findIndex(m => m.id === modalId);
                if (index !== -1) {
                    const modal = this.activeModals[index];
                    modal.modalState.isVisible = false;
                    modal.app.unmount();
                    document.body.removeChild(modal.el);
                    this.activeModals.splice(index, 1);
                    if (!this.activeModals.length) {
                        document.body.style.overflow = ''; // Fjerner overflow: hidden fra body hvis der ikke er flere åbne modaler
                    }
                }
            }
        };
    }
};