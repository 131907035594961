<template>
  <div id="help">
    <div class="grid grid-cols-3 gap-4 bg-white mt-2">
      <div class="col-span-3 border-b">
        <accordion-item
          title="Hjælp til Aktio Indsigt"
          :open="true"
        >
          <p class="mb-2">
            Hjælp siden er opdateret d. 22. november 2022.
          </p>
          <p class="mt-2">
            Har du brug for yderligere hjælp eller har du ris eller ros (vi lytter meget gerne), kan du kontakte <strong><a href="mailto:support@aktio.ai">support@aktio.ai</a></strong>, husk du også kan spørge en kollega om hjælp. Vores svartid er typisk ~1 arbejdsdag. NB: Hvis du sender support e-mails til os, bør du undlade at medsende personoplysninger vedrørende sager i eks. screenshots, vedhæftede filer eller lignende - send i stedet eks. sagsid.
          </p>
        </accordion-item>

        <AccordionItem
          title="Undervisningsvideoer"
          class="hidden"
        >
          <div class="font-light">
            <p>
              Herunder finder du forskellige videoer, som viser dig hvordan du kan bruge Aktio Indsigt, når du
              behandler en aktindsigt.
            </p>
            <p>
              Du kan også få indsat dine egne undervisningsvideoer til aktindsigt her - kontakt da Aktio Indsigt
              support.
            </p>
            <div id="videoItem">
              <VideoList
                :listdata="videos"
                @clicked-item="openVideoModal"
              />
            </div>
            <modal
              name="VideoExample"
              height="auto"
            >
              <video
                id="player"
                :class="nameVideo"
                playsinline
                controls
                :poster="poster"
                @playing="registerPlay"
              >
                <source
                  :src="clickedVideo"
                  type="video/mp4"
                >
              <!-- <source src="/path/to/video.webm" type="video/webm" /> -->

              <!-- Captions are optional -->
              <!-- <track kind="captions" label="English captions" src="/path/to/captions.vtt" srclang="en" default /> -->
              </video>
            </modal>
          </div>
        </AccordionItem>

        <AccordionItem title="Oversigt over dokument statusser og deres beskrivelse">
          <div class="font-light">
            <p>
              Når der indlæses dokumenter i Aktio Indsigt, kan disse få en række forskellige statuskoder. Herunder
              kan du se disse statuskoder, og hvilken betydning de har.
            </p>
            <h2
              v-if="documentStatuses.length === 0"
              class="pt-4 font-bold"
            >
              Henter dokument statusser...
            </h2>
            <div
              v-for="(documentStatus) in sortedDocumentStatuses(documentStatuses)"
              :key="documentStatus.id"
              class="pt-4"
            >
              <div
                class="inline-block h-6 rounded text-xs text-white px-2 py-1 whitespace-no-wrap"
                :class="[
                  documentStatus.background_color_class || 'bg-gray-500'
                ]"
              >
                {{ documentStatus.display_name }}
              </div> {{ documentStatus.description }}
            </div>
          </div>
        </AccordionItem>

        <AccordionItem title="Bestil dokumenter med robotten (RPA)">
          <div class="font-light">
            <p>
              Såfremt du mangler adgang til at bestille dokumenter via robotten (RPA), kan du få det ved at sende en
              e-mail til <strong><a href="mailto:support@aktio.ai?subject=RPA adgang">support@aktio.ai</a></strong> med din nærmeste leder CC.
            </p>
            <p>
              Bed om at få RPA adgang, og bed om at din leder bekræfter det. Herefter kan vi åbne for adgangen,
              såfremt at kommunen har en RPA integration.
            </p>
          </div>
        </AccordionItem>

        <AccordionItem title="Nye features og roadmap">
          <div class="font-light">
            <p>Følg med på vores hjemmeside i de nye features som kommer til Aktio Indsigt. Her kommer snart mere information.</p>
          </div>
        </AccordionItem>

        <AccordionItem title="Kendte fejl og workarounds">
          <div class="font-light">
            <p class="mb-4">
              Vi har desværre også udfordringer nogle gange med teknikken - men vi arbejder hårdt på
              løsninger på nedenstående. Her kommer snart mere information.
            </p>
          </div>
        </AccordionItem>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import VideoList from "@/components/VideoList.vue";
import videoTutorials from "../assets/json/videoTutorials.json";
import AccordionItem from "@/components/controls/AccordionItem.vue";

export default {
  name: "Help",
  components: {
    VideoList,
    AccordionItem,
  },
  data() {
    return {
      videos: videoTutorials,
      nameVideo: '',
      clickedVideo: '',
      poster:'',
    }
  },
  computed: {
    ...mapState(["documentStatuses"]),
  },
  mounted(){
    this.$store.dispatch("getDocumentStatuses")
  },
  methods: {
    sortedDocumentStatuses(array){
      return array.slice().sort((a,b) => a.sort_order > b.sort_order)
    },
    openVideoModal(video) {
      this.$store.dispatch("posthogCapture", {event_name:"OpenVideoModal", data: {videoName: video.title, videoSource: video.src}});
      this.nameVideo = video.title;
      this.clickedVideo = video.src;
      this.poster = video.thumbnail;
      this.$modal.show('VideoPlayer', 
      {
        classes: 'overflow-visible',
        height: "auto",
        scrollable: true,
        clickToClose: true,
      })
    },
    registerPlay(e) {
      this.$store.dispatch("posthogCapture", {
        event_name:"PlayVideo", 
        data:
        {
          VideoTitle: e.explicitOriginalTarget._prevClass,
          VideoSource:e.explicitOriginalTarget.firstChild.src
        }
      })
    }
  }
}
</script>

<style scoped>
  .accordion-body p {
    word-break: keep-all;
  }
</style>