<template>
  <div>
    AuthRedirect
    <div>Params: {{ $route.params }}</div>
    <div>Query: {{ $route.query }}</div>
    <div>location.search: {{ location.search }}</div>
  </div>
</template>

<script>
export default {
    name: "AuthRedirect",
    computed: {
        location() {
            return location
        }
    }
}
</script>

<style>

</style>