<template>
  <div class="w-full">
    <div
      v-if="!redactedProcessStatus"
      class="flex mt-20"
    >
      <span class="text-gray-500 w-full text-center text-2xl"> Henter dokumenter... </span>
    </div>
    <div
      v-if="!isLargeScreen"
      class="mb-1 flex justify-center"
    >
      <table class="table-auto shadow-lg bg-white rounded-md">
        <tr>
          <!-- Afgørelsesbrev & Aktliste -->
          <td class="border px-4 pt-3 pb-2">
            <div class="flex flex-col md:flex-row items-center">
              <font-awesome-icon
                :class="{'animate-spin': isPollingFullDocument, 'invisible': !isPollingFullDocument}"
                icon="fa-spinner"
                class="md:mr-2 md:mb-1 sm:mb-2"
              />
              <div class="flex flex-col md:flex-row flex-wrap">
                <standard-button
                  button-type="standard"
                  button-text="Journaliser sag til SDH"
                  title="Journalisering af sag"
                  class="btn-fixed-width mb-0 md:mr-2 md:mb-1 sm:mb-2"
                  button-text-small
                  @click="openExportDocModal()"
                />
                <standard-button
                  :disabled="isPollingFullDocument"
                  :button-disabled="isPollingFullDocument"
                  button-type="standard-disableable"
                  button-text="Send (print)"
                  button-icon="print"
                  title="Print af sag"
                  class="btn-fixed-width mb-0 md:mr-2 md:mb-1 sm:mb-2"
                  button-text-small
                  @click="printFullDocument"
                />
                <standard-button
                  :disabled="isPollingFullDocument"
                  :button-disabled="isPollingFullDocument"
                  button-type="standard-disableable"
                  button-text="Download"
                  button-icon="download"
                  title="Download af sag"
                  class="btn-fixed-width mb-0 md:mb-1"
                  button-text-small
                  @click="downloadFullDocument"
                />
              </div>
            </div>
          </td>
          <!-- Afgørelsesbrev -->
          <td class="border px-4 pt-3 pb-2">
            <div class="flex flex-col md:flex-row items-center">
              <font-awesome-icon
                :class="{'animate-spin': isPollingDecisionLetter, 'invisible': !isPollingDecisionLetter}"
                icon="fa-spinner"
                class="md:mr-2 md:mb-1 sm:mb-2"
              />
              <div class="flex flex-col md:flex-row flex-wrap">
                <standard-button
                  :disabled="isPollingDecisionLetter"
                  :button-disabled="isPollingDecisionLetter"
                  button-type="standard-disableable"
                  button-text="Send (print)"
                  button-icon="print"
                  title="Send (print) Afgørelsesbrev"
                  class="btn-fixed-width mb-0 md:mr-2 md:mb-1 sm:mb-2"
                  button-text-small
                  @click="printDecisionLetter"
                />
                <standard-button
                  :disabled="isPollingDecisionLetter"
                  :button-disabled="isPollingDecisionLetter"
                  button-type="standard-disableable"
                  button-text="Download"
                  button-icon="download"
                  title="Download Afgørelsesbrev"
                  button-text-small
                  class="btn-fixed-width mb-0 md:mr-2 md:mb-1 sm:mb-2"
                  @click="downloadDecisionLetter"
                />
              </div>
            </div>
          </td>
        </tr>
        <tr 
          v-show="acadreCaseId" 
          class="text-center"
        >
          <td class="border px-4 pt-3 pb-2"> 
            Journaliseret til Acadre Sags Nr.  
          </td>
          <td class="border px-4 pt-3 pb-2 text-aktio-pink-500">
            {{ acadreCaseId }}
          </td>
        </tr>
      </table>
    </div>

    <div class="flex">
      <div
        v-if="decisionLetter"
        class="ml-2 w-auto editor-container"
      >
        <HTMLEditor
          :content="decisionLetter['content']"
          :decision-letter-id="decisionLetter.id"
          :logo="decisionLetter['logo']"
          @update="receivedNewHTML"
        />
      </div>
      <div
        v-else
        class="divide-gray-400 rounded-md border border-gray-400 w-full bg-white flex flex-col justify-center loading"
      >
        <div 
          v-if="showPollStatusMessage"
          class="text-center mb-4 text-gray-700"
          style="font-size: 1.25rem;"
        >
          {{ redactedProcessStatus.status_message }}
        </div>
        <font-awesome-icon
          size="4x"
          class="fa-spin m-0"
          icon="fa-spinner"
          :style="{ color: '#092853'}"
        />
      </div>
      <div v-if="isLargeScreen">
        <table class="table-auto shadow-lg bg-white table-actions">
          <tr>
            <th class="bg-aktio-grey border px-4 text-left py-2">
              Brev
            </th>
            <th class="bg-aktio-grey border px-4 text-left py-2">
              Handlinger
            </th>
          </tr>
          <tr v-show="acadreCaseId">
            <td 
              style="min-width: 160px"
              class="border p-4"
            > 
              Journaliseret til Acadre Sags Nr.  
            </td>
            <td 
              style="min-width: 160px"
              class="border p-4 text-aktio-pink-500"
            >
              {{ acadreCaseId }}
            </td>
          </tr>
          <tr>
            <td
              style="min-width: 160px"
              class="border p-4"
            >
              <div>
                <div 
                  v-if="openProcess.law_set.name === 'INS'" 
                  class="mx-1 font-bold"
                >
                  Brev & Dokument samlingen
                </div>
                <div 
                  v-else
                  class="mx-1 font-bold"
                >
                  Afgørelsesbrev & Dokument samlingen
                </div>
                <font-awesome-icon
                  v-if="isPollingFullDocument"
                  class="animate-spin"
                  icon="fa-spinner"
                />
              </div>
            </td>
            <td
              style="min-width: 325px"
              class="border p-4"
            >
              <div class="flex flex-col md:flex-row flex-wrap">
                <standard-button
                  button-type="standard"
                  button-text="Journaliser sag til ESDH"
                  title="Journalisering af sag"
                  class="mb-0 md:mr-2 md:mb-1 sm:mb-2"
                  button-text-small
                  @click="openExportDocModal()"
                />
                <standard-button
                  :disabled="isPollingFullDocument"
                  :button-disabled="isPollingFullDocument"
                  button-type="standard-disableable"
                  button-text="Send (print)"
                  button-icon="print"
                  title="Print af sag"
                  class="mb-0 md:mr-2 md:mb-1 sm:mb-2"
                  button-text-small
                  @click="printFullDocument"
                />
                <standard-button
                  :disabled="isPollingFullDocument"
                  :button-disabled="isPollingFullDocument"
                  button-type="standard-disableable"
                  button-text="Download"
                  button-icon="download"
                  title="Dowload af sag"
                  class="mb-0 md:mr-2 md:mb-1 sm:mb-2"
                  button-text-small
                  @click="downloadFullDocument"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td
              style="min-width: 160px"
              class="border p-4"
            >
              <div>
                <div
                  v-if="redactedProcessStatus"
                  class="flex items-center"
                >
                  <div 
                    v-if="openProcess.law_set.name === 'INS'" 
                    class="mx-1 font-bold"
                  >
                    Brev
                  </div>
                  <div 
                    v-else
                    class="mx-1 font-bold"
                  >
                    Afgørelsesbrev
                  </div>
                  <font-awesome-icon
                    v-if="isPollingDecisionLetter"
                    class="animate-spin"
                    icon="fa-spinner"
                  />
                </div>
              </div>
            </td>
            <td
              style="min-width: 325px"
              class="border px-4 pt-4 pb-3"
            >
              <div class="flex flex-col md:flex-row flex-wrap">
                <standard-button
                  :disabled="isPollingDecisionLetter"
                  :button-disabled="isPollingDecisionLetter"
                  button-type="standard-disableable"
                  button-text="Send (print)"
                  button-icon="print"
                  title="Send (print) Afgørelsesbrev"
                  class="mb-0 md:mr-2 md:mb-1 sm:mb-2"
                  button-text-small
                  @click="printDecisionLetter"
                />
                <standard-button
                  :disabled="isPollingDecisionLetter"
                  :button-disabled="isPollingDecisionLetter"
                  button-type="standard-disableable"
                  button-text="Download"
                  button-icon="download"
                  title="Download Afgørelsesbrev"
                  class="mb-0 md:mr-2 md:mb-1 sm:mb-2"
                  button-text-small
                  @click="downloadDecisionLetter"
                />
              </div>
            </td>
          </tr>
          <tr v-if="showStatusMessage">
            <td
              class="border p-4 text-center"
              colspan="2"
            >
              {{ redactedProcessStatus.status_message }}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import StandardButton from "@/components/controls/StandardButton.vue";
import HTMLEditor from "@/components/htmlEditor/HTMLEditor.vue";
import axios from "axios";
import { debounce } from "lodash";
import ExportExternalDocumentsForm from "@/components/forms/ExportExternalDocumentsForm.vue";
import SplittetFileChooseForm from "@/components/forms/SplittetFileChooseForm.vue";

export default {
  name: "DecisionOverview",
  components: {
    StandardButton,
    HTMLEditor,
  },
  data() {
    return {
      shouldRecreateDecisionDocuments: false,
      saveOperationPending: false,
      cancelTokenSource: null,
      redactedProcessStatusPolling: null,
      decisionLetter: undefined,
      decisionLetterPolling: null, 
      fullDocumentPolling: null,
      decisionLetterUpdated: undefined,
      shouldPrintOrDownload: undefined,
      printOrDownloadKind: undefined,
      fullDocumentUpdated: undefined,
      fullDocumentFile: undefined,
      isComponentActive: true,
      isPollingDecisionLetter: false,
      isPollingFullDocument: false,
      hasActionStarted: false,
      showStatusMessage: false,
      showPollStatusMessage: false,
      isLargeScreen: true,
      restorationStatus: "",
      acadreCaseId: ""
    };
  },
  computed: {
    ...mapState([
      "openProcess",
      "redactedProcessStatus",
      "apiUrl",
      "files",
      "user",
      "shouldPollDecisionLetter",
    ]),
  },
  watch: {
    decisionLetter(decisionLetter) {
      if(decisionLetter && this.restorationStatus === "NoChange") {
        this.$store.commit("setShouldPollForDecisionLetter", false)
      }
    },
    shouldPollDecisionLetter(shouldPoll){
      if(shouldPoll) {
        this.decisionLetter = undefined;
        this.decisionLetterUpdated = new Date().getTime()
        this.pollDecisionLetter(false);
      }
    },
    openProcess(process) {
      if (process.acadre_case_id) {
        this.acadreCaseId = process.acadre_case_id;
      }
    }
  },
  mounted() {
    this.initializeData()
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
  },
  beforeUnmount() {
    this.isComponentActive = false; 
    clearTimeout(this.decisionLetterPolling); 
    clearTimeout(this.fullDocumentPolling);
    window.removeEventListener('resize', this.checkScreenSize);
  },
  methods: {
    async initializeData() {
      if (!this.openProcess.id) {
        await this.$store.dispatch("loadSpecificProcess", this.$route.params.processId);
        this.restorationStatus = this.openProcess.restorationStatus;
      }
      await this.$store.dispatch("loadFilesFromApi", this.$route.params.processId);
      await this.$store.dispatch("pollRedactedProcessStatus");

      const processObj = {
        process: {
          id: this.openProcess.id,
          process_id: this.$route.params.processId,
        },
        request_user: this.user.userData,
      }
      if (this.openProcess.acadre_case_id) {
        this.acadreCaseId = this.openProcess.acadre_case_id;
        console.log(this.acadreCaseId)
      }

      const decisionLetter = await this.$store.dispatch("getDecisionLetter", processObj);

      // Check if decision letter needs to be created or polled
      const shouldCreateDecisionLetter = !decisionLetter
        || !decisionLetter[0]
        || !decisionLetter[0].content.htmlContent
        || decisionLetter[0].content.htmlContent.length === 0;
      
      if (shouldCreateDecisionLetter) {
        // Create initial decision letter and poll for result
        await this.$store.dispatch("createDecisionLetter", processObj);
        this.pollDecisionLetter(false);
        return;
      }

      // Handle existing decision letter based on restoration status
      if (this.restorationStatus === "NoChange") {
        this.decisionLetter = decisionLetter[0];
      } else {
        this.pollDecisionLetter(false);
      }
    },
    pollRedactedProcessStatus() {
      const poll = async () => {
        if (!this.isComponentActive) return;

        if (this.isComponentActive && (this.isPollingFullDocument || this.isPollingDecisionLetter)) {          
          this.showStatusMessage = true;
          
          await this.$store.dispatch("pollRedactedProcessStatus");
          this.redactedProcessStatusPolling = setTimeout(poll, 1000);
          
          if (!this.hasActionStarted && this.redactedProcessStatus.status_message === "Behandling færdig") {
            this.redactedProcessStatus.status_message = "Starter handling";
          }
          // This is for sets the flag to true so it doesnt replace the last status_message
          if (!this.hasActionStarted && this.redactedProcessStatus.status_message.startsWith("Overstreger i dokument")) {
            this.hasActionStarted = true;
          }
        } else {
          this.hasActionStarted = false; // Update flag for start msg
          if (!this.redactedProcessStatus.status_message.startsWith("Fejl")) {
            this.redactedProcessStatus.status_message = "Behandling færdig";
          }
          setTimeout(() => {
            this.showStatusMessage = false;
          }, 1000);
        }
      }
      poll();
    },
    printOrDownload(action) {
      switch (action) {
        case 'printDecisionLetter':
          this.printFile(this.getDecisionLetterData());
          return;
        case 'printFullDocument':
          this.printFile(this.getFullDocumentData());
          return;
        case 'downloadDecisionLetter':
          this.downloadFile(this.getDecisionLetterData())
          return;
        case 'downloadFullDocument':
          this.downloadFile(this.getFullDocumentData())
          return;
        default:
          console.log(`Unknown action: ${action}`)
      }
    },
    pollDecisionLetter(shouldCheckForPDF, actionType="") {
      const poll = async () => {
        if (!this.isComponentActive) return; // Check if component is still active
        await this.$store.dispatch("pollRedactedProcessStatus");

        const processObj = {
          process: {
            process_id: this.openProcess.process_id,
          },
        };

        try {
          const processInfo = await this.$store.dispatch("getRestorationStatus", {
            processId: this.$route.params.processId,
          });
          // Proceed only if the restoration status is "NoChange"
          if (processInfo.restorationStatus !== "NoChange") {
            if (this.isComponentActive) {
              this.decisionLetterPolling = setTimeout(poll, 1500);
            }
            return;
          }
          this.restorationStatus = processInfo.restorationStatus;
          
          const decisionLetter = await this.$store.dispatch("getDecisionLetter", processObj);
          console.log("Polling Decision Letter")
          if (
            decisionLetter !== undefined &&
            decisionLetter[0] !== undefined &&
            decisionLetter[0].content.htmlContent !== null &&
            decisionLetter[0].content.htmlContent.length > 0
          ) {
            // If we are waiting for a new version of the decision letter PDF, ensure we have the newest version before saving it
            if (shouldCheckForPDF && this.decisionLetterUpdated !== null && decisionLetter[0]['file']) {
              if (new Date(decisionLetter[0]['file']['updated_at']).getTime() > this.decisionLetterUpdated) {
                this.decisionLetter = decisionLetter[0];
                this.printOrDownload(this.printOrDownloadKind);
                this.isPollingDecisionLetter = false;
                this.showPollStatusMessage = false;
                return; // Stop polling once the desired condition is met
              }
            } else {
              this.decisionLetter = decisionLetter[0];
              this.showPollStatusMessage = false;
              if (actionType === "") {
                this.isPollingDecisionLetter = false;
                return; // Stop polling once the desired condition is met
              }
            }
          }
          if (this.isComponentActive) {
            this.decisionLetterPolling = setTimeout(poll, 1500);
          }
        } catch (error) {
          console.error("Failed to poll for decision letter:", error);
          if (this.isComponentActive) {
            this.decisionLetterPolling = setTimeout(poll, 1500);
          }
        }
      };
      this.showPollStatusMessage = true;
      poll();
    },
    pollFullDocument() {
      const poll = async () => {
        if (!this.isComponentActive) return; // Exit if the component is no longer active

        const processObj = {
          process: {
            process_id: this.openProcess.process_id,
          },
        };

        try {
          const redactedProcess = await this.$store.dispatch("getRedactedProcess", processObj);
          console.log("Polling full document")
          // redactedProcess should contain full doc and split doc
          if (
            redactedProcess !== undefined &&
            redactedProcess[0] !== undefined &&
            redactedProcess[0].files[0] !== null &&
            this.fullDocumentUpdated !== null && redactedProcess[0].files[0] &&
            new Date(redactedProcess[0].files[0]['updated_at']).getTime() > this.fullDocumentUpdated
            ) {
              if (redactedProcess[0].files.length > 1) {
                this.splittetFileHandlerModal(redactedProcess, this.printOrDownloadKind)
              } else {
                this.fullDocumentFile = redactedProcess[0].files[0];
                this.printOrDownload(this.printOrDownloadKind);
              }

              this.isPollingFullDocument = false;
              return;
          }
        } catch (error) {
          console.error("Failed to poll for full document:", error);
        }
        if (this.isComponentActive) {
          this.fullDocumentPolling = setTimeout(poll, 1500);
        }
      };
      poll();
    },
    splittetFileHandlerModal(redactedProcess, actionType) {
      this.$modal.show(
        SplittetFileChooseForm,
        {
          processId: this.$route.params.processId,
          decisionLetterId: this.decisionLetter.id,
          redactedProcess,
          actionType,
        },
        {
          draggable: false,
          scrollable: true,
          clickToClose: false,
        }
      );
    },
    receivedNewHTML(htmlContent, header, footer) {
      this.debouncedSaveEditableContent(htmlContent, header, footer);
    },
    debouncedSaveEditableContent: debounce(function(html, header, footer) {
      this.saveEditableContent(html, header, footer);
    }, 500),
    saveEditableContent(htmlContent, header, footer) {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel("A new save operation started.");
      }
      // TODO this.decisionLetter.id giver fejl hvis gendanner forrige side
      this.cancelTokenSource = axios.CancelToken.source();
      this.saveOperationPending = true;
      this.$store.dispatch("updateDecisionLetter", {
        id: this.decisionLetter.id,
        data: { content: { htmlContent, header, footer } },
        processId: this.openProcess.process_id,
        cancelToken: this.cancelTokenSource.token,
      })
      .then((res) => {
        // Edit saved.
        // console.log(res)
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log('Previous save operation canceled', error.message);
        } else {
          // Handle actual error (network error, etc.)
        }
      })
      .finally(() => {
        this.saveOperationPending = false;
      });
    },
    async showFinishCaseForm() {
      await this.$store.dispatch(
          "loadOnlyProcess",
          this.$route.params.processId
      );
    },
    fetchFileData(file_obj) {
      const jwt_token = localStorage.getItem('aktio-ai-user-token');
      const axios_headers = { 'Authorization': 'Bearer ' + jwt_token };
      return axios({
        url: file_obj.apiURL,
        method: 'POST',
        data: {
          file: { 
            id: file_obj.fileObj.id,
            name: file_obj.fileObj.name,
            url: file_obj.fileObj.url,
            mime: file_obj.fileObj.mime
          },
          process_id: file_obj.process_id
        },
        responseType: 'blob',
        headers: axios_headers,
      });
    },
    printFile(file_obj) {
      this.fetchFileData(file_obj).then((res) => {
        const blob = new Blob([res.data], { type: 'application/pdf' });
        const blobURL = URL.createObjectURL(blob);

        const iframe = document.createElement('iframe');
        document.body.appendChild(iframe);
        iframe.style.display = 'none';
        iframe.src = blobURL;
        iframe.onload = function() {
          setTimeout(() => {
            iframe.focus();
            iframe.contentWindow.print();
          }, 1);
        };
      });
    }, 
    downloadFile(file_obj) {
      this.fetchFileData(file_obj).then((res) => {
        const fileURL = window.URL.createObjectURL(new Blob([res.data]));
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', file_obj.fileObj.output_filename);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    getDecisionLetterData() {
      const { process_id } = this.openProcess;

      const decisionLetter = this.decisionLetter;
      if (decisionLetter && decisionLetter.file && process_id) {
        let output_filename = 'Afgørelsesbrev med aktliste for aktindsigt sagsid [' + process_id.toString() + '].' + decisionLetter.file.name.split('.').pop()
        if (this.openProcess.law_set.name === "INS") {
          output_filename = 'Brev med dokumentsamling for indsigtssag sagsid [' + process_id.toString() + '].' + decisionLetter.file.name.split('.').pop()
        }
        const fileObj = {
          id: decisionLetter.file.id,
          name: decisionLetter.file.name,
          url: decisionLetter.file.url,
          mime: decisionLetter.file.mime,
          output_filename: output_filename
        }
        return {'fileObj': fileObj, 'process_id': process_id, 'apiURL': this.apiUrl + "/file/get_decision_letter"};
      }
    },
    getFullDocumentData() {
      const { process_id }  = this.openProcess
      const fullDocumentFile = this.fullDocumentFile;

      if (fullDocumentFile && process_id) {
        const fileObj = {
          id: fullDocumentFile.id,
          name: fullDocumentFile.name,
          url: fullDocumentFile.url,
          mime: fullDocumentFile.mime,
          output_filename: 'Dokumenter for aktindsigt sagsid [' + process_id.toString() + '].' + fullDocumentFile.name.split('.').pop()
        }
        return {'fileObj': fileObj, 'process_id': process_id, 'apiURL': this.apiUrl + "/file/get_redacted_documents"}
      }
    },
    async downloadDecisionLetter() {
      this.isPollingDecisionLetter = true;
      this.pollRedactedProcessStatus();
      const processObj = this.getProcessObject();
      
      const response = await this.$store.dispatch("finalizeDecisionLetter", processObj);
      if (response.backend_process_id) {
        this.decisionLetterUpdated = new Date().getTime();
        this.printOrDownloadKind = 'downloadDecisionLetter';
        this.pollDecisionLetter(true, "download");
      }
    },
    async printDecisionLetter() {
      this.isPollingDecisionLetter = true;
      this.pollRedactedProcessStatus();
      const processObj = this.getProcessObject();
      
      const response = await this.$store.dispatch("finalizeDecisionLetter", processObj);
      if (response.backend_process_id) {
        this.decisionLetterUpdated = new Date().getTime();
        this.printOrDownloadKind = 'printDecisionLetter';
        this.pollDecisionLetter(true, "print");
      }
    },
    async downloadFullDocument() {
      this.isPollingFullDocument = true;
      this.pollRedactedProcessStatus();
      const processObj = this.getProcessObject();
      
      const response = await this.$store.dispatch("createFullDocument", processObj);
      if (response.backend_process_id) {
        this.fullDocumentUpdated = new Date().getTime();
        this.printOrDownloadKind = 'downloadFullDocument';
        this.pollFullDocument(true, "download");
      }
    },
    async printFullDocument() {
      this.isPollingFullDocument = true;
      this.pollRedactedProcessStatus();
      const processObj = this.getProcessObject();
      
      const response = await this.$store.dispatch("createFullDocument", processObj);
      if (response.backend_process_id) {
        this.fullDocumentUpdated = new Date().getTime();
        this.printOrDownloadKind = 'printFullDocument';
        this.pollFullDocument(true, "print");
      }
    },
    getProcessObject() {
      return {
        process: {
          id: this.openProcess.id,
          process_id: this.openProcess.process_id,
        },
        decision_letter_id: this.decisionLetter ? this.decisionLetter.id : null,
      };
    },
    async openExportDocModal() {
      await this.$modal.show(
        ExportExternalDocumentsForm,
        {},
        {
          classes: 'overflow-visible',
          width: "70%",
          height: "800px",
          overflowY: "initial",
          scrollable: true,
          draggable: false,
          clickToClose: false,
        }
      );
    },
    checkScreenSize() {
      this.isLargeScreen = window.innerWidth > (window.screen.width / 3) * 2;
    },
  }
};
</script>

<style>
.invisible {
  visibility: hidden;
}

.editor-container {
  max-width: 870px;
  margin-inline: auto;
}

.btn-fixed-width {
  width: 182px;
}

.table-actions {
  max-width: 550px;
  margin-left: 5px;
}

.loading {
  height: 70vh;
  align-items: center;
}

@media (min-width: 1024px) {
  .editor-container {
    max-width: 1200px;
  }
}
</style>