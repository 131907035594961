<template>
  <div id="surveyElement">
    <surveyPlugin
      v-if="$route.params.surveyGuid && survey"
      :survey="survey"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import 'survey-core/defaultV2.min.css';
import { StylesManager, Model } from 'survey-core';
import { surveyPlugin } from 'survey-vue3-ui';

StylesManager.applyTheme("defaultV2");

// const SURVEY_ID = 1;

export default {
  name: 'NewUserSurvey',
  components: {
    surveyPlugin
  },
  data() {
    return {
      survey: undefined,
      surveyData: '',
      firstLogin:false,
    };
  },
  computed: {
    ...mapState(["user", "surveys"]),
  },
  watch: {
    "surveys"(surveys) {
      if(surveys){
        const surveyModel = this.$store.getters.getSurveyByGuid(this.$route.params.surveyGuid);
        const jsondata = {elements: surveyModel.elements};
        let survey = new Model(jsondata);
        survey.onComplete.add(this.surveyComplete);
        survey.completeText = "Send dit svar";
        survey.completedHtml = "Tak fordi du deltog i undersøgelsen."
      
        this.survey = survey
      }
    },
    "user.userData"(userData) {
      this.firstLogin = userData.first_login

      if(!userData.first_login){
        this.$router.push("/processes")
      }
    }
  },
  async mounted() {
    await this.$store.dispatch("getSurveys", this.$route.params.surveyGuid);
    const existingUserData = this.user.userData
    if(existingUserData) {
      this.firstLogin = existingUserData.first_login
    } else {
      this.$store.dispatch("loadUserFromAPI");
    }
  },
  methods: {
    async surveyComplete(sender) {
      const surveyObj = {
        survey_user: {id: this.user.userData.id},
        survey_result: sender.data,
        survey_guid: this.$route.params.surveyGuid
      }
      this.$store.dispatch("posthogCapture", {event_name:"FirstTimeSurvey", data:sender.data});
      this.$store.dispatch("saveSurvey", surveyObj)
      this.$store.dispatch('updateUser', {user_id: this.user.userData.id, first_login:false})
    },
  }
}

</script>
<style>
#surveyElement {
  --primary: #072A57;
  --primary-light: rgba(8, 85, 203, var(--tw-bg-opacity));
  --secondary: #1ab7fa;
  --background: rgba(243, 244, 246, var(--tw-bg-opacity));
  --background-dim: #ffffff;
  --background-dim-light: #ffffff;
  --primary-text-color: #000000;
}
</style>