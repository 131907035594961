<template>
  <div v-click-outside="clickOutside">
    <div class="flex flex-wrap">
      <div
        class=" rounded text-xs m-auto text-white px-2 py-1 whitespace-no-wrap"
        :class="[
          localDocumentStatus.background_color_class || 'bg-gray-500',
          { 'cursor-not-allowed' : disabled}
        ]"
      >
        <font-awesome-icon  
          v-if="localDocumentStatus.is_processing"
          class="animate-spin"
          icon="fa-spinner"
        />
        {{ localDocumentStatus.display_name }}
      </div> 
     
      <div
        v-if="localDocumentStatus.is_excepted_status === true && localFile.law"
        class="flex"
      >
        <redaction-law-paragraph :law-containing-element="localFile" />
      </div>
    </div>
  </div>
</template>

<script>
import LawParagraph from "@/components/controls/LawParagraph.vue";
import LawSelection from "@/components/controls/LawSelection.vue";

export default {
  name: "DocStatus",
  components: {
    "redaction-law-paragraph": LawParagraph,
  },
  props: {
    documentStatus: Object,
    document: Object,
    documentId: Number,
    processId: String,
    selectOptions: Array,
    showStatusTag: {
      type: Boolean,
      default: true
    },
    shouldEmit: {
      type: Boolean,
      default: false,
    },
    openDirection: {
      type: String,
      default: "bottom"
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      showEdit: false,
      localFile: this.document,
      localDocumentStatus: this.documentStatus,
    };
  },
  watch: {
    document(newVal) {
      this.localFile = newVal;
    },
    documentStatus(newVal) {
      this.localDocumentStatus = newVal;
    },
  },
  methods: {
    clickOutside() {
      this.showEdit = false;
    },
    reopenModal() {
      if (this.localDocumentStatus.is_excepted_status && !this.shouldEmit) {
        this.$modal.hideAll();
        this.updateDocStatus();
      }
    },
    updateDocStatus() {
      if (this.localDocumentStatus.is_excepted_status && !this.shouldEmit) {
        this.$modal.show(
          LawSelection,
          {
            paragraphFromRedaction: this.localFile.law,
            element: this.localFile,
            documentId: this.documentId,
            processId: this.processId,
            documentStatus: this.localDocumentStatus,
            update: this.update,
            shouldEmit: this.shouldEmit,
            elementType: "document",
          },
          {
            classes: 'overflow-visible',
            height: "700px",
            width: "60%",
            clickToClose: true,
          },
          {
            "before-close": this.enableScrollAgain,
          }
        );
      } else {
        if (this.shouldEmit) {
          this.$emit("update-document-status", this.localDocumentStatus);
        } else {
          this.$store.dispatch("updateFile", {
            id: this.documentId,
            processId: this.processId,
            document_status: this.localDocumentStatus,
            law: 0,
          });
        }
      }
      this.showEdit = false;
    },
    update(data) {
      this.localFile = data;
    },
    // TODO: move
    enableScrollAgain() {
      document.body.classList.remove("modal-open");
    },
  },
};
</script>
