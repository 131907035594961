<template>
  <div class="flex absolute right-0 mr-16 justify-end">
    <ul>
      <li
        v-for="(notification, notificationIndex) in notifications"
        :key="notificationIndex"
        class="flex justify-between max-w-sm rounded-md p-2 m-4"
        :class="notificationType(notification.type)"
      >
        <p>{{ notification.message }}</p>
        <div>
          <svg
            class="fill-current h-6 w-6"
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            @click="removeAlert(notificationIndex)"
          >
            <path
              d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
            />
          </svg>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
    name: "NotificationAlert",
    computed: {
    ...mapState("notification", {
      notifications: state => state.notifications
    } ),
  }, 
    methods: {
        removeAlert(index){
            this.$store.dispatch("posthogCapture", {event_name:"RemoveAlert", data:{}});

            this.$store.dispatch("notification/removeNotification", index);
        },
        notificationType(type){
        if (type == 'error'){
            return 'bg-red-500';
        }
        else return 'bg-teal-300';
    }
    }
}
</script>>