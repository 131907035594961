<template>
  <div
    contenteditable="true"
    oncut="return false"
    onpaste="return false"
    class="text-annotation-container"
    :class="{ scroll: maxHeight }"
    :style="{ maxHeight: maxHeight }"
    readonly
    @keydown="allowArrowKeysHandler"
    @mouseup="onMouseUp"
    @keyup.w="onMouseUp"
  >
    <span
      v-for="(item, index) in chunks"
      :key="index"
    >
      <span
        v-if="item.type === 'text'"
        :key="index"
        class="text-for-annotation"
        :class="{ 'text-indent': isTextIndent(index)}"
      >{{ item.content }}</span>
      <br
        v-if="item.type === 'wrap'"
        :key="index"
      >
      <text-annotation 
        v-if="item.type === 'annotation'"
        :key="index"
        contenteditable="false"
        :text-annotation="item"
        :annotation-text-color="annotationTextColor"
        :annotation-bg-color="item.color"
        :data-masking="dataMasking"
        :data-masking-charactor="dataMaskingCharacter"
        @delete-annotation="deleteAnnotation"
        @edit-annotation="editAnnotation"
      />
    </span>
    <transition name="panelIn">
      <input-dialog
        v-if="showInputDialog"
        contenteditable="false"
        :pos-x="inputDialogPosX"
        :pos-y="inputDialogPosY"
        :input-dialog-width="inputDialogWidth"
        :annotation-text-color="annotationTextColor"
        :annotation-bg-color="annotationBgColor"
        :align="inputDialogAlign"
        :edit-object="editAnnotationObject"
        @add-annotation="addAnnotation"
        @edit-annotation="addEditedAnnotation"
      />
    </transition>
  </div>
</template>

<script>
import InputDialog from "./InputDialog.vue";
import TextAnnotation from "./TextAnnotation.vue";
export default {
  name: 'TextContainer',
  components: {
    TextAnnotation,
    InputDialog,
  },
  props: {
    text: String,
    categories: Array,
    modelValue: Array,
    annotationTextColor: {
      type: String,
      default: '#35495e'
    },
    annotationBgColor: {
      type: String,
      default: '#41b883'
    },
    dataMasking: {
      type: Boolean,
      default: false,
    },
    dataMaskingCharacter: {
      type: String,
      default: '●',
    },
    replaceCharacter: {
      type: String,
      default: '*',
    },
    maxHeight: {
      type: Number,
      default: null
    },
    pageNumber: Number
  },
  data() {
    return {
      textAnnotations: [],
      textAfterMasking: null,
      chunks: [],
      selectingContent: null,
      selectingContentStartIndex: null,
      showInputDialog: false,
      inputDialogPosX: '',
      inputDialogPosY: '',
      inputDialogAlign: "left",
      inputDialogWidth: 320,
      editAnnotationObject: null
    }
  },
  watch: {
    selectingContent(value){
      this.showInputDialog = !(value === "" || value === null)
    },
    textAnnotations(annotations){
      const entities = annotations.slice().sort((a, b) => a.start - b.start);
      const chunks = this.generateChunks(this.text, entities);
      this.chunks = chunks;
      this.$emit("input", annotations);
      if (this.dataMasking) {
        this.$emit("afterDataMasking", this.getTextAfterMasking(annotations));
      }
    }
  },
  created() {
    this.textAnnotations = this.modelValue || []
  },
  methods: {
    allowArrowKeysHandler(event) {
      if (event.target.className !== "text-annotation-container bg-white") {
        return true
      }
      const elements = document.getElementsByClassName('text-annotation-container');
      for (let i = 0; i < elements.length; i++) {
        if (document.activeElement === elements[i]) {
          if (event.metaKey || event.keyCode === 37 || event.keyCode === 38 || event.keyCode === 39 || event.keyCode === 40) {
            return true;
          }
        }
      }
      event.preventDefault();
      return false;
    },
    isTextIndent(index){
      if (index === 0) return true;
      const previousChunk = this.chunks[index - 1];
      return previousChunk.type === "wrap"
    },
    getTextAfterMasking(textAnnotations){
      let afterMasking = this.text;
      textAnnotations.forEach((item) => {
        afterMasking =
          afterMasking.substr(0, item.start) +
          this.replaceCharacter.repeat(item.content.length) +
          afterMasking.substr(item.start + item.content.length);
      });
      return afterMasking;
    },
    generateTextChunks(text){
      if(!text) {
        return []
      }
      let chunks = [];
      const snippets = text.split("\n");
      for (const snippet of snippets.slice(0, -1)) {
        chunks.push({
          type: "text",
          content: snippet,
        });
        chunks.push({
          type: "wrap",
          content: "↵",
        });
      }
      chunks.push({
        type: "text",
        content: snippets.slice(-1)[0],
      });
      return chunks;
    },
    generateChunks(text, entities){
      if(!text){
        return []
      }
      let chunks = [];
      let startOffset;
      let bgColor = "41b883";
      // to count the number of characters correctly.
      const characters = text.split("");
      for (const entity of entities) {
        if (entity.state !== "deleted") {
          this.categories.map(category => {
            if (String(category.guid) === String(entity.category.guid)) {
              bgColor = category.bg_color
            }
          });
          // add non-entities to chunks.
          let piece = characters.slice(startOffset, entity.start).join("");
          chunks = chunks.concat(this.generateTextChunks(piece));
          startOffset = entity.end;
          // add entities to chunks.
          piece = characters.slice(entity.start, entity.end).join("");
          chunks.push({
            id: entity.id,
            type: "annotation",
            content: piece,
            start: entity.start,
            annotation: entity.annotation,
            color: bgColor
          });
        }
      }
      // add the rest of text.
      chunks = chunks.concat(
        this.generateTextChunks(
          characters.slice(startOffset, characters.length).join("")
        )
      );
      return chunks;
    },
    getPrevNodesLength(content){
      const nodeIndex = this.chunks.findIndex((item) => item.content === content);
      let prevNodesLength = 0;
      for (let i = 0; i < nodeIndex; i++) {
        prevNodesLength += this.chunks[i].content.length;
      }
      return prevNodesLength;
    },
    editAnnotation({editPosX, editPosY, annotation}) {  
      const viewportWidth = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );
      this.inputDialogWidth = 320;
          if (viewportWidth - editPosX > this.inputDialogWidth) {
            this.inputDialogPosX = editPosX + "px";
            this.inputDialogAlign = "left";
          } else {
            this.inputDialogPosX = editPosX - 320 + "px";
            this.inputDialogAlign = "right";
          }
          this.inputDialogPosY = editPosY + window.scrollY + 5 + "px";
          // update selectingContent
          let word = annotation.content + "";
          word = word.replace(/^\s+|\s+$/g, "");
          this.selectingContent = word.length > 0 ? word : null;
          const selectionString = annotation.content + "";
          this.selectingContent = selectionString.trim().length
            ? selectionString
            : null;
          this.selectingContentStartIndex = annotation.start
          this.editAnnotationObject = annotation
    },
    onMouseUp(event){
      const {target} = event
      // if click on `ok` button, pass
      if (target.classList.contains("add-annotation-button")) return;
      // effective when mouseover on class `text-for-annotation`, expecially unavailable on `annotation` 
      if (
        target.classList.contains("text-for-annotation") ||
        target.classList.contains("text-annotation-container")
      ) {
        this.editAnnotationObject = null;
        const selection = window.getSelection();
        if (selection !== null && selection.toString().length) {
          const range = selection.getRangeAt(0).cloneRange();
          const rect = range.getBoundingClientRect();
          // update input dialog position
          const viewportWidth = Math.max(
            document.documentElement.clientWidth || 0,
            window.innerWidth || 0
          );
          // this.inputDialogWidth = Math.max(rect.width, 200);
          this.inputDialogWidth = 320;
          if (viewportWidth - rect.left > this.inputDialogWidth) {
            this.inputDialogPosX = rect.left + "px";
            this.inputDialogAlign = "left";
          } else {
            this.inputDialogPosX = rect.right - 320 + "px";
            this.inputDialogAlign = "right";
          }
          this.inputDialogPosY = rect.bottom + window.scrollY + 5 + "px";
          // update selectingContent
          let word = selection + "";
          word = word.replace(/^\s+|\s+$/g, "");
          this.selectingContent = word.length > 0 ? word : null;
          const anchorOffset = selection.anchorOffset;
          const focusOffset = selection.focusOffset;
          const anchorNode = selection.anchorNode;
          const anchorNodeData = anchorNode.data;
          const selectionString = selection + "";
          this.selectingContent = selectionString.trim().length
            ? selectionString
            : null;
          this.selectingContentStartIndex =
            this.getPrevNodesLength(anchorNodeData) +
            Math.min(anchorOffset, focusOffset);
        } else {
          this.selectingContent = null;
          this.selectingContentStartIndex = null;
        }
      }
    },
    deleteAnnotation(annotationObj) {
      this.textAnnotations = this.textAnnotations.map(item => {
        if (item.id === annotationObj.id) {
            item.state = "deleted"
        }
        return item
      });
      this.$emit("update-annotations", {annotations: this.textAnnotations, pageNumber: this.pageNumber});
      this.selectingContent = null;
      this.selectingContentStartIndex = null;
    },
    addAnnotation(annotationObj){
      const { annotation, category } = annotationObj;  
      if (
        annotation.length &&
        typeof this.selectingContent == "string" &&
        this.selectingContent.length &&
        this.selectingContentStartIndex !== null
      ) {
        this.textAnnotations = this.textAnnotations.concat({
          id: this.textAnnotations.length + 1,
          state: "user_created",
          content: this.selectingContent,
          annotation,
          start: this.selectingContentStartIndex,
          end: this.selectingContentStartIndex + this.selectingContent.length,
          created: new Date().toUTCString(),
          category
        });
        this.$emit("update-annotations", {annotations: this.textAnnotations, pageNumber: this.pageNumber});
      }
      this.selectingContent = null;
      this.selectingContentStartIndex = null;
    },
    addEditedAnnotation(annotationObj){ //IN PROGRESS
      this.textAnnotations = this.textAnnotations.map(item => {
        if (item.id === annotationObj.id) {
            if ("user_edited" !== item.state) {
              item.previousState = item.state
            }
            item.state = "user_edited"
            item.annotation = annotationObj.annotation
            item.category = {guid: annotationObj.category.guid, unsure: annotationObj.category.unsure}
            item.edited = new Date().toUTCString()
        }
        return item
      });
     
      this.$emit("update-annotations", {annotations: this.textAnnotations, pageNumber: this.pageNumber});

      this.selectingContent = null;
      this.selectingContentStartIndex = null;
    }
  }
}
</script>

<style>
.text-annotation-container {
  border: 2px solid #e7ebe5;
  border-radius: 8px;
  line-height: 1.8;
  padding: 10px 10px 10px 7px;
  text-align: left;
  white-space: pre-wrap;
  margin: 0 0 10px 0;
}
.text-annotation-container .text-for-annotation {
  margin: 0px;
  padding: 0px;
  text-indent: 2em;
}
.text-annotation-container.scroll {
  overflow: scroll;
/* custom scrollbar */
}
.text-annotation-container.scroll::-webkit-scrollbar {
  width: 20px;
  height: 0px;
}
.text-annotation-container.scroll::-webkit-scrollbar-track {
  background-color: transparent;
}
.text-annotation-container.scroll::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}
.text-annotation-container.scroll::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
.panelIn-enter {
  transform: translateY(-10px);
}
.panelIn-enter-active {
  transition: transform 0.3s ease;
}
.panelIn-enter-to {
  transform: translateY(0);
}
</style>