<!--@todo https://www.glyphicons.com/sets/halflings/#copy-duplicate-->
<template>
  <table
    v-if="!$route.params.processId"
    class="table-auto w-full shadow-lg bg-white"
  >
    <tr>
      <th
        v-for="header in processTableHeaders"
        :key="header.text"
        class="bg-aktio-grey border px-4"
        :class="[
          header.showDocIcon
            ? 'flex justify-center py-3'
            : 'text-left py-2',
        ]"
      >
        <component
          :is="docIconComponent"
          v-if="header.showDocIcon"
          class="w-4 h-4 documentIcon"
        />
        <span v-else>
          {{ header.text }}
        </span>
      </th>
    </tr>
    <tr v-if="isLoading && processes.length === 0">
      <td
        :colspan="processTableHeaders.length"
        class="border p-4"
      >
        Indlæser sager...
        <font-awesome-icon
          icon="fa-spinner"
          class="md:mr-2 md:mb-1 sm:mb-2 animate-spin"
        />
      </td>
    </tr>
    <tr v-else-if="processes.length === 0">
      <td
        :colspan="processTableHeaders.length"
        class="border p-4"
      >
        Ingen sager
      </td>
    </tr>
    <tr
      v-for="row in processes"
      :key="row.id"
    >
      <td class="border p-4">
        <div class="text-xs flex space-x-2 mb-2">
          <p>Sagsid: {{ row.process_id }}</p> <button
            v-if="canCopy"
            class="mx-4"
            @click="copy(row.process_id, 'Sagsid')"
          >
            <case-icon-copy class="h-3 w-3" />
          </button>
          <p class="pl-10">
            Oprettet af: {{ returnCreatedBy(row) }}
          </p>
        </div>
        <div class="flex justify-between">
          <div>
            <div
              v-if="row.law_set"
              class="text-xs flex space-x-2"
            >
              <div>{{ returnHeaderName(row.law_set) }}</div>
              <a
                class="px-2 py-0.5 bg-gray-300 rounded-sm mr-1"
                :title="row.law_set.display_name"
              >{{ row.law_set.name }}</a>
              <case-icon-rpa
                v-if="row.request_process_type === 'rpa'"
                class="h-4 w-4"
              />
              <!--              <span class="bg-gray-200 text-blue-800 text-xs font-semibold px-2 py-0.5 rounded dark:bg-aktio-darkblue-900 dark:text-blue-900 h-full ml-1">-->
              <!--                  {{returnNoDocuments(row.documents) > 1 ? `${returnNoDocuments(row.documents)} dokumenter` : returnNoDocuments(row.documents) === 1 ? `1 dokument` : '0 dokumenter'}}-->
              <!--              </span>-->
            </div>
            <a
              class="cursor-pointer"
              @click="goToProcess(row)"
            >
              {{ row.display_name }}
            </a>
          </div>
          <div class="flex justify-between">
            <div
              v-if="
                row.request_subjects &&
                  row.request_subjects.length > 0 &&
                  row.law_set
              "
              class="mx-2 w-24"
            >
              <div
                v-for="requestSubject in row.request_subjects"
                :key="requestSubject.id"
              >
                <div
                  v-if="requestSubject.cpr"
                  class="text-xs"
                >
                  CPR <button
                    v-if="canCopy"
                    class="mx-4"
                    @click="copy(requestSubject.cpr, 'CPR')"
                  >
                    <case-icon-copy class="h-3 w-3" />
                  </button>
                </div>
                <div>{{ returnCPRformatted(requestSubject.cpr) }}</div>
              </div>
            </div>
            <div
              v-else-if="row.request_person_cpr && row.law_set"
              class="mx-2 w-24"
            >
              <div
                v-if="row.request_person_cpr"
                class="text-xs"
              >
                CPR  <button
                  v-if="canCopy"
                  class="mx-4"
                  @click="copy(row.request_person_cpr, 'CPR')"
                >
                  <case-icon-copy class="h-3 w-3" />
                </button>
              </div>
              <div>{{ returnCPRformatted(row.request_person_cpr) }}</div>
            </div>
          </div>
        </div>
      </td>
      <td class="border p-4">
        <redaction-process-dates :process="row" />
      </td>
      <!--      <td class="border p-4 text-center">-->
      <!--        <div v-if="row.process_status.name !== 'Færdigbehandlet'">-->
      <!--            <pre>{{ returnDateDiff(Date.now(), row.request_deadline_date) }}</pre>-->
      <!--        </div>-->
      <!--        <div v-else>-->
      <!--          <div v-if="row.process_status.name === 'Færdigbehandlet'">-->
      <!--            <div class="flex justify-center">-->
      <!--              <redaction-process-status :process="row" />-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </td>-->
      <td class="border p-4 text-center">
        {{ returnNoDocuments(row.document_count) }}
      </td>
      <td class="border p-4">
        <div class="flex items-center">
          <div class="flex items-center">
            <standard-button
              class="h-9 mr-2 documentButton justify-start mb-0.5"
              button-title="Se sagens dokumenter"
              button-type="standard"
              button-icon="doc"
              :button-text="returnNoDocuments(row.document_count) > 0 ? 'Dokumentoversigt' : 'Dokumentoversigt'"
              button-text-small
              @click="goToProcess(row)"
            />
            <standard-button
              v-if="hideIfProcessFinished(row.process_status)"
              class="h-9 mr-2 mb-0.5"
              button-title="Rediger sagens stamdata"
              button-type="standard"
              button-icon="edit"
              button-text="Stamdata"
              button-text-small
              @click="showEditProcess(row.process_id)"
            />
            <standard-button
              v-if="row.users.length > 1"
              class="h-9 mr-2 justify-start"
              button-title="Fjern adgang"
              button-type="delete"
              :button-text="'Fjern adgang'"
              button-text-small
              @click="openRemoveUserModal(row.users, row.id)"
            />
          </div>
        </div>
      </td>
      <td class="border p-4">
        <div class="flex justify-left">
          <standard-button
            class="h-9 mr-2 self-center"
            button-title="Slet sagen"
            button-type="delete"
            button-icon-fa="fa-trash-can"
            button-text-small
            @click="showConfirmDeleteProcessModal(row.id, row.process_id, row.display_name)"
          />
        </div>
      </td>
    </tr>
  </table>
</template>

<script>
import { mapState } from "vuex";
import ProgressDates from "@/components/controls/ProcessDates.vue";
import EditCaseForm from "@/components/forms/EditCaseForm.vue";
import ConfirmDeleteProcessForm from "@/components/forms/ConfirmDeleteProcessForm.vue"
import StandardButton from "@/components/controls/StandardButton.vue";
import FormRemoveUser from "@/components/forms/ConfirmRemoveUserForm.vue";

// import ProcessStatus from "@/components/controls/ProcessStatus.vue";
import iconCopy from "@/components/icons/copy.vue";
import iconRobot from "@/components/icons/robot.vue";
import DocIcon from '@/components/icons/doc.vue';
// import dayjs from "dayjs";

export default {
  name: "ProcessTable",
  components: {
    "redaction-process-dates": ProgressDates,
    StandardButton,
    // "redaction-process-status": ProcessStatus,
    "case-icon-copy": iconCopy,
    "case-icon-rpa": iconRobot,
  },
  data() {
    return {
      canCopy:false,
      processTableHeaders: [
        { text: "Sag", },
        { text: "Datoer" },
        // { text: "Frist" },
        { text: "Dokumenter", showDocIcon: true},
        { text: "Handlinger"},
        { text: ""}
      ],
      localProcesses: [],
      isLoading: true,
    };
  },
  computed: {
    ...mapState(["processes", "documentFinishStatus"]),
    docIconComponent() {
      return DocIcon;
    },
  },
  watch: {
    processes:{
      async handler() {
        this.localProcesses = this.processes;
        this.isLoading = false
      },
      deep:true
    }
  },
  created() {
    this.canCopy = !!navigator.clipboard;
  },
  methods: {
    hideIfProcessFinished(docStatus) {
      if (docStatus && docStatus.is_finished) {
        return false;
      } else {
        return true;
      }
    },
    openRemoveUserModal(users, id) {
      this.$modal.show(
        FormRemoveUser,
        { id, users },
        { classes: 'overflow-visible' }
      );
    },
    async showEditProcess(processId) {
      //await this.$store.dispatch("posthogCapture", {event_name:"EditCase", data: { processId: processId }});

      //TODO: consider loading in modal instead
      await this.$store.dispatch("loadSpecificProcess", processId);
      // await this.$store.dispatch("getDocumentation", processId)
      this.$modal.show(
        EditCaseForm,
        {
          processId: processId,
        },
        {
          classes: 'overflow-visible',
          height: "auto",
          scrollable: true,
        },
        {
          // closed: this.clearDocumentation,
        }
      );
    },
    async showConfirmDeleteProcessModal(processId, processGuid, processName) {

       this.$modal.show(
        ConfirmDeleteProcessForm,
        {
          processId: processId,
          processGuid: processGuid,
          processName: processName
        },
      );
      this.$store.dispatch("posthogCapture", {event_name:"DeleteCase", data:{ processGuid: processGuid }});
    },
    returnHeaderName(law_set) {
      if (law_set && law_set.name === "OFL") {
        return "Emne";
      } else {
        return "Navn";
      }
    },
    clearDocumentation(evt) {
      if (evt.state === "closed") {
        this.$store.commit("clearDocumentationToUpload");
      }
    },
    returnCPRformatted(cpr) {
      if (cpr) {
        return cpr.substring(0, 6) + "-" + cpr.substring(6);
      } else {
        return "";
      }
    },
    returnCreatedBy(process) {
      let created_by = "Ukendt";
      if (process) {
        if (process.created_by) {
          created_by = process.created_by
        } else {
          if (process.users[0].first_name) {
            created_by = process.users[0].first_name + ' ' + process.users[0].last_name
          }
        }
      }
      return created_by
    },
    async goToProcess(process) {
      this.$store.dispatch("posthogCapture", {event_name:"ViewCase", data:{ processId: process.process_id }});
      this.$store.commit("setFiles", [])
      this.$store.commit("setOpenProcess", process)
      // TODO: move to VUEX -also in Home.vue. Consider where to laod things - before router change or after?
      //await this.$store.commit("setProcessName", {process_id: process.process_id, display_name: process.display_name})
      // await this.$store.dispatch("loadFilesFromApi", process.process_id);
      this.$router.push({ params: { processId: process.process_id } });
    },
    returnNoDocuments(document_count) {
      if (document_count) {
        return document_count;
      } else {
        return 0;
      }
    },
    returnUpdatedBy(row) {
      let fullname = "";
      if (row && row.updated_by && row.updated_by.firstname) {
        fullname = row.updated_by.firstname;
      }
      if (row && row.updated_by && row.updated_by.lastname) {
        fullname = fullname + " " + row.updated_by.lastname;
      }
      return fullname;
    },
    async copy(s, text) {
      await navigator.clipboard.writeText(s);
      const toast_message = text + ' er kopieret til udklipsholderen.';
      this.$showRegisterToast(toast_message, "info", 4000, {})
      this.$store.dispatch("posthogCapture", {event_name:"CopyText", data:{copyText: text}});
    },
    // returnDateDiff(fromDate, toDate) {
    //   let day_diff = parseInt(Math.ceil(dayjs(toDate).diff(fromDate, "day", true)))
    //   if (day_diff > 0) {
    //     return 'Om \n' + day_diff + ' dage';
    //   } else if (day_diff === 0) {
    //     return 'I dag';
    //   } else {
    //     return 'Overskredet \n' + Math.abs(day_diff) + ' dage';
    //   }
    //
    // },
  },
};
</script>

<style>
.documentButton {
  min-width: 200px;
}
.documentIcon g {
  fill: black;
}
</style>