<template>
  <div
    v-if="acadreConnectionStatus"
    class="p-8 model-size"
  >
    <redaction-button-exit
      class="absolute right-0 top-0 m-2"
      @emit-close="closeModal('close')"
    />
    <h1 class="text-xl text-center">
      Importer Eksterne Dokumenter
    </h1>

    <!-- Search field -->
    <div class="relative py-2 search-container">
      <multiselect
        v-model="multiselector.value"
        :show-labels="false"
        track-by="name"
        label="name"
        placeholder="Vælg en"
        :options="multiselector.options"
        :searchable="false"
        :allow-empty="false"
      >
        <!-- <template slot="singleLabel" slot-scope="{ option }"> -->
        <template #singleLabel="{ option }">
          <strong class="text-aktio-pink-900">{{ option.name }}</strong> er det valgte at kunne importer fra
        </template>
      </multiselect>
      <div class="flex font-semibold mt-2">
        {{ multiselector.value.title }}
      </div>
      <div class="flex space-x-2">
        <input 
          id="Sag"
          v-model="acadre.acadreSearchText"
          :placeholder="multiselector.value.placeholder"
          type="text"
          class="flex-1 my-1 block py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          @keyup.enter="!acadre.isLoading && acadreSearchWithValidate()" 
        >
        <standard-button 
          v-if="!acadre.isLoading"
          :key="'not-loading'" 
          button-size="small"
          button-text="Søg"
          button-icon="pdf" 
          button-type="standard"
          class="my-1"
          @click="acadreSearchWithValidate()"
        />
        <standard-button 
          v-else
          :key="'loading'" 
          button-size="small"
          button-text="Søg"
          button-icon="spinner" 
          button-type="standard"
          class="my-1"
        />
      </div>
      <div
        v-if="inputError.showError"
        class="text-red-500 text-xs"
      >
        {{ inputError.errorMessage }}
      </div>
    </div>

    <!-- Case/Documents list -->
    <div
      v-if="searchPerformed"
      class="border border-gray-200 rounded-lg overflow-hidden"
    >
      <div
        v-if="acadreCaseResult && acadreCaseResult.length || acadreDocumentsResult && acadreDocumentsResult.length"
        class="flex space-x-3"
      >
        <!-- Case list (first table) -->
        <div class="flex-1 border-r overflow-x-auto">
          <div class="table-title py-1 text-center bg-aktio-darkblue-900 text-white">
            Sager
          </div>
          <div class="table-responsive overflow-x-auto">
            <table class="min-w-full bg-white">
              <thead class="bg-aktio-grey sticky top-0 z-10 thead-size">
                <tr>
                  <th class="py-2 px-2 border border-gray-200 text-left">
                    Sager
                  </th>
                  <th class="py-2 px-2 border border-gray-200 text-left">
                    Bemærkning
                  </th>
                  <th class="py-2 px-2 border border-gray-200 text-left">
                    Sagsnr.
                  </th>
                  <th class="py-2 px-2 border-left border-b border-gray-200 text-left">
                    Dato
                  </th>
                  <th class="py-2 px-2 border-b-2 border-gray-200 text-left spinner-column" />
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(acadreCase, index) in acadreCaseResult" 
                  :key="acadreCase.id"
                  class="curser-pointer hover:bg-gray-100"
                  :class="{'selected-row': selectedIndex === index}"
                  title="Klik for at søge på denne sag"
                  @click="acadreSearchWithValidate(acadreCase.caseInfo.caseNumber, index)"
                >
                  <td class="py-1 px-2 border">
                    {{ acadreCase.CaseFileTitleText }}
                  </td>
                  <td class="py-1 px-2 border">
                    {{ acadreCase.caseInfo.comment }}
                  </td>
                  <td class="py-1 px-2 border">
                    {{ acadreCase.caseInfo.caseNumber }}
                  </td>
                  <td class="py-1 px-2 border-left border-b">
                    {{ acadreCase.caseInfo.creationDate }}
                  </td>
                  <td class="py-1 border-b spinner-column text-center">
                    <redaction-icon-spinner
                      v-if="loadingRowIndex === index"
                      color="black"
                    />
                    <span
                      v-else-if="selectedIndex === index && loadingRowIndex === null"
                      class="arrow"
                    >➜</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- Document list (second table) -->
        <div class="flex-1 border-l overflow-x-auto">
          <div class="table-title py-1 text-center bg-aktio-darkblue-900 text-white">
            Dokumenter
          </div>
          <div class="table-responsive overflow-x-auto">
            <table
              v-if="acadreDocumentsResult && acadreDocumentsResult.length"
              class="min-w-full bg-white"
            >
              <thead class="bg-aktio-grey sticky top-0 z-10 thead-size">
                <tr>
                  <th class="py-2 pe-4 border border-gray-200">
                    <label
                      for="select-all"
                      class="ml-2"
                    >Vælg/Fravælg alle</label>
                    <br>
                    <input
                      id="select-all"
                      type="checkbox"
                      :checked="areAllSelected('acadreDocumentsResult')"
                      @change="toggleSelectAll('acadreDocumentsResult', $event)"
                    >
                  </th>
                  <th class="py-2 px-4 border border-gray-200 text-left">
                    Title på dokument
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="document in acadreDocumentsResult"
                  :key="document.Document.DocumentIdentifier" 
                  :class="document.supported_format ? 'supported-format hover:bg-gray-100' : 'unsupported-format'"
                >
                  <td
                    v-if="document.supported_format"
                    class="py-1 pe-4 border text-center"
                  >
                    <input
                      v-model="document.checked"
                      type="checkbox"
                      @change="handleDocumentCheckChange(document)"
                    >
                  </td>
                  <td
                    v-else
                    class="py-1 px-4 border text-center text-red-500"
                  >
                    Denne fil format er ikke understøttet.
                  </td>
                  <td class="py-1 px-4 border">
                    {{ document.Document.DocumentTitleText }}
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              v-else
              class="min-w-full bg-white"
            >
              <thead class="bg-aktio-grey sticky top-0 z-10 thead-size">
                <tr><th class="py-2 px-4 border border-gray-200" /></tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    v-if="!acadre.isLoading && acadreDocumentsResult.length === 0"
                    class="py-1 px-4 text-center"
                  >
                    Ingen resultater fundet.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div
        v-else-if="!acadre.isLoading && acadreCaseResult.length === 0 || !acadre.isLoading && acadreDocumentsResult.length === 0"
        class="text-center py-2"
      >
        Ingen resultater fundet.
      </div>
    </div>
    <!-- End Case/Documents list -->
    <!-- Added documents list -->
    <div
      v-if="acadreSelectedDocuments && acadreSelectedDocuments.length"
      class="border border-gray-200 rounded-lg overflow-hidden mt-2"
    >
      <div class="flex space-x-3">
        <!-- Selected Document list -->
        <div class="flex-1">
          <div class="table-title py-1 text-center bg-aktio-pink-900 text-white">
            Valgte dokumenter
          </div>
          <div class="selected-table-responsive overflow-x-auto">
            <table class="min-w-full bg-white">
              <thead class="bg-aktio-grey sticky top-0 z-10">
                <tr>
                  <th class="py-2 px-4 border border-gray-200 text-left">
                    Fra sag
                  </th>
                  <th class="py-2 px-4 border border-gray-200 text-left">
                    Sagsnr.
                  </th>
                  <th class="py-2 px-4 border border-gray-200 text-left">
                    Title på dokument
                  </th>
                  <th class="py-2 px-4 border border-gray-200 action-buttons-size">
                    Handlinger
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="doc in acadreSelectedDocuments"
                  :key="doc.document.Document.DocumentIdentifier"
                  class="hover:bg-gray-100"
                >
                  <td class="py-1 px-4 border">
                    {{ doc.acadreCase.CaseFileTitleText }}
                  </td>
                  <td class="py-1 px-4 border">
                    {{ doc.acadreCase.caseInfo.caseNumber }}
                  </td>
                  <td class="py-1 px-4 border">
                    {{ doc.document.Document.DocumentTitleText }}
                  </td>
                  <td class="py-1 px-4 border text-center">
                    <standard-button 
                      button-size="small" 
                      button-text-small
                      button-text="Fjern" 
                      button-icon="remove"
                      button-type="delete"
                      button-title="Fjern dette dokument fra denne liste (valgte dokumenter)"
                      class="my-1"
                      @click="removeSelectedDocument(doc.document.Document.DocumentIdentifier)" 
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- End Added documents list -->
    <div class="button-cancel-save">
      <standard-button
        button-size="small"
        button-text="Annuller"
        button-type="cancel"
        class="mr-1"
        @click="cancelAcadreImport()"
      />
      <standard-button
        button-size="small"
        button-text="Gem"
        button-type="standard"
        class="ml-1"
        @click="closeModal('save')"
      />
    </div>
  </div>
  <div
    v-else
    class="p-8 model-size"
  >
    <redaction-button-exit
      class="absolute right-0 top-0 m-2"
      @emit-close="closeModal()"
    />
    <h1 class="text-xl text-center">
      Importer Eksterne Dokumenter
    </h1>
    <div class="relative py-2 search-container">
      <multiselect
        v-model="multiselector.value"
        :show-labels="false"
        track-by="name"
        label="name"
        placeholder="Vælg en"
        :options="multiselector.options"
        :searchable="false"
        :allow-empty="false"
      >
        <!-- <template slot="singleLabel" slot-scope="{ option }"> -->
        <template #singleLabel="{ option }">
          <strong class="text-aktio-pink-900">{{ option.name }}</strong> er det valgte at kunne importer fra
        </template>
      </multiselect>
      <h2 class="text-center text-red-500 mt-4">
        Systemet kan ikke oprette forbindelse til Acadre
      </h2>
    </div>
  </div>
</template>
  
<script>
import { mapState } from "vuex";
import Multiselect from "vue-multiselect";
import StandardButton from "@/components/controls/StandardButton.vue";
import buttonExit from "@/components/controls/buttonExit.vue";
import iconSpinner from "@/components/icons/spinner.vue";

export default {
  name: "ModalImportExternalDocuments",
  components: {
    Multiselect,
    "redaction-button-exit": buttonExit,
    StandardButton,
    "redaction-icon-spinner": iconSpinner,
  },
  data() {
    // These options is for multiselector. (Multiselect)
    const options = [
      { 
        name: 'Acadre', 
        title: 'Søg efter Acadre sag/sagsnummer', 
        placeholder: 'Indtast venligst et Sag/Sagsnummer (ex. for sag: 0000000000 eller for sagsnummer: 00/0).' 
      },
      {
        name: 'SBsys',
        title: 'Skriv CPR',
        placeholder: 'Indtast venligst et CPR Nr.',
      },
      { 
        name: 'Cura', 
      },
    ];

    return {
      acadre: {
        acadreSearchText: "",
        isLoading: false,
      },
      loadingRowIndex: null,
      searchPerformed: false, // Indicates if a search has been made
      selectedIndex: null,
      inputError: {
        timeoutId: null,
        errorMessage: "",
        showError: false,
      },
      multiselector: {
        value: options[0],
        options: options
      }
    };
  },
  computed: {
    ...mapState('acadre', [
      "acadreCaseResult",
      "acadreDocumentsResult",
      "acadreSelectedDocuments",
      "acadreConnectionStatus"
    ]),
  },
  async mounted() {
    await this.$store.dispatch("loadUserFromAPI");
    await this.$store.dispatch("acadre/checkAcadreConnection")
  },
  methods: {
    closeModal(type) {
      if (type === "save") {
        this.$store.commit("acadre/setChosenAcadreDocumentsCount", this.acadreSelectedDocuments);
      }
      this.$emit("close");
    },
    searchAcadreCases() {
      this.$store.commit("acadre/setAcadreDocumentsResult", []);
      const searchObject = {
        // username: "",
        // password: "",
        case_title: this.acadre.acadreSearchText,
        // case_type: "",
        // case_subtype: "",
        // case_responsible_id: "",
        // administrative_unit_id: "",
        // public_access_level_id: "",
        // classification_criterion_type: [],
        // custom_field: [],
      }
      this.$store.dispatch("acadre/searchAcadreForCases", searchObject).then(() => {
        this.searchPerformed = true;
        this.acadre.isLoading = false;
      });
    },
    searchAcadreDocument(acadreSearchText) {
      const searchObject = {
        case_title: acadreSearchText
      };
      this.$store.dispatch("acadre/searchAcadreForDocuments", searchObject).then(() => {
        this.searchPerformed = true;
        this.$store.dispatch("acadre/updateDocumentSelectionState");
        this.acadre.isLoading = false;
        this.loadingRowIndex = null;
      });
    },
    acadreSearchWithValidate(query = null, index) {
      const searchText = query || this.acadre.acadreSearchText;
      const regex = /^\d{2}\/\d+$/;
      const isCaseNumber = regex.test(searchText);

      if (searchText.length < 3) {
        this.showErrorMessage("Der skal minimum være 3 karakter.");
        return;
      }

      if (query) {
        this.loadingRowIndex = index;
        this.selectedIndex = index;
      } else {
        this.acadre.isLoading = true;
        this.selectedIndex = -1;
        this.$store.commit("acadre/setAcadreCaseResult", []);
        this.$store.commit("acadre/setAcadreDocumentsResult", []);
      }

      if (isCaseNumber) {
        this.searchAcadreDocument(searchText);
      } else {
        this.searchAcadreCases();
      }
      this.acadre.acadreSearchText = "";
    },
    areAllSelected(resultType) {
      console.log("areAllSelected", this[resultType]);
      return this[resultType].length && this[resultType].every(doc => doc.checked);
    },
    toggleSelectAll(resultType, event) {
      // Set the 'checked' property of all documents to the checked state of the "Select All" checkbox
      const newValue = event.target.checked;
      this[resultType].forEach(doc => {
        if (doc.supported_format) {
          doc.checked = newValue;
          this.handleDocumentCheckChange(doc);
        }
      })
    },
    handleDocumentCheckChange(document) {
      const index = this.acadreSelectedDocuments.findIndex(doc => doc.document.Document.DocumentIdentifier === document.Document.DocumentIdentifier);
      let acadreCase;
      if (this.acadreCaseResult && this.acadreCaseResult.length) {
        acadreCase = this.acadreCaseResult[this.selectedIndex];
      } else {
        acadreCase = document.acadreCase;
      }
      
      if (document.checked  && index === -1) {
        this.acadreSelectedDocuments.push({document, acadreCase});
      } else if (!document.checked && index !== -1) {
        this.acadreSelectedDocuments.splice(index, 1);
      }
    },
    removeSelectedDocument(docId) {
      const index = this.acadreSelectedDocuments.findIndex(doc => doc.document.Document.DocumentIdentifier === docId);
      if (index !== -1) {
        this.acadreSelectedDocuments.splice(index, 1);
        this.$store.dispatch("acadre/updateDocumentSelectionState");
      }
    },
    /**
     * ShowErrorMessage
     * Takes a message and is for the input error.
     * It will clear the timeout if there already existing one 
     * and then it will make a new setTimeout.
     * 
     * setTimeout is set to 3 sec.
     */
    showErrorMessage(message) {
      this.inputError.errorMessage = message;
      this.inputError.showError = true;

      // If there already is made a timeout then clear it
      if (this.inputError.timeoutId) {
        clearTimeout(this.inputError.timeoutId);
      }

      this.inputError.timeoutId = setTimeout(() => {
        this.inputError.showError = false;
        this.inputError.errorMessage = "";
      }, 3000);
    },
    cancelAcadreImport() {
      this.acadreSelectedDocuments.length = 0;
      this.closeModal();
    },
  },
};
</script>

<style>
.model-size {
  min-height: 85vh;
  padding-bottom: 80px;
}

.search-container {
  max-width: 50%;
  margin: auto;
}

.search-bar {
  margin-bottom: 20px;
}

.table-title {
  border-bottom: 1px solid #e5e7eb;
  font-weight: bold;
}

.action-buttons-size {
  width: 250px;
}

.table-responsive,
.selected-table-responsive{
  max-height: 250px;
  overflow-y: auto;
}

.thead-size {
  height: 90px;
}

.curser-pointer {
  cursor: pointer;
}

.selected-row {
  background-color: #f0f0f0;
}

.arrow {
  content: '➜';
  color: #007bff;
  font-size: 18px;
}

.spinner-column {
  width: 40px;
}

.supported-format {
  background-color: #fff;
}

.unsupported-format {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.button-cancel-save {
  position: absolute;
  bottom: 30px;
  right: 40px;
}
</style>
