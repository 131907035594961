<template>
  <ul class="flex">
    <!-- TODO: add max width for process names  -->
    <li class="ml-4">
      <a
        class="text-aktio-darkblue-900 bg-gray-200 block rounded px-4 py-2 hover:bg-gray-300 hover:border-aktio-darkblue-500 hover:text-aktio-darkblue-900 cursor-pointer"
        :class="{ 'nav-button block rounded px-4 py-2': navDashboard }"
        @click="navTo('/')"
      >Forside</a>
    </li>
    <li class="ml-2">
      <a
        class="text-aktio-darkblue-900 bg-gray-200 block rounded px-4 py-2 hover:bg-gray-300 hover:border-aktio-darkblue-500 hover:text-aktio-darkblue-900 cursor-pointer"
        :class="{ 'nav-button block rounded px-4 py-2': navProcesses }"
        @click="navTo('/processes')"
      >Sager</a>
    </li>
    <li class="ml-2">
      <standard-button 
        v-if="!$route.params.processId && !$route.params.documentId"
        button-text="Opret ny sag" 
        button-icon="plus" 
        @click="openNewCaseModal" 
      />
    </li>
  </ul>
</template>

<script>
import { mapState } from "vuex";
import StandardButton from "@/components/controls/StandardButton.vue";
import EditCaseForm from "@/components/forms/EditCaseForm.vue";

export default {
  name: "TopNav",
  components: {
    StandardButton,
  },
  computed: {
    navDashboard() {
      return this.$route.name === "Dashboard";
    },
    navProcesses() {
      return this.$route.name && (this.$route.name.includes("Processes") || this.$route.name.includes("DocumentViewer"));
    },
    ...mapState('acadre', [
      "acadreSelectedDocuments",
    ]),
  },
  methods: {
    navTo(nav) {
      this.$router.push(nav);
    },
    openNewCaseModal() {
      this.$store.dispatch("posthogCapture", { event_name: "CreateCase", data: {} });

      // Clear variable
      this.acadreSelectedDocuments.length = 0;

      this.$modal.show(
        EditCaseForm,
        {},
        {
          classes: 'overflow-visible',
          height: "auto",
          scrollable: true,
          clickToClose: false,
        },
      );
    },
  },
};
</script>

<style>
  .nav-button {
    background-color: rgb(219, 89, 164);
    color: white;
    /* background-color: rgb(189, 193, 199); */
  }
  
  .nav-button:hover {
    background-color: rgb(199, 79, 144);
    color: white;
    /* background-color: rgb(169, 173, 179); */
  }
</style>