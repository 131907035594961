<template>
  <div class="w-full">
    <redaction-button-exit
      class="absolute right-0 top-0 m-2"
      @emit-close="closeModal()"
    />
    <input 
      class="bg-white h-10 w-full px-5 rounded-lg border text-sm focus:outline-none" 
      v-model="searchQuery" 
      @input="searchAddress" 
      placeholder="Søg efter adresse" 
      type="text"
    />
    <ul v-if="results.length">
      <li class="border-b hover:bg-gray-300 cursor-pointer pointer py-2" v-for="result in results" :key="result.adresse.id" @click="selectAddress(result.adresse.href)">
        <span class="ml-2 bg-white-100 text-aktio-darkblue-900">{{ formatAdresse(result) }}</span>
      </li>
    </ul>
  </div>
</template>
  
  <script>
  import { mapState } from "vuex";
  import axios from 'axios';
  import buttonExit from "@/components/controls/buttonExit.vue";
  
  export default {
    name: "ModalAdressForm",
    components: {
      "redaction-button-exit": buttonExit,
    },
    data() {
      return {
        searchQuery: '',
        results: [],
        selectedAddress: null,
        bfeNumber: null
      };
    },
    computed: {
      ...mapState('acadre', [
        "ejendomCaseData",
        "ejendomNumber",
      ]),
      ...mapState([
        "openProcess",
      ])
    },
    methods: {
      closeModal() {
            this.$emit("close");
      },
      async searchAddress() {
        if (this.searchQuery.length > 3) {
          try {
            const response = await axios.get(`https://api.dataforsyningen.dk/datavask/adresser`, {
              params: {
                betegnelse: this.searchQuery
              }
            });
            this.results = response.data.resultater.slice(0, 10);
          } catch (error) {
            console.error(error);
          }
        }
      },
      async selectAddress(addressHref) {
        try {
          const addressResponse = await axios.get(addressHref);
          this.selectedAddress = addressResponse.data;
          const jordstykkeHref = this.selectedAddress.adgangsadresse.jordstykke.href;
          const jordstykkeResponse = await axios.get(jordstykkeHref);
          await this.$store.dispatch("acadre/getBBRNumber", jordstykkeResponse.data.bfenummer)
          const matrikelnummer = this.selectedAddress.adgangsadresse.jordstykke.matrikelnr + ", " + this.selectedAddress.adgangsadresse.jordstykke.ejerlav.navn
          const ejendomCaseData = {
            adress: this.selectedAddress.adressebetegnelse,
            matrikelnummer: matrikelnummer,
            bfeNumber: jordstykkeResponse.data.bfenummer,
            ejendomsnr: this.ejendomNumber,
            streetName: this.selectedAddress.adgangsadresse.vejstykke.navn,
            houseNumber: this.selectedAddress.adgangsadresse.husnr,
            postCode: this.selectedAddress.adgangsadresse.postnummer.nr,
            city: this.selectedAddress.adgangsadresse.postnummer.navn,
          }
          this.$store.commit("acadre/setEjendomCaseData", ejendomCaseData)
          this.closeModal()
        } catch (error) {
          console.error(error);
        }
      },
      formatAdresse(result) {
        let cleanAdress
        if (result.adresse.vejnavn) {
          cleanAdress = result.adresse.vejnavn
          if (result.adresse.husnr) {
            cleanAdress += " " + result.adresse.husnr + ","
            if (result.adresse.etage) {
              cleanAdress += " " + result.adresse.etage + "."
              if (result.adresse.dør) {
                cleanAdress += " " + result.adresse.dør + ","
              } else {
                cleanAdress += ","
              }
            }
          } else {
            cleanAdress += ","
          }
          cleanAdress += " "
        }
        if (result.adresse.postnr) {
          cleanAdress += result.adresse.postnr + " " + result.adresse.postnrnavn
        }
        return cleanAdress
      }
    }
  };
  </script>
  
  <style scoped>
  /* Tilføj din CSS her */
  </style>