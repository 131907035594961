<template>
  <div class="p-8">
    <redaction-button-exit
      class="absolute right-0 top-0 m-2"
      @emit-close="closeModal()"
    />
    <h1 class="text-xl">
      Del sagen med en kollega
    </h1>
    <div class="md:py-2">
      <div>
        <label
          for="case_name"
          class="mt-4 block text-sm font-medium text-gray-700"
        >
          Søg på navn eller e-mail og vælg den kollega, du vil dele sagen med
        </label>
        <multiselect
          v-model="collaborator"
          placeholder="Skriv min 2 tegn af navn eller e-mail for at søge."
          select-label=""
          selected-label=""
          deselect-label=""
          :options="shareUsers"
          :multiple="false"
          track-by="username"
          :custom-label="customLabel"
          :close-on-select="true"
          :internal-search="false"
          @search-change="searchChangeHandler"
        >
          <!-- <span slot="noResult">
                {{ searchText }}
          </span>
          <span slot="noOptions">

          </span> -->
          <template #noResult>
            <span>{{ searchText }}</span>
          </template>
          
          <template #noOptions>
            <span><!-- Ingen indhold, muligvis for en tom visning --></span>
          </template>
        </multiselect>
      </div>
    </div>
    <div class="mt-4 block text-sm font-medium text-gray-700">
      Skriv en besked til din kollega
    </div>
    <textarea
      id="request_message"
      v-model="process.message"
      placeholder="Skriv en besked den kollega du deler sagen med"
      class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
    />
    <div class="md:py-2">
      <div class="text-xs">
        Denne besked bliver sendt til din kollega sammen med et link til sagen.
      </div>
    </div>
    <div class="py-3 mb-4">
      <standard-button
        v-if="$route.params.processId && !$route.params.documentId"
        class="w-full"
        button-type="standard-disableable"
        button-text="Send invitation"
        button-icon="spinner"
        :show-icon="isSending"
        :button-disabled="!collaborator || !process.message"
        @click="shareCase()"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Multiselect from "vue-multiselect";
import buttonExit from "@/components/controls/buttonExit.vue";
import StandardButton from "@/components/controls/StandardButton.vue";
import _ from "lodash";

export default {
  name: "FormShareCase",
  components: {
    Multiselect,
    "redaction-button-exit": buttonExit,
    StandardButton,
  },
  data() {
    return {
      showEdit: true,
      collaborator: undefined,
      isSending: false,
      searchText: "Vent venligst - søger...",
      process: {
        message: "",
      },
    };
  },

  computed: {
    ...mapState(["openProcess", "processes", "strapiUrl", "user", "shareUsers"]),

    localProcess: {
      get() {
        return this.openProcess;
      },
      set(val) {
        this.localProcess = val;
      },
    },
  },
  watch: {
    shareUsers: function(newVal) {
      if (Object.keys(newVal).length === 0) {
        this.searchText = "Ingen kollegaer blev fundet."
      } else {
        this.searchText = ""
      }
    }
  },
  async mounted() {
    if (
      this.processId &&
      this.openProcess &&
      this.processId === this.openProcess.process_id
    ) {
      this.isEdit = true;
      this.process = this.openProcess;
    }
    this.process.document_types = this.documentTypes;
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async shareCase() {
      this.$store.dispatch("posthogCapture", {event_name:"ShareCase", data:{}});

      if (this.collaborator && this.process.message) {
        this.isSending = true;
        let processWithUserData = this.openProcess;
        processWithUserData["users"].push({id: this.collaborator.id});

        //collaborateRequest = process id, user id<receiver>, request status, msg
        let collaborateRequest = {
          process: this.openProcess,
          assigned_user: [this.collaborator],
          message: this.process.message,
          request_user_data: this.user.userData,
          // request_status : ,
        };

        const processResponse = await this.$store.dispatch(
          "updateProcessUser",
          processWithUserData
        );
        const collabResponse = await this.$store.dispatch(
          "pushCollaborateRequest",
          collaborateRequest
        );

        // Reset list of search result list
        this.$store.commit('setShareUsers', {data: []})

        if ((await processResponse.id) && (await collabResponse.id)) {
          this.isSending = false;
          this.$emit("close");
        }
      }
    },

    customLabel(option) {
      return option.value
    },
     searchChangeHandler: _.debounce(function (query) {
      if (query.length > 1) {
        const searchObj = {
          processId: this.openProcess.process_id,
          query
        }
        this.searchText = "Vent venligst - søger..."
        this.$store.dispatch("getUsersToShareWith", searchObj);
    } else {
        this.searchText = "Skriv mere end 1 tegn."
        this.$store.commit('setShareUsers', {data: []})
      }
  }, 700),
  },
};
</script>
