<template>
  <div>
    <div 
      class="flex pt-2 pb-2 items-center fixed w-full bg-gray-100 justify-between z-50"
      style="width: calc(100% - 3rem)"
    >
      <button @click="goBack()">
        <font-awesome-icon  
          class="mr-2"
          icon="fa-arrow-left"
        />
        <span class="text-l font-semibold">Dokumentoversigt</span>
      </button>
      <div class="ml-10 flex items-left">
        <span class="text-l font-semibold">Dokument: {{ openFile.display_name }}</span>
      </div>
      <div class="flex">
        <multiselect
          id="annotation-category"   
          v-model="annotationCategory"
          class="w-250"
          name="annotation-category"
          :options="annotationCategories"
          :loading="!annotationCategory || !annotationCategories"
          :multiple="false"
          :searchable="false"
          :allow-empty="false"
          :close-on-select="true"
          :custom-label="customAnnotationsCategoryLabel"
          track-by="guid"
          :option-height="150"
          select-label
          deselect-label
          selected-label
        />
        <div class="flex items-center ml-2 pr-6 border-gray-300 border-r-2">
          <toggle-switch
            v-model="unsureAnnotationCategory"
            :disabled="!openFile"
          /> 
          <span class="ml-2">Usikker</span>
        </div>
        <div class="flex">
          <div
            v-if="!editTextToggle"
            class="flex"
          >
            <button 
              class="bg-aktio-darkblue-600 hover:bg-aktio-darkblue-700 focus:outline-none rounded px-2 py-2 ml-2 mr-1 text-white text-sm shadow inline-flex items-center"
              @click="editTextToggle = !editTextToggle; showToast()"
            >
              Rediger Tekst
            </button>
            <div
              v-if="!confirmSearch"
              class="flex"
            >
              <button 
                class="bg-red-600 hover:bg-red-700 focus:outline-none rounded px-2 py-2 ml-1 mr-2 text-white text-sm shadow inline-flex items-center"
                @click="confirmSearch = !confirmSearch"
              >
                Søg Entiteter
              </button>
            </div>
            <div
              v-else
              class="flex"
            >
              <button 
                class="bg-red-600 hover:bg-red-700 focus:outline-none rounded px-2 py-2 ml-1 mr-1 text-white text-sm shadow inline-flex items-center"
                @click="reSearch"
              >
                Søg
              </button>

              <button 
                class="bg-aktio-darkblue-600 hover:bg-aktio-darkblue-700 focus:outline-none rounded px-2 py-2 ml-1 mr-2 text-white text-sm shadow inline-flex items-center"
                @click="confirmSearch = !confirmSearch"
              >
                Annuller
              </button>
            </div>
          </div>
          <div
            v-else
            class="flex"
          >
            <button 
              class="bg-aktio-darkblue-600 hover:bg-aktio-darkblue-700 focus:outline-none rounded px-2 py-2 ml-2 mr-1 text-white text-sm shadow inline-flex items-center"
              @click="saveTextNormal = true"
            >
              Gem Ændringer
            </button>

            <button 
              class="bg-aktio-darkblue-600 hover:bg-aktio-darkblue-700 focus:outline-none rounded px-2 py-2 ml-1 mr-2 text-white text-sm shadow inline-flex items-center"
              @click="editTextToggle = !editTextToggle"
            >
              Annuller
            </button>
          </div>
        </div>
        <div
          v-show="disableFinishButton()"
          class="flex items-center ml-6"
        >
          <toggle-switch
            v-model="localShouldBeChecked"
            :disabled="!openFile"
          /> 
          <span class="ml-2">Færdigbehandlet</span>
        </div>
      </div>
    </div>
    <div class="flex flex-col pt-14">
      <div
        v-if="failedToLoad"
        class="justify-self-center self-center mt-10"
      >
        <span class="text-2xl font-semibold">Indlæsningen gik for hurtigt prøv igen</span>
      </div>
      <div
        v-else-if="!pagesText || pagesText.length === 0"
        class="justify-self-center self-center mt-10"
      >
        <span class="text-2xl font-semibold">Henter tekst...</span>
      </div>
      <div v-if="!failedToLoad">
        <div 
          v-for="(pageText, index) in pagesText"
          :key="`annotation${index}`" 
          class="flex items-center"
        >
          <span class="mr-2 whitespace-nowrap w-16">Side {{ index+1 }}</span>
          <div
            class="flex flex-col"
            style="width: calc(100% - 4rem)"
          >
            <div v-if="editTextToggle">
              <TextEditContainer
                ref="textEditContainer"
                :save-text-status="saveTextStatus"
                :save-text-normal="saveTextNormal"
                :text="pageText"
                :page-number="index+1"
                @update-text="updateText"
              />
            </div>
            <div v-else>
              <TextContainer 
                v-model="textAnnotationsLocal[index]"
                class="bg-white"
                :text="pageText"
                :categories="nerAnnotationCategories"
                :page-number="index+1"
                @update-annotations="updateAnnotations"
              />
            </div>
            
            <div class="bg-gray-100 h-4" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import _ from "lodash"
import { mapState } from "vuex";
import Multiselect from "vue-multiselect";
import TextContainer from "@/components/nerViewer/TextContainer.vue";
import TextEditContainer from "@/components/nerViewer/TextEditContainer.vue";
import TextAnnotation from "@/components/nerViewer/TextAnnotation.vue";
import StandardButton from "@/components/controls/StandardButton.vue";
import FEATURE_FLAGS from '@/constants/featureflags';
import annotationCategoriesFromJSON from '../assets/json/ss_annotation_categories.json';
import nerAnnotationCategoriesFromJSON from '../assets/json/ner_annotation_categories.json';
import ToggleSwitch from "@/components/controls/ToggleSwitch.vue";

const PDFJS = require("pdfjs-dist/build/pdf.js");

PDFJS.GlobalWorkerOptions.workerSrc = "//" + location.host + "/static/pdf.worker.js";
const CMAP_URL = "../../node_modules/pdfjs-dist/cmaps/";
const CMAP_PACKED = true;
const BASE64_MARKER = ";base64,";

// Convert base64 data to Uint8array data
const convertDataURIToBinary = (dataURI) => {
  const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  const base64 = dataURI.substring(base64Index);
  const raw = window.atob(base64);
  const rawLength = raw.length;
  const array = new Uint8Array(new ArrayBuffer(rawLength));

  for (let i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
};

export default {
  name: "TextAnnotateViewer",
  components: {
    Multiselect,
    StandardButton,
    TextContainer,
    TextEditContainer,
    TextAnnotation,
    ToggleSwitch,
  },
  data() {
    return {
      pagesText: [],
      editedPagesText: [],
      textAnnotationsPrevious: [],
      textAnnotationsLocal: [],
      localShouldBeChecked: false,
      annotationCategory: undefined,
      annotationCategories: [],
      nerAnnotationCategories: [],
      unsureAnnotationCategory: false,
      editTextToggle: false,
      saveTextStatus: false,
      confirmSearch: false,
      saveTextNormal: false,
      failedToLoad: false,
    };
  },
  computed: {
    ...mapState([
      "openFile",
      "openProcess",
      "entitySearchStatus",
      "nerData"
    ]),
  },
  watch: {
    async localShouldBeChecked(checked){
      const obj = {
        id: this.openFile.id,
        documentId: this.openFile.id,
        processId: this.openFile.process.process_id,
        data: {
          text_annotate_completed: checked
        }
      }
      this.$store.dispatch("setDocumentTextAnnotateData", obj);
    },
    async unsureAnnotationCategory(unsure){
      const obj = {
        id: this.openFile.id,
        documentId: this.openFile.id,
        processId: this.openFile.process.process_id,
        data: {
          text_annotate_category_is_unsure: unsure
        }
      }
      this.$store.dispatch("setDocumentTextAnnotateData", obj);
    },
    async annotationCategory(category){
      if(this.openFile.text_annotate_category_guid != category.guid){
        const obj = {
          id: this.openFile.id,
          documentId: this.openFile.id,
          processId: this.openFile.process.process_id,
          data: {
            text_annotate_category_guid: category.sort_order > 0 ? category.guid : null
          }
        }
        this.$store.dispatch("setDocumentTextAnnotateData", obj);
      }
    },
    async "nerData" (nData){
      if(nData && nData.text_annotation && nData.pdf_text_clean){
        const annotations = Object.keys(nData.text_annotation).map(key => {
          return nData.text_annotation[key].map(({annotation}) => annotation)
        })
        if (nData.pdf_text_user_edited) {
          this.pagesText = nData.pdf_text_user_edited
        } else {
          this.pagesText = nData.pdf_text_clean
        }
        this.textAnnotationsPrevious = [...annotations]
        this.textAnnotationsLocal = [...annotations]
      }
    },
  },
  async mounted() {
    if (this.openFile.id) {
      await this.$store.dispatch("fetchNerData", this.openFile.id)
      if (this.nerData.pdf_text_user_edited) {
        this.pagesText = this.nerData.pdf_text_user_edited
      } else {
        this.pagesText = this.nerData.pdf_text_clean
      }
      const annotations = Object.keys(this.nerData.text_annotation).map(key => {
        return this.nerData.text_annotation[key].map(({annotation}) => annotation)
      })
      this.textAnnotationsPrevious = [...annotations]
      this.textAnnotationsLocal = [...annotations]
    } else {
      this.pagesText = []
      this.failedToLoad = true
    }
    this.localShouldBeChecked = this.openFile.text_annotate_completed
    this.unsureAnnotationCategory = this.openFile.text_annotate_category_is_unsure

    const textAnnotationCategory = annotationCategoriesFromJSON.find(({guid}) => guid === this.openFile.text_annotate_category_guid)

    if(textAnnotationCategory){
      this.annotationCategory = textAnnotationCategory
    } else {
      // Default is first annotate category with sort order 1 (this cannot be selected, is just placeholder)
      this.annotationCategory = annotationCategoriesFromJSON.find(({sort_order}) => sort_order === 0);
    }
    this.annotationCategories = annotationCategoriesFromJSON.sort((a,b) => a.sort_order > b.sort_order)
    this.nerAnnotationCategories = nerAnnotationCategoriesFromJSON.sort((a,b) => a.sort_order > b.sort_order)
  },
  beforeUnmount() {
    // Reset file data such that a new file will be loaded properly 
    // on each new creation of component
    this.$store.commit("resetFileData")
    this.$store.commit("setNerData", {})
  },
  methods: {
    customAnnotationsCategoryLabel({description, tag_name, sort_order }){
      if(sort_order === 0){
        return description;
      }
      return `${description}` 
    },
    pollDocumentStatuses() {
      this.documentStatusPolling = setInterval(() => {
        const docObj = {processId: this.$store.state.openProcess.process_id}
        this.$store.dispatch("pollDocumentStatuses", docObj)
      }, 3000)
    },
    async updateAnnotations({annotations, pageNumber}){
      const textAnnotationObject = this.nerData.text_annotation || {};
      const key = `page_no:${pageNumber}`
      const annotationsFormatted = annotations.map(annotation => ({
        annotation
      }))
      textAnnotationObject[key] = annotationsFormatted;
      
      const updatedNerData = this.nerData 
      updatedNerData.text_annotation = JSON.stringify(textAnnotationObject)
      await this.$store.dispatch("updateNerData", updatedNerData)
    },
    updateText({text}){
      this.editedPagesText.push(text);
      if(this.editedPagesText.length === this.pagesText.length) {
        const updatedNerData = this.nerData 
        updatedNerData.pdf_text_user_edited = JSON.stringify(this.editedPagesText)

        this.$store.dispatch("updateNerData", updatedNerData)
        this.pagesText = this.editedPagesText
        this.editTextToggle = false;
        this.saveTextNormal = false;
        this.editedPagesText = []
      }
    },
    async reSearch() {
        const documentChangesObj = {
          id: this.openFile.id,
          processId: this.openFile.process.process_id,
          data: {
            document_status: {
              id: this.entitySearchStatus.id
            },
          }
        }
      // Update document status to finished 
      await this.$store.dispatch("setDocumentStatus", documentChangesObj)
      const pages = []
      this.pagesText.map(page => {pages.push({user_page: page})})
      const entObj = {
        processId: this.openFile.process.process_id,
        data: {
          process_id: this.openFile.process.process_id,
          doc_id: this.openFile.id,
          pages_text: pages
        }
      }
      await this.$store.dispatch("reSearchEntities", entObj)
      
      //this.pollDocumentStatuses(pollObj);
      await this.$router.push({name: "Processes", params: { processId: this.openFile.process.process_id }});
    },
    disableFinishButton() {
      return this.$featureflag(FEATURE_FLAGS.enableToggleFinishDocument)
    },
    showToast() {
      const msg = "Redigering af tekst ændrer måske placeringen af annoteringen på den side (Du kan søge igen for at fikse det efter)"
      this.$showRegisterToast(msg, 'warning', 5000, {})
    },
    async goBack() {
      const randomNum = Math.random()*100;
      await this.$router.push({name: "DocumentViewer",
          params: { 
            processId: this.$route.params.processId,
            documentId: this.$route.params.documentId,
            pageNumber: 1, 
            randomNum
          },
      });
    },
  },
};
</script>

<style>
  .w-250 {
    width: 250px;
  }
</style>