<template>
  <div
    :id="'drag-drop-' + documentationType"
    class="w-full h-full border border-dashed border-gray-500 text-sm"
  >
    <!-- documentation allready uploaded -->
    <!-- <ul v-if="openDocumentations" class="px-2">
      <li v-for="(doc, docIndex) in openDocumentations" :key="docIndex">
        <div class="flex my-1 text-sm" v-if="doc.type === documentationType">
          <redaction-icon-pdf class="h-4 w-4 mr-2" />{{ doc.display_name }}
          <a
            @click="
              $store.dispatch('removeDocumentation', doc.id)
            "
            class="mx-2 px-2 text-sm inline-flex justify-center cursor-pointer shadow-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >x
          </a>
        </div>
      </li>
    </ul> -->
    <!-- documentation to be uploaded -->
    <ul
      v-if="documentationToUpload"
      class="px-2"
    >
      <li
        v-for="(doc, docIndex) in documentationToUpload"
        :key="docIndex"
      >
        <div
          v-if="doc.docInfo.type === documentationType"
          class="flex my-1 text-sm"
        >
          <redaction-icon-pdf class="h-4 w-4 mr-2" />{{
            doc.docInfo.display_name
          }}
          <a
            class="h-6 w-6 mx-2 px-2 text-sm inline-flex justify-center cursor-pointer shadow-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            @click="$store.commit('removeDocumentationToUpload', docIndex)"
          >
            x
          </a>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
import iconPdf from "@/components/icons/pdf.vue";

import Uppy from '@uppy/core'
import DragDrop from '@uppy/drag-drop'

export default {
  name: "DocumentationUpload",
  components: {
    "redaction-icon-pdf": iconPdf,
  },
  props: {
    documentationType: {
      type: String,
      default: "original_request",
    },
    uploadText: {
      type: String,
      default: "Klik her for at tilføje anmodning(er)",
    },
  },
  data() {
    return {
      uppyDragDrop: false,
      unAllowedFileList: [],
    };
  },
  computed: {
    ...mapState([
      "documentationToUpload",
      "openDocumentations",
      "allowedFileTypes",
    ]),
  },
  watch: {
    unAllowedFileList(unallowedFiles) {
      if (unallowedFiles && unallowedFiles.length > 0) {
        const toast_message = "Filerne:\n\n" + unallowedFiles.join("\n") + "\n\nkan ikke overføres"
        this.$showRegisterToast(toast_message, "warning", 4000, {ProcessGuid: this.processId})
      }
    },
  },
  mounted() {
    if (!this.uppyDragDrop) {
      this.uppyDragDrop = new Uppy({
        debug: false,
        autoProceed: true,
        onBeforeFileAdded: (currentFile) => {
          const currentFileName = currentFile.name || false;
          if (currentFileName) {
            const currentExt = currentFileName.substr(
              currentFileName.lastIndexOf(".")
            );
            const isAllowed = this.allowedFileTypes.some(
              (e) => e === currentExt
            );
            if (!isAllowed) {
              this.unAllowedFileList.push(currentFileName);
            }
            this.$store.dispatch("posthogCapture", {event_name:"RequestDocumentation", data: { allowed_file_extension: true, extension: currentExt }})
          }
        },
        restrictions: {
          allowedFileTypes: this.allowedFileTypes,
        },
        locale: {
          strings: {
            // Text to show on the droppable area.
            dropHereOr: this.uploadText,
          },
        },
      });
    }
    this.uppyDragDrop
      .use(DragDrop, { target: "#drag-drop-" + this.documentationType })
      .on("file-added", (file) => {
        let fileName = file.name;
        if (file.extension && file.extension.length > 0) {
          fileName = file.name.slice(0, -1 * file.extension.length - 1);
        }
        const fileInfo = {
          file: file.data,
          docInfo: {
            name: fileName,
            display_name: fileName,
            extension: file.extension,
            type: this.documentationType,
          },
        };
        this.$store.commit("pushDocumentationToUpload", fileInfo);
      });
  },
};
</script>

<style>
/* hide icons created by uppy */
.uppy-DragDrop-arrow {
  display: none;
}
</style>