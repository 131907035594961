export default {
  install(app, store) {
    app.config.globalProperties.$showRegisterToast = (toastMessage, toastType, duration, payload) => {
      if (!duration) {
        duration = 2000
      }
      app.config.globalProperties.$toast.open({ message: toastMessage, type: toastType.toLowerCase(), duration: duration });

      let posthog_data = { Text: toastMessage, ToastType: toastType.toUpperCase() }
      posthog_data = { ...posthog_data, ...payload }
      store.dispatch("posthogCapture", { event_name: "Toast", data: posthog_data })
    }
  }
}
/* export default {
  install(Vue, store) {
    Vue.prototype.$showRegisterToast = (toastMessage, toastType, duration, payload) => {
      if (!duration) {
        duration = 2000
      }
      Vue.$toast.open({ message: toastMessage, type: toastType.toLowerCase(), duration: duration });

      let posthog_data = { Text: toastMessage, ToastType: toastType.toUpperCase() }
      posthog_data = { ...posthog_data, ...payload }
      store.dispatch("posthogCapture", { event_name: "Toast", data: posthog_data })
    }
  }
} */