import posthog from 'posthog-js';

export default {
  install(app) {
    app.config.globalProperties.$featureflag = (flagName) => {
      if (flagName === undefined) {
        console.log('A flag name is required');
        return;
      }
      try {
        return posthog.isFeatureEnabled(flagName); // { send_event: false }
      } catch {
        console.log('posthog is not initialized');
        return false;
      }
    };
  }
};