<template>
  <div class="flex w-full text-gray-800">
    <input
      v-model="localSearchTerm"
      type="search"
      name="redaction-search"
      autocomplete="off"
      placeholder="Søg i dokumentet"
      class="bg-white h-10 w-full px-5 rounded-lg border text-sm focus:outline-none"
      @focus="$emit('show-refiner', true)"
      v-on:keyup.enter="submit(true)"
      v-on:focus="setFocus(true)" 
      v-on:blur="setFocus(false)"
    >
    <standard-button
      class="ml-1"
      :disabled="waitingForSearch"
      button-title="Search"
      button-type="standard"
      button-icon-fa="fa-magnifying-glass"
      button-text="Søg"
      @click="submit(false)"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { v4 as uuidv4 } from "uuid";
import StandardButton from "@/components/controls/StandardButton.vue";

export default {
  name: "SearchBox",
  components: {
    StandardButton,
  },
   data() {
    return {
      isFocused: false,
      localSearchTerm: ''
    };
  },
  computed: {
    ...mapState([
      "searchTerm",
      "waitingForSearch",
      "searchPDFResults",
    ]),
  },
  mounted() {
    if(this.searchTerm){
      this.localSearchTerm = this.searchTerm
    }
  },
  methods: {
    // Keep track of input focus state to avoid
    // unnecessary search triggers
    setFocus(isFocused) {
      this.isFocused = isFocused;
    },
    async submit(checkForFocus) {
        this.$store.dispatch("posthogCapture", {event_name:"SubmitSearchTerm", data: {}});
        // Prevent other 'enter' key press events
        // to trigger search unless explicitly set
      if(checkForFocus && !this.isFocused){
        return
      }
      const searchObj = {
        "searchTerm": this.localSearchTerm,
        "documentId": this.$route.params.documentId,
        "processId": this.$route.params.processId
      }
      this.$store.commit("setCurrentSearchType", "text")
      const emptyResult = {query_result: []};
      this.$store.commit("setPageSearchResults", emptyResult);
      this.$store.commit("setWaitingForSearch", true)
      await this.$store.dispatch("setSearchTerm", searchObj);
      this.sendSearches();
    },
    async sendSearches() {
      let searchForDocument = []
      const searchObject = this.transformData(this.searchPDFResults)
      searchObject.forEach((search, index) => {
        searchForDocument.push(
          {
            ...search,
            number: index + 1
          }
        )
      })
      const event = new CustomEvent('setSearches', { detail: searchForDocument })
      window.document.querySelector('#pdfViewerIFrame').contentDocument.dispatchEvent(event)
    },
    transformData(inputData) {
      if (!inputData || !Array.isArray(inputData) || inputData.length === 0) {
        console.error("Invalid input data");
        return [];
      }
      // Extract the actual data from the outer array
      const actualData = inputData[0];
      return actualData.flatMap(page => 
        (page.page_matches || []).map(matchItem => {
          const { match } = matchItem;
          const fullText = match.map(m => m.text).join(" ");
          return {
            comment: page.display_name,
            guid: null, // page.document_id,
            page_number: page.page_number,
            id: page.page_number + "-" + uuidv4(),
            redacted_text: fullText,
            positions: match.map(matchData => ({
              height: (matchData.bottom * 1.33227731) - (matchData.top * 1.33227731),
              text: matchData.text,
              ner: matchData.ner,
              id: matchData.match_id.toString(),
              oldY: 0,
              page_scale: 4 / 3,
              width: (matchData.x1 * 1.33227731) - (matchData.x0 * 1.33227731),
              x: matchData.x0 * 1.33227731,
              y: matchData.top * 1.33227731,
            })),
          };
        })
      );
    },
  }
};
</script>

<style scoped>
/* clears the ‘X’ from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
} /* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
</style>