<template>
  <div
    v-if="paragraph"
    class="text-sm font-normal tracking-wide px-1"
  >
    <span class="px-2 bg-gray-300 rounded-sm mr-1">{{
      lawContainingElement.law.name
    }}</span>
    <a
      @click="goToParagraph(lawContainingElement.law.id)"
    >{{ paragraph }}</a>
  </div>
</template>

<script>
export default {
  name: "LawParagraph",
  props: {
    lawContainingElement: Object,
  },
  computed: {
    paragraph() {
      let paragraph = "";
      const law = this.lawContainingElement.law || {};
      if (law.paragraph && law.stk) {
        if (law.stk === "1") {
          paragraph = "§ " + law.paragraph;
        } else {
          paragraph = "§ " + law.paragraph + ", stk. " + law.stk;
        }
      }
      return paragraph;
    },
  },
  methods: {
    goToParagraph(redactionId) {
      console.log("NAV to: " + redactionId);
    },
  },
};
</script>

<style>
</style>