const getDefaultState = () => ({
    notifications: []
})

const notification = {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        createNotification(state, payload) {
            state.notifications.push(payload);
        },
        removeNotification(state, index) {
            state.notifications.splice(index, 1);
        },
        resetState(state) {
            Object.assign(state, getDefaultState())
        }
    },
    // call from any components by "this.$store.dispatch("notification/addNotification", {message:String,error:Boolean,timeout:Number});"
    //if error not specified, default is info message, if timeout not specified message is displayed until closed manually

    actions: {
        addNotification({ commit, dispatch }, payload) {
            commit('createNotification', payload);
            if (payload.timeout > 0) {
                setTimeout(() => { dispatch('removeNotification', payload.index) }, payload.timeout)
            }
        },
        removeNotification({ commit }, index) {
            commit('removeNotification', index);
        }
    }
}

export default notification;