<template>
  <div class="w-full flex items-center justify-center">
    <!-- Steps -->
    <div 
      v-for="step in steps" 
      :key="step.number" 
      class="h-8 flex items-center cursor-pointer"
      @click="() => updateStep(step.number)"
    >
      <div 
        class="rounded-full p-2 border-2 h-8 w-8 flex items-center justify-center"
        :class="[
          {
            'border-blue-600' : activeStep === step.number,
            'border-green-500' : step.completed,
          },
        ]"
      >
        <span
          v-if="step.completed && activeStep !== step.number"
          class="text-green-500"
        >
          ✔
        </span>
        <span 
          v-else
          :class="[
            {
              'text-blue-600' : activeStep === step.number,
              'text-gray-500' : activeStep !== step.number && !step.completed
            }
          ]"
        >
          {{ step.number }}
        </span>
      </div>
      <div 
        class="ml-3 mr-6 text-sm"
        :class="[
          {
            'text-blue-600' : activeStep === step.number,
            'text-green-500': activeStep !== step.number && step.completed,
            'text-gray-500' : activeStep !== step.number && !step.completed
          },
        ]"
      >
        {{ step.title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Steps",
  props: {
    steps: Array,
    activeStep: Number,
    updateStep: Function
  }
};
</script>