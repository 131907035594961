<template>
  <div class="w-full border-t-2 px-8 py-6 lg:flex justify-between items-center">
    <div class="flex">
      <a
        href="https://aktio.ai"
        target="_blank"
        class="hover:text-gray-900"
      >
        ©2024 Aktio.ai
      </a>
    </div>
  </div>
</template>

<script>
export default {
    name: 'AppFooter'
}
</script>