<template>
  <div id="kombit">
    <div
      class="min-h-screen flex justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8"
    >
      <div class="max-w-md w-full">
        Vi logger dig ind med Kombit Single Sign On nu.
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<script>
import AppFooter from "@/components/Footer.vue";
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

export default {
  name: "SSO",
  components: {
    AppFooter,
  },
  mounted() {
      this.retrieveJWT();
  },
  methods: {
    async retrieveJWT() {
      const jwt_token_to_decode = await Cookies.get('jwt-token');
      if (jwt_token_to_decode) {
        const userData = jwtDecode(jwt_token_to_decode);
        Cookies.remove('jwt-token')
        // this.$store.commit("user/setUser", userData)
        this.$store.commit("user/authenticate", true);
        localStorage.setItem('aktio-ai-user-token', userData.jwt);
        localStorage.setItem("loginMethod", "kombitSSO");
        if(userData.first_login) {
          this.$router.push("/profile");
        } else {
          this.$router.push("/processes");
        }
      } else {
        console.error('No JWT found in cookies');
        localStorage.removeItem("aktio-ai-user-token");
        this.$store.commit("user/authenticate", false);
        this.$router.push("/login?message=Fejl i SSO. Kontakt Aktio.")
      }
    }
  },
};
</script>
